// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sales-by-product-item-text-gy_hn {
  fill: rgb(10%, 10%, 10%);
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/sales-by-product-item/sales-by-product-item.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,eAAe;AACjB","sourcesContent":[".text {\n  fill: rgb(10%, 10%, 10%);\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `sales-by-product-item-text-gy_hn`
};
export default ___CSS_LOADER_EXPORT___;

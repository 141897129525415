import * as d3 from 'd3';
import React, { useMemo, useState } from 'react';
import { LegendItem } from '../../../components/legend-item/legend-item.component';
import { formatChartValue } from '../../../utils/formatChartValue';
import { style } from './capital-chart.module.css';
export function CapitalChart(props) {
    var balance = props.balance, bankBalance = props.bankBalance, profit = props.profit, capital = props.capital;
    var _a = useState(0), centerGroupHeight = _a[0], setCenterGroupHeight = _a[1];
    var width = 150;
    var height = 150;
    var radius = width / 2;
    var pie = useMemo(function () {
        return d3
            .pie()
            .value(function (d) { return d.value; });
    }, []);
    var arcs = useMemo(function () {
        return pie([
            { value: balance, color: 'green' },
            { value: bankBalance, color: 'purple' },
            { value: profit, color: 'blue' }
        ]);
    }, [pie, balance, bankBalance, profit]);
    var arc = useMemo(function () {
        return d3
            .arc()
            .innerRadius(radius * 0.55)
            .outerRadius(radius);
    }, [radius]);
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.title }, "Capital"),
        React.createElement("svg", { className: style.chart, width: width, height: height, viewBox: "-".concat(width / 2, " -").concat(height / 2, " ").concat(width, " ").concat(height) },
            React.createElement("g", { ref: function (e) { return setCenterGroupHeight((e === null || e === void 0 ? void 0 : e.getBoundingClientRect().height) || 0); }, transform: "translate(0, -".concat(centerGroupHeight / 2, ")") },
                React.createElement("text", { className: style.capitalTitle, textAnchor: 'middle', alignmentBaseline: 'text-before-edge' }, "Total"),
                React.createElement("text", { className: style.capital, y: 18, textAnchor: 'middle', alignmentBaseline: 'text-before-edge' }, formatChartValue(capital))),
            React.createElement("g", null, arcs.map(function (value) { return (React.createElement("path", { className: style[value.data.color], key: value.index, d: arc(value) || '' })); }))),
        React.createElement("div", { className: style.legend },
            React.createElement(LegendItem, { color: 'green', title: 'Balance:', amount: balance }),
            React.createElement(LegendItem, { color: 'blue', title: 'Beneficio:', amount: profit }),
            React.createElement(LegendItem, { color: 'purple', title: 'Cuentas bancarias:', amount: bankBalance }))));
}

import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { style } from './error-popup.module.css';
export function ErrorPopup(props) {
    var open = props.open, children = props.children, onClose = props.onClose;
    var ref = useRef(null);
    useEffect(function () {
        if (!open)
            return;
        var handleClick = function (event) {
            var rect = ref.current.getBoundingClientRect();
            if (event.x < rect.x || event.x > rect.right || event.y < rect.y || event.y > rect.bottom) {
                onClose();
            }
        };
        document.addEventListener('click', handleClick);
        return function () {
            document.removeEventListener('click', handleClick);
        };
    }, [open, onClose]);
    return (React.createElement("div", { ref: ref, className: clsx(style.container, open && style.open) },
        React.createElement("div", { className: style.arrow }),
        React.createElement("div", { className: style.content }, children)));
}

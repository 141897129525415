var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { BankAccountApi } from '@recargas-dominicanas/core/api';
import { Pagination, Table, TableHeader, Text } from '@recargas-dominicanas/core/components';
import { OptionButton } from '../../../components/option-button/option-button.component';
import { PendingTransactionItem } from './pending-transaction-item/pending-transaction-item.component';
import { BankScrapingManagerStore } from '../../../store/bankScrapingManager';
import { BankAccountStore } from '../../../store/bankAccountStore';
import { ErrorPopup } from './error-popup/error-popup.component';
import { Status } from './status/status.component';
import { style } from './pending-transactions.module.css';
export function PendingTransactions(props) {
    var _a, _b, _c, _d, _e;
    var id = props.id;
    var page = BankAccountStore.getPendingTransactions(id);
    var status = BankAccountStore.getExecutionStatus(id);
    var bankScrapingManager = BankScrapingManagerStore.get();
    var _f = useState(false), refreshing = _f[0], setRefreshing = _f[1];
    var _g = useState(false), openErrorPopup = _g[0], setOpenErrorPopup = _g[1];
    var _h = useState(false), disabledExecutionButton = _h[0], setDisableExecutionButton = _h[1];
    var _j = useState(false), executing = _j[0], setExecuting = _j[1];
    var _k = useState(false), executionFailed = _k[0], setExecutionFailed = _k[1];
    useEffect(function () {
        update();
    }, [id]);
    useEffect(function () {
        var _a, _b;
        setDisableExecutionButton(((_a = status.lastExecution) === null || _a === void 0 ? void 0 : _a.status) === 'pending' || ((_b = status.lastExecution) === null || _b === void 0 ? void 0 : _b.status) === 'running');
    }, [(_a = status.lastExecution) === null || _a === void 0 ? void 0 : _a.status]);
    useEffect(function () {
        var _a, _b;
        var id;
        if (((_a = status.lastExecution) === null || _a === void 0 ? void 0 : _a.status) === 'pending' || ((_b = status.lastExecution) === null || _b === void 0 ? void 0 : _b.status) === 'running') {
            id = setInterval(update, 2500);
        }
        return function () {
            clearInterval(id);
        };
    }, [(_b = status.lastExecution) === null || _b === void 0 ? void 0 : _b.status]);
    function handleFetch(page, size) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, BankAccountStore.fetchPendingTransactions(id, page, size)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleRefreshStatus() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setRefreshing(true);
                        setExecutionFailed(false);
                        return [4 /*yield*/, update()];
                    case 1:
                        _a.sent();
                        setRefreshing(false);
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleStartScrapingService() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var status_1, err_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        setExecuting(true);
                        setExecutionFailed(false);
                        setDisableExecutionButton(true);
                        return [4 /*yield*/, BankAccountApi.startScrapingService(id)];
                    case 1:
                        _c.sent();
                        return [4 /*yield*/, BankAccountApi.getExecutionStatus(id)];
                    case 2:
                        status_1 = _c.sent();
                        BankAccountStore.updateExecutionStatus(id, status_1);
                        setExecuting(false);
                        setDisableExecutionButton(((_a = status_1.lastExecution) === null || _a === void 0 ? void 0 : _a.status) === 'pending' || ((_b = status_1.lastExecution) === null || _b === void 0 ? void 0 : _b.status) === 'running');
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _c.sent();
                        setExecuting(false);
                        setExecutionFailed(true);
                        setDisableExecutionButton(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function handleOpenErrorPopup(event) {
        setOpenErrorPopup(function (open) { return !open; });
        event.stopPropagation();
    }
    function update() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            BankAccountStore.fetchExecutionStatus(id),
                            BankAccountStore.fetchPendingTransactions(id, 1, 25),
                            BankScrapingManagerStore.fetch()
                        ])];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function formatTime(date) {
        var day = getDate(new Date(date));
        var today = getDate(new Date());
        var yesterday = getDate(new Date(), -1);
        var tomorrow = getDate(new Date(), 1);
        var timeFormatted = new Intl.DateTimeFormat('es-DO', { timeStyle: 'short' }).format(new Date(date));
        if (day === yesterday)
            return "ayer ".concat(timeFormatted);
        if (day === today)
            return "hoy ".concat(timeFormatted);
        if (day === tomorrow)
            return "ma\u00F1ana ".concat(timeFormatted);
        return new Intl.DateTimeFormat('es-DO', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(date));
    }
    function getDate(date, day) {
        if (day === void 0) { day = 0; }
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + day, 0, 0, 0, 0).getTime();
    }
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.toolbar },
            React.createElement("div", { className: style.toolbarContent },
                status.lastExecution &&
                    React.createElement("span", { className: style.text },
                        "\u00DAlt. ejecuci\u00F3n: ",
                        formatTime(status.lastExecution.date)),
                status.nextExecution && Date.parse(status.nextExecution.date) > Date.now() &&
                    React.createElement("span", { className: style.text },
                        "Pr\u00F3x. ejecuci\u00F3n: ",
                        formatTime(status.nextExecution.date)),
                ((_c = status.lastExecution) === null || _c === void 0 ? void 0 : _c.status) === 'failed' &&
                    React.createElement("div", { className: style.statusErrorContainer },
                        React.createElement(Status, { icon: 'error_outline', title: 'Error', severity: 'error', onClick: handleOpenErrorPopup }),
                        React.createElement(ErrorPopup, { open: openErrorPopup, onClose: function () { return setOpenErrorPopup(false); } }, status.lastExecution.error)),
                (bankScrapingManager === null || bankScrapingManager === void 0 ? void 0 : bankScrapingManager.status) === 'failed' &&
                    React.createElement(Status, { icon: 'error_outline', title: 'Error auto ejecutando servicio', severity: 'error' }),
                executionFailed &&
                    React.createElement(Status, { icon: 'error_outline', title: 'Error ejecutando servicio', severity: 'error' }),
                executing &&
                    React.createElement(Status, { icon: 'autorenew', title: 'Ejecutando', rotatedIcon: true }),
                (((_d = status.lastExecution) === null || _d === void 0 ? void 0 : _d.status) === 'pending' || ((_e = status.lastExecution) === null || _e === void 0 ? void 0 : _e.status) === 'running') &&
                    React.createElement(Status, { icon: 'autorenew', title: 'En progreso', rotatedIcon: true }),
                refreshing &&
                    React.createElement(Status, { icon: 'autorenew', title: 'Actualizando', rotatedIcon: true })),
            React.createElement("div", { className: style.toolbarContent },
                React.createElement(OptionButton, { icon: 'refresh', title: 'Actualizar', onClick: handleRefreshStatus }),
                React.createElement(OptionButton, { icon: 'play_arrow', title: 'Ejecutar', disabled: disabledExecutionButton, onClick: handleStartScrapingService }))),
        React.createElement("div", { className: style.tableContainer },
            React.createElement(Table, { style: style.table },
                React.createElement(TableHeader, { style: style.tableHeader, autoHide: true },
                    React.createElement(Text, { text: 'Tipo' }),
                    React.createElement(Text, { text: 'Balance' }),
                    React.createElement(Text, { text: 'Fecha' }),
                    React.createElement(Text, { text: 'Estado' }),
                    React.createElement(Text, { text: 'Descripci\u00F3n' }),
                    React.createElement(Text, { text: '' })),
                React.createElement("tbody", null, page === null || page === void 0 ? void 0 : page.data.map(function (pendingTransaction) {
                    return React.createElement(PendingTransactionItem, { key: pendingTransaction.id, bankAccountId: id, pendingTransaction: pendingTransaction });
                })))),
        page &&
            React.createElement(Pagination, { title: 'Transacciones', index: page.index, pages: page.pages, count: page.count, size: page.size, onChange: handleFetch })));
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-release-modal-modal_card-Q_Np8 {
  width: 524px;
  height: 660px;
}

.app-release-modal-modalContent-RjGwR {
  display: flex;
  flex-direction: column;
}

.app-release-modal-fieldsContent-wG9EG {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.app-release-modal-textField_container-mMSna {
  width: 220px;
  margin-top: 20px;
}

.app-release-modal-textField_label-ANToB {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9)
}

.app-release-modal-textField_input-Z0sW1 {
  height: 40px;
  font-size: 15px;
}

.app-release-modal-title-ohvyt {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-weight: 500;
  margin-top: 28px;
}

.app-release-modal-releaseNotes-pkwOU {
  height: 380px;
  flex-grow: 1;
  overflow: auto;
  margin-top: 8px;
}

.app-release-modal-addButton_base-hvcf9 {
  margin-left: auto;
  padding: 0px 4px;
  margin-top: 8px;
}

.app-release-modal-info-B7gt1 {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color:rgba(0, 0, 0, 0.9);
  margin-top: 12px;
}

.app-release-modal-paddingBottom-hvN3f {
  padding-bottom: 20px;
}

.app-release-modal-date-mcCaR {
  padding-left: 4px;
}

.app-release-modal-published-moZZS {
  color: var(--money-color);
  font-weight: 500;
}

.app-release-modal-modalActions_container-rJIi3 {
  width: 360px;
}

.app-release-modal-error_container-gYKlZ {
  margin: 16px 20px 0px;
}

.app-release-modal-indeterminateProgressBar_container-Bj7f4 {
  margin: 12px 20px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/app-releases/app-release-modal/app-release-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf;AACF;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".modal_card {\n  width: 524px;\n  height: 660px;\n}\n\n.modalContent {\n  display: flex;\n  flex-direction: column;\n}\n\n.fieldsContent {\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: space-between;\n}\n\n.textField_container {\n  width: 220px;\n  margin-top: 20px;\n}\n\n.textField_label {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.9)\n}\n\n.textField_input {\n  height: 40px;\n  font-size: 15px;\n}\n\n.title {\n  color: rgba(0, 0, 0, 0.9);\n  font-size: 14px;\n  font-weight: 500;\n  margin-top: 28px;\n}\n\n.releaseNotes {\n  height: 380px;\n  flex-grow: 1;\n  overflow: auto;\n  margin-top: 8px;\n}\n\n.addButton_base {\n  margin-left: auto;\n  padding: 0px 4px;\n  margin-top: 8px;\n}\n\n.info {\n  display: flex;\n  justify-content: space-between;\n  font-size: 15px;\n  color:rgba(0, 0, 0, 0.9);\n  margin-top: 12px;\n}\n\n.paddingBottom {\n  padding-bottom: 20px;\n}\n\n.date {\n  padding-left: 4px;\n}\n\n.published {\n  color: var(--money-color);\n  font-weight: 500;\n}\n\n.modalActions_container {\n  width: 360px;\n}\n\n.error_container {\n  margin: 16px 20px 0px;\n}\n\n.indeterminateProgressBar_container {\n  margin: 12px 20px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `app-release-modal-modal_card-Q_Np8`,
	"modalContent": `app-release-modal-modalContent-RjGwR`,
	"fieldsContent": `app-release-modal-fieldsContent-wG9EG`,
	"textField_container": `app-release-modal-textField_container-mMSna`,
	"textField_label": `app-release-modal-textField_label-ANToB`,
	"textField_input": `app-release-modal-textField_input-Z0sW1`,
	"title": `app-release-modal-title-ohvyt`,
	"releaseNotes": `app-release-modal-releaseNotes-pkwOU`,
	"addButton_base": `app-release-modal-addButton_base-hvcf9`,
	"info": `app-release-modal-info-B7gt1`,
	"paddingBottom": `app-release-modal-paddingBottom-hvN3f`,
	"date": `app-release-modal-date-mcCaR`,
	"published": `app-release-modal-published-moZZS`,
	"modalActions_container": `app-release-modal-modalActions_container-rJIi3`,
	"error_container": `app-release-modal-error_container-gYKlZ`,
	"indeterminateProgressBar_container": `app-release-modal-indeterminateProgressBar_container-Bj7f4`
};
export default ___CSS_LOADER_EXPORT___;

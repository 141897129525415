var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Fragment, useRef, useState } from 'react';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { ProviderProductApi } from '@recargas-dominicanas/core/api';
import { Cell } from '@recargas-dominicanas/core/components';
import { TableRow } from '@recargas-dominicanas/core/components';
import { CellIcon } from '@recargas-dominicanas/core/components';
import { CellImage } from '@recargas-dominicanas/core/components';
import { Menu, Image } from '@recargas-dominicanas/core/components';
import { ProviderStore } from '../../../../store/providerStore';
import { AddProductModal } from '../add-product-modal/add-product-modal.component';
import { MenuOption } from '../../../../components/menu-option/menu-option.component';
import { ConfirmModal } from '../../../../components/confirm-modal/confirm-modal.component';
import { style } from './product-item.module.css';
export function ProductItem(props) {
    var providerId = props.providerId, providerProduct = props.providerProduct;
    var menuIconRef = useRef(null);
    var _a = useState(false), openMenu = _a[0], setOpenMenu = _a[1];
    var _b = useState(false), openEditModal = _b[0], setOpenEditModal = _b[1];
    var _c = useState(false), openConfirmModal = _c[0], setOpenConfirmModal = _c[1];
    function handleSave(data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ProviderProductApi.update(providerProduct.id, data)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, ProviderStore.fetchProducts(providerId)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleDelete() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ProviderProductApi.delete(providerProduct.id)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, ProviderStore.fetchProducts(providerId)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleEnabled() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ProviderProductApi.update(providerProduct.id, { enabled: !providerProduct.enabled })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, ProviderStore.fetchProducts(providerId)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(Fragment, null,
        React.createElement(TableRow, { key: providerProduct.id, style: style.tableRow },
            React.createElement(CellImage, { src: providerProduct.product.image }),
            React.createElement(Cell, { weight: 'medium', text: providerProduct.product.name }),
            React.createElement(Cell, { weight: 'medium', color: 'darkgray', text: providerProduct.product.type }),
            React.createElement(Cell, { weight: 'bold', color: 'green', text: providerProduct.product.type === 'Factura'
                    ? formatCurrency(providerProduct.profit)
                    : providerProduct.profit + '%' }),
            React.createElement(Cell, { text: providerProduct.key }),
            providerProduct.enabled
                ? React.createElement(Cell, { weight: 'medium', color: 'green', text: 'Activado' })
                : React.createElement(Cell, { weight: 'medium', color: 'gray', text: 'Desactivado' }),
            React.createElement(CellIcon, { refIcon: menuIconRef, onClick: function () { return setOpenMenu(true); } })),
        React.createElement(Menu, { open: openMenu, anchor: menuIconRef, onClose: function () { return setOpenMenu(false); } },
            React.createElement(MenuOption, { text: providerProduct.enabled ? 'Desactivar' : 'Activar', onClick: handleEnabled }),
            React.createElement(MenuOption, { text: 'Editar', onClick: function () { return setOpenEditModal(true); } }),
            React.createElement(MenuOption, { text: 'Eliminar', style: style.deleteOption, onClick: function () { return setOpenConfirmModal(true); } })),
        React.createElement(AddProductModal, { open: openEditModal, title: 'Editar producto', buttonText: 'Guardar', errorMessage: 'Ocurrio un error al actualizar el producto', providerProduct: providerProduct, onSave: handleSave, onClose: function () { return setOpenEditModal(false); } }),
        React.createElement(ConfirmModal, { open: openConfirmModal, title: 'Eliminar producto', description: 'Eliminar producto', onConfirm: handleDelete, onClose: function () { return setOpenConfirmModal(false); } },
            React.createElement("div", { className: style.productItem },
                React.createElement(Image, { className: style.productItemImage, src: providerProduct.product.image }),
                React.createElement("div", { className: style.productItemInfo },
                    React.createElement("span", { className: style.productItemName }, providerProduct.product.name),
                    React.createElement("span", { className: style.productItemType }, providerProduct.product.type))))));
}

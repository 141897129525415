import React from 'react';
import { formatId, formatPhone } from '@recargas-dominicanas/core/utils';
import { OutlineCard, TextItem, Title } from '@recargas-dominicanas/core/components';
import { mergeStyle } from './customer-card.module.css';
export function CustomerCard(props) {
    var customer = props.customer, business = props.business, customStyle = props.style;
    var style = mergeStyle(customStyle);
    return (React.createElement(OutlineCard, { className: style.container },
        React.createElement("div", { className: style.data },
            React.createElement(Title, { style: style.title, title: 'Datos del cliente' }),
            React.createElement(TextItem, { style: style.textItem, title: 'Nombre', text: customer.name }),
            React.createElement(TextItem, { style: style.textItem, title: 'C\u00E9dula', text: customer.docNumber }),
            React.createElement(TextItem, { style: style.textItem, title: 'Tel\u00E9fono', text: formatPhone(customer.phone) }),
            React.createElement(TextItem, { style: style.textItem, title: 'Correo electr\u00F3nico', text: customer.email })),
        React.createElement("div", { className: style.data },
            React.createElement(Title, { style: style.title, title: 'Datos del negocio' }),
            React.createElement(TextItem, { style: style.textItem, title: 'Nombre', text: business.name }),
            React.createElement(TextItem, { style: style.textItem, title: 'RNC', text: business.rnc }),
            React.createElement(TextItem, { style: style.textItem, title: 'ID', text: formatId(business.id) }),
            React.createElement(TextItem, { style: style.textItem, title: 'Tel\u00E9fono', text: formatPhone(business.phone) }),
            React.createElement(TextItem, { style: style.textItem, title: 'Correo electr\u00F3nico', text: business.email }),
            React.createElement(TextItem, { style: style.textItem, title: 'Ciudad', text: business.city }),
            React.createElement(TextItem, { style: style.textItem, title: 'Direcci\u00F3n', text: business.address }),
            React.createElement(TextItem, { style: style.textItem, title: 'Porcentaje', text: "".concat(business.percent, "%") }))));
}

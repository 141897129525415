var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from 'react';
import { OptionButton } from '../../components/option-button/option-button.component';
import { VerticalBarChart } from '../../components/vertical-bar-chart/vertical-bar-chart.component';
import { HorizontalBarChart } from '../../components/horizontal-bar-chart/horizontal-bar-chart.component';
import { SalesByProductChart } from '../../components/sales-by-product-chart/sales-by-product-chart.component';
import { FinanceStore } from '../../store/financeStore';
import { CapitalChart } from './capital-chart/capital-chart.component';
import { FinanceItem } from './finance-item/finance-item.component';
import { DatePicker } from '../../components/date-picker/date-picker.component';
import { style } from './finances.module.css';
import { PageContainer, PageToolbar, Title, PageContent } from '@recargas-dominicanas/core/components';
export function Finances() {
    var menuRef = useRef(null);
    var _a = useState(false), openMenu = _a[0], setOpenMenu = _a[1];
    var _b = useState('Mes actual'), optionDateLabel = _b[0], setOptionDateLabel = _b[1];
    var date = useState(new Date())[0];
    var _c = useState(new Date(date.getFullYear(), date.getMonth())), startDate = _c[0], setStartDate = _c[1];
    var _d = useState(new Date(date.getFullYear(), date.getMonth() + 1)), endDate = _d[0], setEndDate = _d[1];
    var summary = FinanceStore.getSummary();
    var sales = FinanceStore.getSales();
    var profits = FinanceStore.getProfits();
    var salesByProducts = FinanceStore.getSalesByProduct();
    var timeFormat = FinanceStore.getTimeFormat();
    var tickCount = FinanceStore.getTickCount();
    var barWidth = FinanceStore.getBarWidth();
    useEffect(function () {
        FinanceStore.fetch();
    }, []);
    function handleRefresh() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                FinanceStore.fetchSummary(startDate, endDate);
                FinanceStore.fetchSalesByProduct(startDate, endDate);
                if (timeFormat === 'day')
                    FinanceStore.fetchSalesByDay(startDate, endDate);
                if (timeFormat === 'month')
                    FinanceStore.fetchSalesByMonth(startDate, endDate);
                return [2 /*return*/];
            });
        });
    }
    function handleDateChange(startDate, endDate, label, type) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setStartDate(startDate);
                setEndDate(endDate);
                setOptionDateLabel(label);
                FinanceStore.fetchSummary(startDate, endDate);
                FinanceStore.fetchSalesByProduct(startDate, endDate);
                if (type === 'year')
                    FinanceStore.fetchSalesByMonth(startDate, endDate);
                if (type === 'month')
                    FinanceStore.fetchSalesByDay(startDate, endDate);
                return [2 /*return*/];
            });
        });
    }
    if (!summary)
        return null;
    return (React.createElement(PageContainer, { className: style.container },
        React.createElement(PageToolbar, { className: style.toolbar },
            React.createElement(Title, { style: style.title, title: 'Balance general' }),
            React.createElement("div", { className: style.options },
                React.createElement(OptionButton, { optionRef: menuRef, icon: 'refresh', title: 'Actualizar', onClick: handleRefresh }),
                React.createElement(OptionButton, { optionRef: menuRef, icon: 'event', title: optionDateLabel, rightIcon: 'expand_more', onClick: function () { return setOpenMenu(function (open) { return !open; }); } }),
                React.createElement(DatePicker, { anchor: menuRef, open: openMenu, onChange: handleDateChange, onClose: function () { return setOpenMenu(false); } }))),
        React.createElement(PageContent, { className: style.content },
            React.createElement("div", { className: style.financeItems },
                React.createElement(FinanceItem, { title: 'Balance', amount: summary.balance }),
                React.createElement(FinanceItem, { title: 'Cuentas bancarias', amount: summary.banksBalance }),
                React.createElement(FinanceItem, { title: 'Capital', amount: summary.capital }),
                React.createElement(FinanceItem, { title: 'Beneficio General', amount: summary.generalProfit }),
                React.createElement(FinanceItem, { title: 'Venta', amount: summary.sales }),
                React.createElement(FinanceItem, { title: 'Beneficio', amount: summary.profit })),
            React.createElement("div", { className: style.charts },
                React.createElement("div", { className: style.chartColumn },
                    React.createElement(CapitalChart, { balance: summary.balance, bankBalance: summary.banksBalance, profit: summary.generalProfit, capital: summary.capital }),
                    React.createElement(HorizontalBarChart, { title: 'Balance', width: 360, height: 140, data: [
                            { title: 'Balance', value: summary.balance, color: 'green' },
                            { title: 'Balance asignado', value: summary.balanceAsigned, color: 'purple' }
                        ] })),
                React.createElement("div", { className: style.verticalBarCharts },
                    React.createElement(VerticalBarChart, { className: style.verticalBarChart, title: 'Ventas', barWidth: barWidth, xTicksCount: tickCount, yTicksCount: 3, timeFormat: timeFormat, data: sales }),
                    React.createElement(VerticalBarChart, { className: style.verticalBarChart, title: 'Beneficios', barWidth: barWidth, xTicksCount: tickCount, yTicksCount: 3, timeFormat: timeFormat, data: profits })),
                React.createElement("div", { className: style.chartColumn },
                    React.createElement(SalesByProductChart, { title: 'Ventas por productos', products: salesByProducts }))))));
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-down-button-container-YF57d {
  height: 32px;
  display: flex;
  cursor: pointer;
}

.drop-down-button-button-mBaZC {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px 0px 0px 4px;
  padding-left: 8px;
}

.drop-down-button-button-mBaZC:hover, .drop-down-button-button-mBaZC:hover ~ .drop-down-button-iconRight-woBdM {
  background-color: var(--background-color);
}

.drop-down-button-title-kzYea {
  line-height: 32px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  margin: 0px 12px 0px 8px;
  white-space: nowrap;
}

.drop-down-button-icon-fd04w {
  --size: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.drop-down-button-iconRight-woBdM {
  width: 28px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-left: none;
  border-radius: 0px 4px 4px 0px;
}

.drop-down-button-iconRight-woBdM:hover {
  background-color: var(--background-color);
}

.drop-down-button-menu_container-tM08P {
  width: unset;
  min-width: 232px;
}
`, "",{"version":3,"sources":["webpack://./src/components/drop-down-button/drop-down-button.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,qCAAqC;EACrC,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,yBAAyB;EACzB,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,qCAAqC;EACrC,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".container {\n  height: 32px;\n  display: flex;\n  cursor: pointer;\n}\n\n.button {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  border: 1px solid rgba(0, 0, 0, 0.15);\n  border-radius: 4px 0px 0px 4px;\n  padding-left: 8px;\n}\n\n.button:hover, .button:hover ~ .iconRight {\n  background-color: var(--background-color);\n}\n\n.title {\n  line-height: 32px;\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n  margin: 0px 12px 0px 8px;\n  white-space: nowrap;\n}\n\n.icon {\n  --size: 20px;\n  color: rgba(0, 0, 0, 0.7);\n}\n\n.iconRight {\n  width: 28px;\n  height: 32px;\n  line-height: 32px;\n  text-align: center;\n  font-size: 20px;\n  color: rgba(0, 0, 0, 0.7);\n  border: 1px solid rgba(0, 0, 0, 0.15);\n  border-left: none;\n  border-radius: 0px 4px 4px 0px;\n}\n\n.iconRight:hover {\n  background-color: var(--background-color);\n}\n\n.menu_container {\n  width: unset;\n  min-width: 232px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `drop-down-button-container-YF57d`,
	"button": `drop-down-button-button-mBaZC`,
	"iconRight": `drop-down-button-iconRight-woBdM`,
	"title": `drop-down-button-title-kzYea`,
	"icon": `drop-down-button-icon-fd04w`,
	"menu_container": `drop-down-button-menu_container-tM08P`
};
export default ___CSS_LOADER_EXPORT___;

import * as d3 from 'd3';
import React, { useEffect, useMemo, useRef } from 'react';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { formatChartValue } from '../../utils/formatChartValue';
import { useRect } from '../../hooks/useRect';
import { style } from './horizontal-bar-chart.module.css';
export function HorizontalBarChart(props) {
    var title = props.title, width = props.width, height = props.height, data = props.data;
    var svgRef = useRef(null);
    var container = useRect({ width: width, height: height, padding: { left: 4 } });
    var tickText = useRect({ margin: { top: 12 } });
    var lastTickText = useRect();
    var barContainer = useRect();
    var barRect = useRect({ height: 20, margin: { top: 20 } });
    var barValue = useRect({ margin: { top: 20, left: 12 } });
    var deps = data.map(function (d) { return d.value + d.title; }).toString();
    var values = data.map(function (d) { return d.value; });
    var xAxisStart = container.padding.left;
    var xAxisEnd = container.width - (lastTickText.width / 2) - container.padding.right;
    var yAxisStart = container.padding.top;
    var yAxisEnd = container.height - tickText.height - tickText.margin.top - barContainer.height;
    var max = useMemo(function () { return d3.max(values); }, [deps]);
    var x = useMemo(function () { return d3.scaleLinear([0, max], [xAxisStart, xAxisEnd]); }, [max, xAxisStart, xAxisEnd]);
    var y = useMemo(function () { return d3.scalePoint(values, [yAxisStart, yAxisEnd]); }, [deps, yAxisStart, yAxisEnd]);
    var ticks = useMemo(function () { return x.nice(3).ticks(3); }, [x]);
    useEffect(function () {
        if (!svgRef.current)
            return;
        var tickTextElement = svgRef.current.querySelector(".".concat(style.tickText));
        var barContainerElement = svgRef.current.querySelector('.bar-container');
        var barValueElement = svgRef.current.querySelector(".".concat(style.barText));
        tickText.setHeight((tickTextElement === null || tickTextElement === void 0 ? void 0 : tickTextElement.getBBox().height) || 0);
        barContainer.setHeight((barContainerElement === null || barContainerElement === void 0 ? void 0 : barContainerElement.getBBox().height) || 0);
        barValue.setHeight((barValueElement === null || barValueElement === void 0 ? void 0 : barValueElement.getBBox().height) || 0);
    }, []);
    useEffect(function () {
        if (svgRef.current) {
            var tickTextsElements = svgRef.current.querySelectorAll(".".concat(style.tickText));
            lastTickText.setWidth(tickTextsElements[tickTextsElements.length - 1].getBBox().width || 0);
        }
    }, [max]);
    useEffect(function () {
        if (!svgRef.current)
            return;
        var barContainers = svgRef.current.querySelectorAll('.bar-container');
        var rects = Array.from(barContainers).map(function (barContainer) { return barContainer.getBBox(); });
        var max = rects.reduce(function (prev, next) { return prev.width > next.width ? prev : next; });
        if (max.x + max.width > container.width) {
            container.setPaddingRight((max.x + max.width) - container.width);
        }
    }, [container.width, deps]);
    function centerBarValue(y) {
        var result = barValue.height > 0 ? (barRect.height - barValue.height) / 2 : 0;
        return y + result;
    }
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.title }, title),
        React.createElement("svg", { className: style.chart, width: container.width, height: container.height, ref: svgRef },
            React.createElement("g", null, ticks.map(function (tick) {
                return React.createElement("g", { key: tick },
                    React.createElement("line", { className: style.line, x1: x(tick), x2: x(tick), y1: container.padding.top, y2: container.height - tickText.height - tickText.margin.top }),
                    React.createElement("text", { className: style.tickText, x: x(tick), y: container.height, alignmentBaseline: 'text-after-edge' }, formatChartValue(tick)));
            })),
            React.createElement("g", null, data.map(function (d) {
                return React.createElement("g", { key: d.value + d.title, className: 'bar-container' },
                    React.createElement("text", { className: style.barText, x: container.padding.left, y: y(d.value), alignmentBaseline: 'text-before-edge' }, d.title),
                    React.createElement("rect", { className: style[d.color], x: container.padding.left, y: y(d.value) + barRect.margin.top, width: x(d.value) - x(0), height: barRect.height }),
                    React.createElement("text", { className: style.barText, x: (x(d.value) - x(0)) + barValue.margin.left, y: centerBarValue(y(d.value) + barValue.margin.top), alignmentBaseline: 'text-before-edge' }, formatCurrency(d.value)));
            })))));
}

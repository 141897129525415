// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linked-trasaction-select-autocomplete_container-qIjUI {
  width: 100%;
}

.linked-trasaction-select-autocomplete_popup-Tx6Fs {
  max-height: 300px;
}

.linked-trasaction-select-item-M2jUA {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  font-size: 15px;
  padding: 12px;
}

.linked-trasaction-select-balance-r_0CJ {
  flex-shrink: 0;
  font-weight: 500;
  color: var(--money-color);
}

.linked-trasaction-select-date-ytA74 {
  color: rgba(0, 0, 0, 0.8);
}

.linked-trasaction-select-disabled-deMSj {
  color: rgba(0, 0, 0, 0.45);
}

.linked-trasaction-select-disabled-deMSj:hover {
  background-color: initial;
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/pending-transactions/linked-trasaction-select/linked-transaction-select.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,QAAQ;EACR,eAAe;EACf,aAAa;AACf;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".autocomplete_container {\n  width: 100%;\n}\n\n.autocomplete_popup {\n  max-height: 300px;\n}\n\n.item {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 4px;\n  font-size: 15px;\n  padding: 12px;\n}\n\n.balance {\n  flex-shrink: 0;\n  font-weight: 500;\n  color: var(--money-color);\n}\n\n.date {\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.disabled {\n  color: rgba(0, 0, 0, 0.45);\n}\n\n.disabled:hover {\n  background-color: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autocomplete_container": `linked-trasaction-select-autocomplete_container-qIjUI`,
	"autocomplete_popup": `linked-trasaction-select-autocomplete_popup-Tx6Fs`,
	"item": `linked-trasaction-select-item-M2jUA`,
	"balance": `linked-trasaction-select-balance-r_0CJ`,
	"date": `linked-trasaction-select-date-ytA74`,
	"disabled": `linked-trasaction-select-disabled-deMSj`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-container-lySZf {
  display: flex;
  gap: 8px;
  align-items: center;
}

.status-clickable-GsxVO {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
}

.status-clickable-GsxVO:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.status-icon-tm9Wb {
  --size: 18px;
  color: rgba(0, 0, 0, 0.7);
}

.status-title-u7LqI {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.9);
}

.status-rotateAnimation-QFrm1 {
  animation: status-rotate-KCLlj 1.5s linear infinite;
}

.status-error-UgKur {
  color: var(--accent-color);
}

.status-clickableError-U4u9w:hover {
  background-color: var(--transparent-accent-color);
}

@keyframes status-rotate-KCLlj {
  from { transform: rotateZ(0deg) }
  to { transform: rotateZ(360deg) }
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/pending-transactions/status/status.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EAEE,eAAe;EACf,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,mDAAsC;AACxC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,OAAO,yBAAyB;EAChC,KAAK,2BAA2B;AAClC","sourcesContent":[".container {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n\n.clickable {\n  composes: container;\n  cursor: pointer;\n  border-radius: 4px;\n  padding: 4px;\n}\n\n.clickable:hover {\n  background-color: rgba(0, 0, 0, 0.04);\n}\n\n.icon {\n  --size: 18px;\n  color: rgba(0, 0, 0, 0.7);\n}\n\n.title {\n  font-size: 13px;\n  color: rgba(0, 0, 0, 0.9);\n}\n\n.rotateAnimation {\n  animation: rotate 1.5s linear infinite;\n}\n\n.error {\n  color: var(--accent-color);\n}\n\n.clickableError:hover {\n  background-color: var(--transparent-accent-color);\n}\n\n@keyframes rotate {\n  from { transform: rotateZ(0deg) }\n  to { transform: rotateZ(360deg) }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `status-container-lySZf`,
	"clickable": `status-clickable-GsxVO status-container-lySZf`,
	"icon": `status-icon-tm9Wb`,
	"title": `status-title-u7LqI`,
	"rotateAnimation": `status-rotateAnimation-QFrm1`,
	"rotate": `status-rotate-KCLlj`,
	"error": `status-error-UgKur`,
	"clickableError": `status-clickableError-U4u9w`
};
export default ___CSS_LOADER_EXPORT___;

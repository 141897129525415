// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-deposit-modal-modal_card-AMq12 {
  width: 340px;
  height: initial;
  min-height: 504px;
}

.register-deposit-modal-modal_content-hNtHU {
  padding: 0px 20px;
}

.register-deposit-modal-textField_container-qFD1t, .register-deposit-modal-dateTime_container-ZEWC6, .register-deposit-modal-businessSelect-tGN4a {
  width: 100%;
  margin-top: 24px;
}

.register-deposit-modal-textField_input-_wVRk {
  height: 40px;
  font-size: 15px;
}

.register-deposit-modal-textField_container-qFD1t:first-child {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/register-deposit-modal/register-deposit-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".modal_card {\n  width: 340px;\n  height: initial;\n  min-height: 504px;\n}\n\n.modal_content {\n  padding: 0px 20px;\n}\n\n.textField_container, .dateTime_container, .businessSelect {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.textField_input {\n  height: 40px;\n  font-size: 15px;\n}\n\n.textField_container:first-child {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `register-deposit-modal-modal_card-AMq12`,
	"modal_content": `register-deposit-modal-modal_content-hNtHU`,
	"textField_container": `register-deposit-modal-textField_container-qFD1t`,
	"dateTime_container": `register-deposit-modal-dateTime_container-ZEWC6`,
	"businessSelect": `register-deposit-modal-businessSelect-tGN4a`,
	"textField_input": `register-deposit-modal-textField_input-_wVRk`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-body-x0TTw {
  display: flex;
  height: calc(100vh - 45px);
}

.main-navBar_container-bTFgQ {
  height: 100%;
  width: 64px;
}

.main-content-Rtz4Q {
  width: calc(100% - 64px);
}
`, "",{"version":3,"sources":["webpack://./src/pages/main/main.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".body {\n  display: flex;\n  height: calc(100vh - 45px);\n}\n\n.navBar_container {\n  height: 100%;\n  width: 64px;\n}\n\n.content {\n  width: calc(100% - 64px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `main-body-x0TTw`,
	"navBar_container": `main-navBar_container-bTFgQ`,
	"content": `main-content-Rtz4Q`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.config-row-configItem-PabdT {
  border: 1px solid var(--border-color);
  border-radius: var(--small-border-radius);
  padding: 8px 12px;
}

.config-row-configItemName-GVZbl {
  font-size: 15px;
  font-weight: 500;
}

.config-row-configItemValue-FKx3r {
  font-size: 15px;
  word-wrap: break-word;
  margin-top: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/config/config-row/config-row.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,yCAAyC;EACzC,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[".configItem {\n  border: 1px solid var(--border-color);\n  border-radius: var(--small-border-radius);\n  padding: 8px 12px;\n}\n\n.configItemName {\n  font-size: 15px;\n  font-weight: 500;\n}\n\n.configItemValue {\n  font-size: 15px;\n  word-wrap: break-word;\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"configItem": `config-row-configItem-PabdT`,
	"configItemName": `config-row-configItemName-GVZbl`,
	"configItemValue": `config-row-configItemValue-FKx3r`
};
export default ___CSS_LOADER_EXPORT___;

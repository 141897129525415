// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-select-autocomplete_container-exqOZ {
  width: 100%;
}

.business-select-autocomplete_popup-oBfUf {
  max-height: 200px;
}

.business-select-item-fSJKT {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
}

.business-select-id-ipU4q {
  width: 32px;
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.75);
  font-weight: 500;
}

.business-select-name-_ToQS {
  color: rgba(0, 0, 0, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/components/business-select/business-select.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".autocomplete_container {\n  width: 100%;\n}\n\n.autocomplete_popup {\n  max-height: 200px;\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  padding: 8px 12px;\n}\n\n.id {\n  width: 32px;\n  flex-shrink: 0;\n  color: rgba(0, 0, 0, 0.75);\n  font-weight: 500;\n}\n\n.name {\n  color: rgba(0, 0, 0, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autocomplete_container": `business-select-autocomplete_container-exqOZ`,
	"autocomplete_popup": `business-select-autocomplete_popup-oBfUf`,
	"item": `business-select-item-fSJKT`,
	"id": `business-select-id-ipU4q`,
	"name": `business-select-name-_ToQS`
};
export default ___CSS_LOADER_EXPORT___;

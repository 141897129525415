// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sales-by-product-chart-container-_pDUt {
  width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sales-by-product-chart-title-JnrHG {
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 500;
}

.sales-by-product-chart-content-wlKik {
  flex-grow: 1;
  overflow-y: scroll;
  margin-top: 24px
}

.sales-by-product-chart-chart-FDG3g {
  width: 100%;
  display: block;
}

.sales-by-product-chart-ticks-wZlFd {
  width: 100%;
  flex-shrink: 0;
  margin-top: 16px;
}

.sales-by-product-chart-line-lQUwP {
  stroke: rgba(0, 0, 0, 8%);
}

.sales-by-product-chart-tickText-aGj2u {
  fill: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  text-anchor: middle;
}

.sales-by-product-chart-productName-N29kS {
  fill: rgba(0, 0, 0, 0.8);
  font-size: 12px;
}

.sales-by-product-chart-legend-qet14 {
  display: flex;
  flex-flow: row wrap;
  column-gap: 24px;
  row-gap: 20px;
  margin-top: 28px;
  justify-content: center;
}

.sales-by-product-chart-green-oNcdD {
  fill: #27AE60;
}

.sales-by-product-chart-blue-m6dZ1 {
  fill: #2F80ED;
}

.sales-by-product-chart-purple-Wv5xE {
  fill: #9B51E0;
}

.sales-by-product-chart-orange-h2NHu {
  fill: #F2994A;
}
`, "",{"version":3,"sources":["webpack://./src/components/sales-by-product-chart/sales-by-product-chart.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB;AACF;;AAEA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".container {\n  width: 360px;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.title {\n  flex-shrink: 0;\n  color: rgba(0, 0, 0, 0.8);\n  font-size: 15px;\n  font-weight: 500;\n}\n\n.content {\n  flex-grow: 1;\n  overflow-y: scroll;\n  margin-top: 24px\n}\n\n.chart {\n  width: 100%;\n  display: block;\n}\n\n.ticks {\n  width: 100%;\n  flex-shrink: 0;\n  margin-top: 16px;\n}\n\n.line {\n  stroke: rgba(0, 0, 0, 8%);\n}\n\n.tickText {\n  fill: rgba(0, 0, 0, 0.5);\n  font-size: 12px;\n  text-anchor: middle;\n}\n\n.productName {\n  fill: rgba(0, 0, 0, 0.8);\n  font-size: 12px;\n}\n\n.legend {\n  display: flex;\n  flex-flow: row wrap;\n  column-gap: 24px;\n  row-gap: 20px;\n  margin-top: 28px;\n  justify-content: center;\n}\n\n.green {\n  fill: #27AE60;\n}\n\n.blue {\n  fill: #2F80ED;\n}\n\n.purple {\n  fill: #9B51E0;\n}\n\n.orange {\n  fill: #F2994A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sales-by-product-chart-container-_pDUt`,
	"title": `sales-by-product-chart-title-JnrHG`,
	"content": `sales-by-product-chart-content-wlKik`,
	"chart": `sales-by-product-chart-chart-FDG3g`,
	"ticks": `sales-by-product-chart-ticks-wZlFd`,
	"line": `sales-by-product-chart-line-lQUwP`,
	"tickText": `sales-by-product-chart-tickText-aGj2u`,
	"productName": `sales-by-product-chart-productName-N29kS`,
	"legend": `sales-by-product-chart-legend-qet14`,
	"green": `sales-by-product-chart-green-oNcdD`,
	"blue": `sales-by-product-chart-blue-m6dZ1`,
	"purple": `sales-by-product-chart-purple-Wv5xE`,
	"orange": `sales-by-product-chart-orange-h2NHu`
};
export default ___CSS_LOADER_EXPORT___;

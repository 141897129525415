import React, { useState } from 'react';
import { style } from './edit-field.module.css';
import { OptionIcon, TextArea } from '@recargas-dominicanas/core/components';
import clsx from 'clsx';
export function EditField(props) {
    var title = props.title, value = props.value, placeholder = props.placeholder, onChange = props.onChange;
    var _a = useState(false), enabled = _a[0], setEnabled = _a[1];
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.header },
            React.createElement("span", { className: style.title }, title),
            React.createElement(OptionIcon, { className: style.icon, icon: 'edit', onClick: function () { return setEnabled(function (enabled) { return !enabled; }); } })),
        !enabled &&
            React.createElement("span", { className: clsx(style.value, !value && style.placeholder), onClick: function () { return setEnabled(function (enabled) { return !enabled; }); } }, value || placeholder),
        enabled &&
            React.createElement(TextArea, { style: style.textArea, autofocus: true, value: value, onChange: onChange })));
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import androidLogo from '../../images/android-logo.png';
import webLogo from '../../images/web-logo.png';
import { useLocation, Redirect } from 'react-router';
import { Icon, Option, OutlineCard, Title, ToolbarTitle } from '@recargas-dominicanas/core/components';
import { copy, formatId } from '@recargas-dominicanas/core/utils';
import { TextItem } from '@recargas-dominicanas/core/components';
import { CustomerCard } from '../../components/customer-card/customer-card.component';
import { style } from './created-customer.module.css';
import { PageContainer } from '@recargas-dominicanas/core/components';
import { PageToolbar } from '@recargas-dominicanas/core/components';
import { PageContent } from '@recargas-dominicanas/core/components';
export function CreatedCustomer() {
    var location = useLocation();
    if (!location.state)
        return React.createElement(Redirect, { to: '/' });
    var _a = location.state, customer = _a.customer, business = _a.business, businessUser = _a.businessUser, token = _a.token;
    var link = "".concat(process.env.WEB_APP_DOMAIN, "/create-password/").concat(token);
    function handleCopy(text) {
        var _this = this;
        return function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, copy(text)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
    }
    return (React.createElement(PageContainer, null,
        React.createElement(PageToolbar, null,
            React.createElement(ToolbarTitle, { title: 'Cliente creado exitosamente' })),
        React.createElement(PageContent, { className: style.content },
            React.createElement(CustomerCard, { style: style.customerCard, customer: customer, business: business }),
            React.createElement("div", { className: style.rightContent },
                React.createElement(OutlineCard, { className: style.loginInfo },
                    React.createElement(Title, { title: 'Informacion de inicio de sesion' }),
                    React.createElement("div", { className: style.loginInfoContent },
                        React.createElement(TextItem, { style: style.fillTextItem, title: 'C\u00F3digo del negocio', text: formatId(business.id), icon: 'copy', onOptionClick: handleCopy(formatId(business.id)) }),
                        React.createElement(TextItem, { style: style.fillTextItem, title: 'Usuario', text: businessUser.userName, icon: 'copy', onOptionClick: handleCopy(businessUser.userName) }))),
                React.createElement(TextItem, { style: style.activationLink, title: 'Enlace de activacion', text: link, icon: 'copy', onOptionClick: handleCopy(link) }),
                React.createElement("div", { className: style.appsContainer },
                    React.createElement(OutlineCard, { className: style.appCard },
                        React.createElement("img", { className: style.appCardImage, src: androidLogo }),
                        React.createElement("span", { className: style.appCardTitle }, "Aplicaci\u00F3n Android"),
                        React.createElement("span", { className: style.appCardVersion }, "v6.2.1"),
                        React.createElement("div", { className: style.appCardOptions },
                            React.createElement(Option, { style: style.appCardOption, icon: 'copy', text: 'Copiar enlace' }),
                            React.createElement(Option, { style: style.appCardOption, icon: 'file_download', text: 'Descargar' }))),
                    React.createElement(OutlineCard, { className: style.appCard },
                        React.createElement("img", { className: style.appCardImage, src: webLogo }),
                        React.createElement("span", { className: style.appCardTitle }, "Aplicaci\u00F3n Web"),
                        React.createElement("a", { className: style.appCardUrl, href: 'https://wwww.recargasdominicanas.do' },
                            React.createElement("span", null, "recargasdominicanas.do"),
                            React.createElement(Icon, { className: style.externalIcon, icon: 'open_in_new_blank' })),
                        React.createElement("div", { className: style.appCardOptions },
                            React.createElement(Option, { style: style.appCardOption, icon: 'copy', text: 'Copiar enlace' }))))))));
}

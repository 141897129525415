var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Icon, OutlineButton, TextField } from '@recargas-dominicanas/core/components';
import { validators } from '@recargas-dominicanas/core/utils';
import { ProviderApi } from '@recargas-dominicanas/core/api';
import { ConfirmModal } from '../../../components/confirm-modal/confirm-modal.component';
import { ProviderStore } from '../../../store/providerStore';
import { style } from './configuration.module.css';
export function Configuration(props) {
    var providerId = props.providerId;
    var configs = ProviderStore.getConfigs(providerId) || [];
    var _a = useState(), configField = _a[0], setConfigField = _a[1];
    var _b = useState([]), configFields = _b[0], setConfigFields = _b[1];
    var _c = useState(false), openConfirmModal = _c[0], setOpenConfirmModal = _c[1];
    useEffect(function () {
        setConfigFields(mapConfigs());
    }, [configs]);
    function handleAddConfigField() {
        setConfigFields(function (state) { return __spreadArray(__spreadArray([], state, true), [{ name: '', value: '', updated: false }], false); });
    }
    function handleUpdateField(index, key, value) {
        setConfigFields(function (state) {
            var field = state[index];
            var config = configs.find(function (config) { return config.id === field.id; });
            if (key === 'name')
                field.name = value;
            if (key === 'value')
                field.value = value;
            if (config) {
                field.updated = (config === null || config === void 0 ? void 0 : config[key]) !== value;
            }
            return __spreadArray([], state, true);
        });
    }
    function handleDelete(index, configField) {
        if (configField.id) {
            setConfigField(configField);
            setOpenConfirmModal(true);
        }
        else {
            setConfigFields(function (state) { return state.filter(function (_, i) { return i !== index; }); });
        }
    }
    function handleConfirmDelete(configField) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ProviderApi.deleteConfig(configField.id)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, ProviderStore.fetchConfigs(providerId)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleSave() {
        return __awaiter(this, void 0, void 0, function () {
            var isValid, newConfigs, updatedConfigs, newConfigsPromises, updatedConfigsPromises;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isValid = configFields.every(function (config) {
                            var _a, _b;
                            return ((_a = config.name) === null || _a === void 0 ? void 0 : _a.trim()) !== '' &&
                                ((_b = config.value) === null || _b === void 0 ? void 0 : _b.trim()) !== '';
                        });
                        if (!isValid)
                            return [2 /*return*/];
                        newConfigs = configFields.filter(function (config) { return !config.id; });
                        updatedConfigs = configFields.filter(function (config) { return config.updated; });
                        newConfigsPromises = newConfigs.map(function (config) {
                            return ProviderApi.addConfig(providerId, config.name, config.value);
                        });
                        updatedConfigsPromises = updatedConfigs.map(function (config) {
                            return ProviderApi.updateConfig(config.id, config.name, config.value);
                        });
                        return [4 /*yield*/, Promise.all(__spreadArray(__spreadArray([], newConfigsPromises, true), updatedConfigsPromises, true))];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, ProviderStore.fetchConfigs(providerId)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleCancel() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setConfigFields(mapConfigs());
                return [2 /*return*/];
            });
        });
    }
    function mapConfigs() {
        if (!configs.length) {
            return [{ name: '', value: '', updated: false }];
        }
        return configs.map(function (_a) {
            var id = _a.id, name = _a.name, value = _a.value;
            return ({ id: id, name: name, value: value, updated: false });
        });
    }
    function compare() {
        return (configFields.length > configs.length ||
            configFields.some(function (config) { return config.updated; }));
    }
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: style.container },
            React.createElement(OutlineButton, { style: style.addButton, text: 'Agregar', onClick: handleAddConfigField }),
            React.createElement("div", { className: style.configurations }, configFields === null || configFields === void 0 ? void 0 : configFields.map(function (configField, index) { return (React.createElement("div", { key: index, className: style.configuration },
                React.createElement(TextField, { style: style.textField, placeholder: 'Nombre', value: configField.name, autofocus: !configField.id, validators: [validators.required], onChange: function (value) { return handleUpdateField(index, 'name', value); } }),
                React.createElement(TextField, { style: style.textField, placeholder: 'Valor', value: configField.value, autofocus: !configField.id, validators: [validators.required], onChange: function (value) { return handleUpdateField(index, 'value', value); } }),
                React.createElement(Icon, { className: style.icon, icon: 'delete', onClick: function () { return handleDelete(index, configField); } }))); })),
            React.createElement("div", { className: style.actionButtons },
                React.createElement(Button, { style: style.actionButton, text: 'Cancelar', disabled: !compare(), onClick: handleCancel }),
                React.createElement(OutlineButton, { style: style.actionButton, text: 'Guardar', disabled: !compare(), onClick: handleSave }))),
        configField &&
            React.createElement(ConfirmModal, { title: 'Borrar configuracion', description: 'Borrar configuracion', open: openConfirmModal, onClose: function () { return setOpenConfirmModal(false); }, onConfirm: function () { return handleConfirmDelete(configField); } },
                React.createElement("div", { className: style.configItem },
                    React.createElement("div", { className: style.configItemName }, configField.name),
                    React.createElement("div", { className: style.configItemValue }, configField.value)))));
}

import clsx from 'clsx';
import React, { createContext, useCallback, useRef, useState } from 'react';
import { Icon, Menu } from '@recargas-dominicanas/core/components';
import { mergeStyle } from './drop-down-button.module.css';
export var DropDownButtonContext = createContext({ onClose: function () { } });
export function DropDownButton(props) {
    var className = props.className, icon = props.icon, title = props.title, children = props.children, onClick = props.onClick;
    var style = mergeStyle(className);
    var menuIconRef = useRef(null);
    var _a = useState(false), openMenu = _a[0], setOpenMenu = _a[1];
    var onClose = useCallback(function () {
        setOpenMenu(false);
    }, []);
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.button, onClick: onClick },
            React.createElement(Icon, { className: style.icon, icon: icon }),
            React.createElement("span", { className: clsx(style.title) }, title)),
        React.createElement(Icon, { className: style.iconRight, icon: 'expand_more', iconRef: menuIconRef, onClick: function () { return setOpenMenu(true); } }),
        React.createElement(Menu, { style: style.menu, open: openMenu, anchor: menuIconRef, top: 8, onClose: function () { return setOpenMenu(false); } },
            React.createElement(DropDownButtonContext.Provider, { value: { onClose: onClose } }, children))));
}

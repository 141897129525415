// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pending-transaction-modal-modal_card-UGvZD {
  width: 344px;
  height: auto;
  max-height: 600px;  
}

.pending-transaction-modal-modal_content-FdH5P {
  padding: 0px 20px 20px;
}

.pending-transaction-modal-detailItem_container-MoekX {
  width: auto;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 16px;
  padding: 18px 0px;
}

.pending-transaction-modal-detailItem_title-gF9GE {
  font-size: 15px;
  font-weight: normal;
}

.pending-transaction-modal-detailItem_text-TVo0E {
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.75);
}

.pending-transaction-modal-detailItem_green-tfKSq {
  color: var(--money-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/pending-transactions/pending-transaction-modal/pending-transaction-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".modal_card {\n  width: 344px;\n  height: auto;\n  max-height: 600px;  \n}\n\n.modal_content {\n  padding: 0px 20px 20px;\n}\n\n.detailItem_container {\n  width: auto;\n  flex-wrap: wrap;\n  row-gap: 12px;\n  column-gap: 16px;\n  padding: 18px 0px;\n}\n\n.detailItem_title {\n  font-size: 15px;\n  font-weight: normal;\n}\n\n.detailItem_text {\n  font-weight: 500;\n  font-size: 15px;\n  color: rgba(0, 0, 0, 0.75);\n}\n\n.detailItem_green {\n  color: var(--money-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `pending-transaction-modal-modal_card-UGvZD`,
	"modal_content": `pending-transaction-modal-modal_content-FdH5P`,
	"detailItem_container": `pending-transaction-modal-detailItem_container-MoekX`,
	"detailItem_title": `pending-transaction-modal-detailItem_title-gF9GE`,
	"detailItem_text": `pending-transaction-modal-detailItem_text-TVo0E`,
	"detailItem_green": `pending-transaction-modal-detailItem_green-tfKSq`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-profit-withdrawal-modal-modal_card-_Fn6Q {
  width: 340px;
  height: initial;
  min-height: 480px;
}

.register-profit-withdrawal-modal-modal_content-lo7Dp {
  padding: 0px 20px;
}

.register-profit-withdrawal-modal-profit-CAimW {
  margin-top: 20px;
}

.register-profit-withdrawal-modal-profitTitle-OyCa9 {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
}

.register-profit-withdrawal-modal-profitValue-nbhuZ {
  font-size: 17px;
  font-weight: bold;
  color: var(--money-color);
  margin-top: 12px;
}

.register-profit-withdrawal-modal-textField_container-mhOMS, .register-profit-withdrawal-modal-dateTime_container-K0Vhr {
  width: 100%;
  margin-top: 24px;
}

.register-profit-withdrawal-modal-textField_input-x6t6K {
  height: 40px;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/register-profit-withdrawal-modal/register-profit-withdrawal-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".modal_card {\n  width: 340px;\n  height: initial;\n  min-height: 480px;\n}\n\n.modal_content {\n  padding: 0px 20px;\n}\n\n.profit {\n  margin-top: 20px;\n}\n\n.profitTitle {\n  font-size: 15px;\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.profitValue {\n  font-size: 17px;\n  font-weight: bold;\n  color: var(--money-color);\n  margin-top: 12px;\n}\n\n.textField_container, .dateTime_container {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.textField_input {\n  height: 40px;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `register-profit-withdrawal-modal-modal_card-_Fn6Q`,
	"modal_content": `register-profit-withdrawal-modal-modal_content-lo7Dp`,
	"profit": `register-profit-withdrawal-modal-profit-CAimW`,
	"profitTitle": `register-profit-withdrawal-modal-profitTitle-OyCa9`,
	"profitValue": `register-profit-withdrawal-modal-profitValue-nbhuZ`,
	"textField_container": `register-profit-withdrawal-modal-textField_container-mhOMS`,
	"dateTime_container": `register-profit-withdrawal-modal-dateTime_container-K0Vhr`,
	"textField_input": `register-profit-withdrawal-modal-textField_input-x6t6K`
};
export default ___CSS_LOADER_EXPORT___;

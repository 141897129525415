var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef, useState, Fragment } from 'react';
import { BusinessUserApi } from '@recargas-dominicanas/core/api';
import { TableRow } from '@recargas-dominicanas/core/components';
import { Menu } from '@recargas-dominicanas/core/components';
import { Cell } from '@recargas-dominicanas/core/components';
import { CellIcon } from '@recargas-dominicanas/core/components';
import { BusinessUserModal } from '../business-user-modal/business-user-modal.componet';
import { BusinessUserConfirmModal } from '../business-user-confirm-modal/business-user-confirm-modal.component';
import { BusinessUserRenameModal } from '../business-user-rename-modal/business-user-rename-modal.component';
import { BusinessStore } from '../../store/businessStore';
import { MenuOption } from '../menu-option/menu-option.component';
import { mergeStyle } from './business-user-item.module.css';
export function BusinessUserItem(props) {
    var businessId = props.businessId, businessUser = props.businessUser, customStyle = props.style;
    var style = mergeStyle(customStyle);
    var iconRef = useRef(null);
    var _a = useState(false), openMenu = _a[0], setOpenMenu = _a[1];
    var _b = useState(false), openModal = _b[0], setOpenModal = _b[1];
    var _c = useState(false), openRenameModal = _c[0], setOpenRenameModal = _c[1];
    var _d = useState(false), openResetPasswordModal = _d[0], setOpenResetPasswordModal = _d[1];
    var _e = useState(false), openResetedPasswordModal = _e[0], setOpenResetedPasswordModal = _e[1];
    var _f = useState(false), openDeleteModal = _f[0], setOpenDeleteModal = _f[1];
    function handleOpenMenu(event) {
        setOpenMenu(function (state) { return !state; });
        event === null || event === void 0 ? void 0 : event.stopPropagation();
    }
    function handleCloseMenu() {
        setOpenMenu(false);
    }
    function handleResetPassword() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, BusinessUserApi.resetPassword(businessUser.id)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, BusinessStore.fetchBusinessUsers(businessId)];
                    case 2:
                        _a.sent();
                        setOpenResetedPasswordModal(true);
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleEnable() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, BusinessUserApi.enable(businessUser.id)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, BusinessStore.fetchBusinessUsers(businessId)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleDisable() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, BusinessUserApi.disable(businessUser.id)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, BusinessStore.fetchBusinessUsers(businessId)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleDelete() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, BusinessUserApi.delete(businessUser.id)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, BusinessStore.fetchBusinessUsers(businessId)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(Fragment, null,
        React.createElement(TableRow, { key: businessUser.id, style: style.tableRow, onClick: function () { return setOpenModal(true); } },
            React.createElement(Cell, { text: businessUser.name }),
            React.createElement(Cell, { weight: 'medium', text: businessUser.userName }),
            businessUser.state === 'ACTIVATED' &&
                React.createElement(Cell, { weight: 'medium', color: 'green', text: 'Activado' }),
            businessUser.state === 'NOT_ACTIVATED' &&
                React.createElement(Cell, { weight: 'medium', color: 'darkgray', text: 'No Activado' }),
            businessUser.state === 'DISABLED' &&
                React.createElement(Cell, { weight: 'medium', color: 'gray', text: 'Desativado' }),
            React.createElement(CellIcon, { refIcon: iconRef, onClick: handleOpenMenu })),
        React.createElement(Menu, { open: openMenu, anchor: iconRef, style: style.menu, top: 8, onClose: handleCloseMenu },
            React.createElement(MenuOption, { text: 'Cambiar nombre', onClick: function () { return setOpenRenameModal(true); } }),
            (businessUser === null || businessUser === void 0 ? void 0 : businessUser.state) === 'ACTIVATED' &&
                React.createElement(MenuOption, { text: 'Resetear contrase\u00F1a', onClick: function () { return setOpenResetPasswordModal(true); } }),
            (businessUser === null || businessUser === void 0 ? void 0 : businessUser.state) === 'DISABLED' &&
                React.createElement(MenuOption, { text: 'Activar', onClick: handleEnable }),
            (businessUser === null || businessUser === void 0 ? void 0 : businessUser.state) === 'ACTIVATED' &&
                React.createElement(MenuOption, { text: 'Desactivar', onClick: handleDisable }),
            React.createElement(MenuOption, { style: style.deleteMenuOption, text: 'Eliminar', onClick: function () { return setOpenDeleteModal(true); } })),
        React.createElement(BusinessUserModal, { open: openModal, title: 'Usuario', businessUser: businessUser, onClose: function () { return setOpenModal(false); } }),
        React.createElement(BusinessUserModal, { open: openResetedPasswordModal, title: 'Contrase\u00F1a reseteada', businessUser: businessUser, onClose: function () { return setOpenResetedPasswordModal(false); } }),
        React.createElement(BusinessUserConfirmModal, { open: openDeleteModal, title: 'Eliminar usuario', description: 'Eliminar usuario', businessUser: businessUser, onConfirm: handleDelete, onClose: function () { return setOpenDeleteModal(false); } }),
        React.createElement(BusinessUserConfirmModal, { open: openResetPasswordModal, title: 'Resetear contrase\u00F1a', description: 'Resetear la contrase\u00F1a del usuario', businessUser: businessUser, onConfirm: handleResetPassword, onClose: function () { return setOpenResetPasswordModal(false); } }),
        React.createElement(BusinessUserRenameModal, { open: openRenameModal, businessId: businessId, businessUser: businessUser, onClose: function () { return setOpenRenameModal(false); } })));
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deposits-container-p7lKJ {
  height: 100%;
  padding: 20px 0px 0px 20px;
  overflow: hidden;
}

.deposits-table_container-PT61b {
  padding-right: 10px;
  overflow: scroll;
}

.deposits-tableHeader_tab-vkBhH {
  border-top: 1px solid var(--border-color);
}

.deposits-description-JEsJV {
  width: 100%;
  min-width: 280px;
  white-space: normal;
  padding: 8px 26px;
}
`, "",{"version":3,"sources":["webpack://./src/components/deposits/deposits.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".container {\n  height: 100%;\n  padding: 20px 0px 0px 20px;\n  overflow: hidden;\n}\n\n.table_container {\n  padding-right: 10px;\n  overflow: scroll;\n}\n\n.tableHeader_tab {\n  border-top: 1px solid var(--border-color);\n}\n\n.description {\n  width: 100%;\n  min-width: 280px;\n  white-space: normal;\n  padding: 8px 26px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `deposits-container-p7lKJ`,
	"table_container": `deposits-table_container-PT61b`,
	"tableHeader_tab": `deposits-tableHeader_tab-vkBhH`,
	"description": `deposits-description-JEsJV`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { useForm, validators } from '@recargas-dominicanas/core/utils';
import { TextField } from '@recargas-dominicanas/core/components';
import { Modal } from '../../../../components/modal/modal.component';
import { UploadImage } from '../../../../components/upload-image/upload-image.component';
import { mergeStyle } from './edit-invoice-modal.module.css';
export function EditInvoiceModal(props) {
    var open = props.open, title = props.title, invoice = props.invoice, customStyle = props.style, onClose = props.onClose, onAccept = props.onAccept;
    if (!open)
        return null;
    var style = mergeStyle(customStyle);
    var form = useForm({
        id: invoice === null || invoice === void 0 ? void 0 : invoice.id,
        type: 'Factura',
        name: (invoice === null || invoice === void 0 ? void 0 : invoice.name) || '',
        profit: (invoice === null || invoice === void 0 ? void 0 : invoice.profit.toString()) || '',
        image: undefined
    });
    function handleAccept() {
        if (form.isValid()) {
            onAccept === null || onAccept === void 0 ? void 0 : onAccept(form.value);
            handleClose();
        }
    }
    function handleClose() {
        form.clear();
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    return (React.createElement(Modal, { open: open, title: title, style: style.modal, secondaryButton: 'Guardar', onSecondaryClick: handleAccept, onClose: handleClose },
        React.createElement(UploadImage, { required: true, image: invoice === null || invoice === void 0 ? void 0 : invoice.image, field: form.fields.image }),
        React.createElement("div", { className: style.rightPane },
            React.createElement(TextField, { style: style.textField, placeholder: 'Nombre', formField: form.fields.name, validators: [validators.required] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Beneficio', type: 'number', formField: form.fields.profit, validators: [validators.required] }))));
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.release-note-item-container-b31Jz {
  border-radius: var(--small-border-radius);
  border: 1px solid var(--border-color);
  padding: 10px 10px 16px 12px;
  margin-top: 20px;
}

.release-note-item-container-b31Jz:first-of-type {
  margin-top: 12px;
}

.release-note-item-toolbar-M1Ik8 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.release-note-item-title-x6JzH {
  font-size: 14px;
}

.release-note-item-icon-pIuvP {
  --size: 20px;
  color: rgba(0, 0, 0, 0.75)
}

.release-note-item-description-QTk9F {
  font-size: 15px;
  font-weight: 300;
  margin-top: 12px;
}

.release-note-item-menu_container-R00Il {
  width: 160px;
}

.release-note-item-menuOption_container-q7php {
  padding: 8px 16px;
}

.release-note-item-menuOption_text-xrLYq {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}`, "",{"version":3,"sources":["webpack://./src/pages/app-releases/release-note-item/release-note-item.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,qCAAqC;EACrC,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ;AACF;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".container {\n  border-radius: var(--small-border-radius);\n  border: 1px solid var(--border-color);\n  padding: 10px 10px 16px 12px;\n  margin-top: 20px;\n}\n\n.container:first-of-type {\n  margin-top: 12px;\n}\n\n.toolbar {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n}\n\n.title {\n  font-size: 14px;\n}\n\n.icon {\n  --size: 20px;\n  color: rgba(0, 0, 0, 0.75)\n}\n\n.description {\n  font-size: 15px;\n  font-weight: 300;\n  margin-top: 12px;\n}\n\n.menu_container {\n  width: 160px;\n}\n\n.menuOption_container {\n  padding: 8px 16px;\n}\n\n.menuOption_text {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.9);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `release-note-item-container-b31Jz`,
	"toolbar": `release-note-item-toolbar-M1Ik8`,
	"title": `release-note-item-title-x6JzH`,
	"icon": `release-note-item-icon-pIuvP`,
	"description": `release-note-item-description-QTk9F`,
	"menu_container": `release-note-item-menu_container-R00Il`,
	"menuOption_container": `release-note-item-menuOption_container-q7php`,
	"menuOption_text": `release-note-item-menuOption_text-xrLYq`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { useForm, validators } from '@recargas-dominicanas/core/utils';
import { Modal } from '../../../../components/modal/modal.component';
import { UploadImage } from '../../../../components/upload-image/upload-image.component';
import { mergeStyle } from './edit-product-modal.module.css';
import { Select, SelectOption, TextField } from '@recargas-dominicanas/core/components';
export function EditProductModal(props) {
    var open = props.open, title = props.title, buttonText = props.buttonText, product = props.product, customStyle = props.style, onClose = props.onClose, onAccept = props.onAccept;
    if (!open)
        return null;
    var style = mergeStyle(customStyle);
    var form = useForm({
        id: product === null || product === void 0 ? void 0 : product.id,
        name: (product === null || product === void 0 ? void 0 : product.name) || '',
        type: product === null || product === void 0 ? void 0 : product.type,
        profit: (product === null || product === void 0 ? void 0 : product.profit.toString()) || '',
        min: (product === null || product === void 0 ? void 0 : product.min.toString()) || '',
        max: (product === null || product === void 0 ? void 0 : product.max.toString()) || '',
        image: undefined
    });
    function handleAccept() {
        if (form.isValid()) {
            onAccept === null || onAccept === void 0 ? void 0 : onAccept(form.value);
            handleClose();
        }
    }
    function handleClose() {
        form.clear();
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    return (React.createElement(Modal, { open: open, title: title, style: style.modal, secondaryButton: buttonText, onSecondaryClick: handleAccept, onClose: handleClose },
        React.createElement(UploadImage, { required: true, image: product === null || product === void 0 ? void 0 : product.image, field: form.fields.image }),
        React.createElement("div", { className: style.rightPane },
            React.createElement(TextField, { style: style.textField, placeholder: 'Nombre', formField: form.fields.name, validators: [validators.required] }),
            React.createElement(Select, { style: style.select, placeholder: 'Tipo', required: true, formField: form.fields.type },
                React.createElement(SelectOption, { style: style.selectOption, value: 'Recarga', label: 'Recarga' }, "Recarga"),
                React.createElement(SelectOption, { style: style.selectOption, value: 'Paquetico', label: 'Paquetico' }, "Paquetico")),
            React.createElement(TextField, { style: style.textField, placeholder: 'Porcentaje', type: 'number', formField: form.fields.profit, validators: [validators.required] }),
            React.createElement("div", { className: style.inputContainer },
                React.createElement(TextField, { style: style.inlineTextField, placeholder: 'M\u00EDnimo', type: 'number', formField: form.fields.min, validators: [validators.required] }),
                React.createElement(TextField, { style: style.inlineTextField, placeholder: 'M\u00E1ximo', type: 'number', formField: form.fields.max, validators: [validators.required], onEnter: handleAccept })))));
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Fragment, useMemo, useState } from 'react';
import { copy } from '@recargas-dominicanas/core/utils';
import { Modal } from '../modal/modal.component';
import { Icon, OutlineButton } from '@recargas-dominicanas/core/components';
import { mergeStyle } from './business-user-modal.module.css';
import { AccessTokenApi } from '@recargas-dominicanas/core/api';
export function BusinessUserModal(props) {
    var _a;
    var open = props.open, title = props.title, businessUser = props.businessUser, customStyle = props.style, onClose = props.onClose;
    if (!open)
        return null;
    var style = mergeStyle(customStyle);
    var _b = useState((_a = businessUser === null || businessUser === void 0 ? void 0 : businessUser.user) === null || _a === void 0 ? void 0 : _a.accessToken), accessToken = _b[0], setAccessToken = _b[1];
    var isExpired = useMemo(function () { return Date.now() > Date.parse(accessToken === null || accessToken === void 0 ? void 0 : accessToken.expirationDate); }, [accessToken]);
    function handleCopyLink() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, copy("".concat(process.env.WEB_APP_DOMAIN, "/create-password/").concat(accessToken.token))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleUpdateLink() {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = setAccessToken;
                        return [4 /*yield*/, AccessTokenApi.update(accessToken.id)];
                    case 1:
                        _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(Modal, { open: true, style: style.modal, title: title, secondaryButton: 'Aceptar', onClose: onClose, onSecondaryClick: onClose },
        React.createElement("div", { className: style.item },
            React.createElement("span", { className: style.itemTitle }, businessUser.name),
            React.createElement("span", { className: style.username }, businessUser.userName)),
        businessUser.state === 'ACTIVATED' &&
            React.createElement(Fragment, null,
                React.createElement("div", { className: style.stateTitle }, "Estado"),
                React.createElement("div", { className: style.stateActivated }, "Activado")),
        businessUser.state === 'DISABLED' &&
            React.createElement(Fragment, null,
                React.createElement("div", { className: style.stateTitle }, "Estado"),
                React.createElement("div", { className: style.stateDisabled }, "Desactivado")),
        accessToken &&
            React.createElement(Fragment, null,
                React.createElement("span", { className: style.title },
                    "Enlace de activacion ",
                    isExpired && 'expirado'),
                React.createElement("div", { className: style.activationLink },
                    React.createElement("span", { className: style.link },
                        process.env.WEB_APP_DOMAIN,
                        "/create-password/",
                        accessToken.token),
                    React.createElement(Icon, { className: style.icon, icon: 'copy', onClick: handleCopyLink })),
                isExpired &&
                    React.createElement(OutlineButton, { style: style.updateLinkButton, text: 'Actualizar enlace', onClick: handleUpdateLink }))));
}

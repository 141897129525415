var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from 'react';
import { ProviderApi } from '@recargas-dominicanas/core/api';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { DatePicker } from '../../../components/date-picker/date-picker.component';
import { OptionButton } from '../../../components/option-button/option-button.component';
import { VerticalBarChart } from '../../../components/vertical-bar-chart/vertical-bar-chart.component';
import { SalesByProductChart } from '../../../components/sales-by-product-chart/sales-by-product-chart.component';
import { style } from './sales.module.css';
export function Sales(props) {
    var provider = props.provider;
    var menuRef = useRef(null);
    var _a = useState(false), openMenu = _a[0], setOpenMenu = _a[1];
    var _b = useState('Mes actual'), optionDateLabel = _b[0], setOptionDateLabel = _b[1];
    var date = useState(new Date())[0];
    var _c = useState(new Date(date.getFullYear(), date.getMonth())), startDate = _c[0], setStartDate = _c[1];
    var _d = useState(new Date(date.getFullYear(), date.getMonth() + 1)), endDate = _d[0], setEndDate = _d[1];
    var _e = useState({ balance: 0, sales: 0, profit: 0 }), salesSummary = _e[0], setSalesSummary = _e[1];
    var _f = useState([]), sales = _f[0], setSales = _f[1];
    var _g = useState([]), profits = _g[0], setProfits = _g[1];
    var _h = useState('day'), timeFormat = _h[0], setTimeFormat = _h[1];
    var _j = useState(15), xTicksCount = _j[0], setXTicksCount = _j[1];
    var _k = useState(8), barWidth = _k[0], setBarWidth = _k[1];
    var _l = useState([]), salesByProducts = _l[0], setSalesByProducts = _l[1];
    useEffect(function () {
        handleFetchData();
    }, []);
    function handleFetchData() {
        return __awaiter(this, void 0, void 0, function () {
            var sales, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!(timeFormat === 'day')) return [3 /*break*/, 2];
                        return [4 /*yield*/, ProviderApi.getSalesByDay(provider.id, startDate, endDate)];
                    case 1:
                        _a = _d.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, ProviderApi.getSalesByMonth(provider.id, startDate, endDate)];
                    case 3:
                        _a = _d.sent();
                        _d.label = 4;
                    case 4:
                        sales = _a;
                        _b = setSalesSummary;
                        return [4 /*yield*/, ProviderApi.getSales(provider.id, startDate, endDate)];
                    case 5:
                        _b.apply(void 0, [_d.sent()]);
                        _c = setSalesByProducts;
                        return [4 /*yield*/, ProviderApi.getSalesByProduct(provider.id, startDate, endDate)];
                    case 6:
                        _c.apply(void 0, [_d.sent()]);
                        setSales(sales.map(function (sale) { return ({ value: sale.sales, date: sale.date }); }));
                        setProfits(sales.map(function (sale) { return ({ value: sale.profit, date: sale.date }); }));
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleDateChange(startDate, endDate, label, type) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, sales_1, sales_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        setStartDate(startDate);
                        setEndDate(endDate);
                        setOptionDateLabel(label);
                        _a = setSalesSummary;
                        return [4 /*yield*/, ProviderApi.getSales(provider.id, startDate, endDate)];
                    case 1:
                        _a.apply(void 0, [_c.sent()]);
                        _b = setSalesByProducts;
                        return [4 /*yield*/, ProviderApi.getSalesByProduct(provider.id, startDate, endDate)];
                    case 2:
                        _b.apply(void 0, [_c.sent()]);
                        if (!(type === 'year')) return [3 /*break*/, 4];
                        return [4 /*yield*/, ProviderApi.getSalesByMonth(provider.id, startDate, endDate)];
                    case 3:
                        sales_1 = _c.sent();
                        setSales(sales_1.map(function (sale) { return ({ value: sale.sales, date: sale.date }); }));
                        setProfits(sales_1.map(function (sale) { return ({ value: sale.profit, date: sale.date }); }));
                        setTimeFormat('month');
                        setXTicksCount(12);
                        setBarWidth(16);
                        _c.label = 4;
                    case 4:
                        if (!(type === 'month')) return [3 /*break*/, 6];
                        return [4 /*yield*/, ProviderApi.getSalesByDay(provider.id, startDate, endDate)];
                    case 5:
                        sales_2 = _c.sent();
                        setSales(sales_2.map(function (sale) { return ({ value: sale.sales, date: sale.date }); }));
                        setProfits(sales_2.map(function (sale) { return ({ value: sale.profit, date: sale.date }); }));
                        setTimeFormat('day');
                        setXTicksCount(15);
                        setBarWidth(8);
                        _c.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.header },
            React.createElement("div", { className: style.salesItems },
                React.createElement("div", { className: style.item },
                    React.createElement("span", { className: style.title }, "Balance"),
                    React.createElement("span", { className: style.amount }, formatCurrency(salesSummary.balance))),
                React.createElement("div", { className: style.item },
                    React.createElement("span", { className: style.title }, "Ventas"),
                    React.createElement("span", { className: style.amount }, formatCurrency(salesSummary.sales))),
                React.createElement("div", { className: style.item },
                    React.createElement("span", { className: style.title }, "Beneficio"),
                    React.createElement("span", { className: style.amount }, formatCurrency(salesSummary.profit)))),
            React.createElement("div", { className: style.options },
                React.createElement(OptionButton, { icon: 'refresh', title: 'Actualizar', onClick: handleFetchData }),
                React.createElement(OptionButton, { optionRef: menuRef, icon: 'event', title: optionDateLabel, rightIcon: 'expand_more', onClick: function () { return setOpenMenu(function (open) { return !open; }); } }),
                React.createElement(DatePicker, { anchor: menuRef, open: openMenu, onChange: handleDateChange, onClose: function () { return setOpenMenu(false); } }))),
        React.createElement("div", { className: style.content },
            React.createElement(SalesByProductChart, { title: 'Ventas por productos', products: salesByProducts }),
            React.createElement("div", { className: style.contentRight },
                React.createElement(VerticalBarChart, { className: style.verticalBarChart, title: 'Ventas', barWidth: barWidth, xTicksCount: xTicksCount, yTicksCount: 3, timeFormat: timeFormat, data: sales }),
                React.createElement(VerticalBarChart, { className: style.verticalBarChart, title: 'Beneficios', barWidth: barWidth, xTicksCount: xTicksCount, yTicksCount: 3, timeFormat: timeFormat, data: profits })))));
}

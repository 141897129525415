// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-account-select-container-fk671 {
  width: 100%;
  margin-top: 28px;
}

.bank-account-select-popup-aKYa1 {
  padding: 4px 0px;
  max-height: 220px;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/bank-account-select/bank-account-select.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".container {\n  width: 100%;\n  margin-top: 28px;\n}\n\n.popup {\n  padding: 4px 0px;\n  max-height: 220px;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `bank-account-select-container-fk671`,
	"popup": `bank-account-select-popup-aKYa1`
};
export default ___CSS_LOADER_EXPORT___;

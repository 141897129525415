// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.config-container-BUJ8K {
  height: 100%;
  padding-left: 10px;
  overflow: hidden;
}

.config-table_container-eeyO5 {
  padding: 0px 10px;
}

.config-tableHeader_cellContent-yR1uV {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.config-addOption_container-AdeTH {
  width: min-content;
  margin-left: auto;
}

.config-addOption_title-dROgt {
  font-weight: normal;
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/config/config.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container {\n  height: 100%;\n  padding-left: 10px;\n  overflow: hidden;\n}\n\n.table_container {\n  padding: 0px 10px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.addOption_container {\n  width: min-content;\n  margin-left: auto;\n}\n\n.addOption_title {\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `config-container-BUJ8K`,
	"table_container": `config-table_container-eeyO5`,
	"tableHeader_cellContent": `config-tableHeader_cellContent-yR1uV`,
	"addOption_container": `config-addOption_container-AdeTH`,
	"addOption_title": `config-addOption_title-dROgt`
};
export default ___CSS_LOADER_EXPORT___;

import * as d3 from 'd3';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { formatChartValue } from '../../utils/formatChartValue';
import { useRect } from '../../hooks/useRect';
import { style } from './vertical-bar-chart.module.css';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
var locale = d3.timeFormatLocale({
    dateTime: '%A, %e de %B de %Y, %X',
    date: '%d/%m/%Y',
    time: '%H:%M:%S',
    periods: ['am', 'pm'],
    days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    shortDays: ['Dom', 'Lun', 'Mar', 'Mié', 'Fue', 'Vie', 'Sáb'],
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
});
export function VerticalBarChart(props) {
    var _a, _b;
    var title = props.title, _c = props.barWidth, barWidth = _c === void 0 ? 16 : _c, className = props.className, xTicksCount = props.xTicksCount, yTicksCount = props.yTicksCount, _d = props.timeFormat, timeFormat = _d === void 0 ? 'week' : _d, _e = props.data, data = _e === void 0 ? [] : _e;
    var _f = useState(0), resize = _f[0], setResize = _f[1];
    var svgRef = useRef(null);
    var deps = data.map(function (d) { return d.date + d.value; }).toString();
    var container = useRect({ padding: { left: 0, top: 20, right: 4 } });
    var tickText = useRect();
    var tickLine = useRect({ margin: { left: 20 } });
    var barRect = useRect({ width: barWidth, margin: { bottom: 20 } });
    var barTitle = useRect();
    var barValue = useRect({ margin: { bottom: 8 } });
    var xAxisStart = container.padding.left + tickText.width + tickLine.margin.left + (barRect.width / 2);
    var xAxisEnd = container.width - container.padding.right - (barRect.width / 2);
    var yAxisStart = container.padding.top;
    var yAxisEnd = container.height - barTitle.height - barRect.margin.bottom;
    var startDate = new Date((_a = data[0]) === null || _a === void 0 ? void 0 : _a.date);
    var endDate = new Date((_b = data[data.length - 1]) === null || _b === void 0 ? void 0 : _b.date);
    var max = useMemo(function () { return d3.max(data, function (d) { return d.value; }); }, [deps]);
    var x = useMemo(function () { return d3.scaleTime([startDate, endDate], [xAxisStart, xAxisEnd]); }, [deps, xAxisStart, xAxisEnd]);
    var y = useMemo(function () { return d3.scaleLinear([max, 0], [yAxisStart, yAxisEnd]); }, [max, yAxisStart, yAxisEnd]);
    var xTicks = useMemo(function () { return x.ticks(xTicksCount); }, [x, xTicksCount]);
    var yTicks = useMemo(function () { return y.nice(yTicksCount).ticks(yTicksCount); }, [y, yTicksCount]);
    useEffect(function () {
        var onResize = function () { return setResize(window.innerWidth + window.innerHeight); };
        window.addEventListener('resize', onResize);
        return function () { return window.removeEventListener('resize', onResize); };
    }, []);
    useEffect(function () {
        var rect = svgRef.current.getBoundingClientRect();
        container.setWidth(rect.width);
        container.setHeight(rect.height);
    }, [resize]);
    useEffect(function () {
        if (container.width && container.height) {
            var tickTextElement = svgRef.current.querySelector(".".concat(style.tickText));
            var barTitleElement = svgRef.current.querySelector(".".concat(style.barTitle));
            tickText.setWidth((tickTextElement === null || tickTextElement === void 0 ? void 0 : tickTextElement.getBBox().width) || 0);
            barTitle.setHeight((barTitleElement === null || barTitleElement === void 0 ? void 0 : barTitleElement.getBBox().height) || 0);
        }
    }, [deps, container.width, container.height]);
    function formatDate(date) {
        if (timeFormat === 'day')
            return locale.format('%-d')(date);
        if (timeFormat === 'week')
            return locale.format('%b %-d')(date);
        if (timeFormat === 'month')
            return locale.format('%b')(date);
    }
    return (React.createElement("div", { className: clsx(style.container, className) },
        React.createElement("div", { className: style.title }, title),
        React.createElement("svg", { className: style.chart, ref: svgRef },
            React.createElement("g", null, xTicks.map(function (tick) {
                return React.createElement("text", { key: tick.toString(), className: style.barTitle, x: x(tick), y: container.height, alignmentBaseline: 'text-after-edge' }, formatDate(tick));
            })),
            React.createElement("g", null, yTicks.map(function (tick) {
                return React.createElement("g", { key: tick },
                    React.createElement("text", { className: style.tickText, x: container.padding.left + tickText.width, y: y(tick), alignmentBaseline: 'middle' }, formatChartValue(tick)),
                    React.createElement("line", { className: style.line, x1: container.padding.left + tickText.width + tickLine.margin.left, x2: container.width - container.padding.right, y1: y(tick), y2: y(tick) }));
            })),
            React.createElement("g", null, container.width && container.height && data.map(function (d) {
                return React.createElement("g", { key: d.date, className: style.barContainer },
                    d.value > 0 &&
                        React.createElement("text", { className: style.value, x: x(new Date(d.date)), y: y(d.value) - barValue.margin.bottom, textAnchor: 'middle', alignmentBaseline: 'text-after-edge' }, formatCurrency(d.value)),
                    React.createElement("rect", { key: d.date, className: style.rect, x: x(new Date(d.date)) - (barRect.width / 2), y: y(d.value), width: barRect.width, height: y(0) - y(d.value) }));
            })))));
}

import React from 'react';
import clsx from 'clsx';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { style } from './legend-item.module.css';
export function LegendItem(props) {
    var title = props.title, color = props.color, amount = props.amount;
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: clsx(style.circle, style[color]) }),
        React.createElement("div", { className: style.title }, title),
        amount !== undefined &&
            React.createElement("div", { className: style.amount }, formatCurrency(amount))));
}

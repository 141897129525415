var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ProviderApi } from '@recargas-dominicanas/core/api';
import { BaseStore } from './BaseStore';
var Store = /** @class */ (function (_super) {
    __extends(Store, _super);
    function Store() {
        return _super.call(this, 'providersState') || this;
    }
    Store.prototype.getAll = function () {
        return this.findAll('provider');
    };
    Store.prototype.getById = function (id) {
        return this.findById(id, 'provider');
    };
    Store.prototype.getTransactionsPage = function (id) {
        return this.findById(id, 'transactionsPage');
    };
    Store.prototype.getProducts = function (id) {
        return this.findById(id, 'products');
    };
    Store.prototype.getBankAccounts = function (id) {
        return this.findById(id, 'bankAccounts');
    };
    Store.prototype.getDeposits = function (id) {
        return this.findById(id, 'deposits');
    };
    Store.prototype.getConfigs = function (id) {
        return this.findById(id, 'configs');
    };
    Store.prototype.getExternalBalance = function (id) {
        return this.findById(id, 'externalBalance');
    };
    Store.prototype.fetchAll = function () {
        return __awaiter(this, void 0, void 0, function () {
            var providers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ProviderApi.getAll()];
                    case 1:
                        providers = _a.sent();
                        providers.forEach(function (provider) {
                            if (_this.exists(provider.id)) {
                                _this.update(provider.id, 'provider', provider);
                                return;
                            }
                            _this.add('provider', provider);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Store.prototype.fetchById = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var provider;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ProviderApi.getById(id)];
                    case 1:
                        provider = _a.sent();
                        if (this.exists(id)) {
                            this.update(id, 'provider', provider);
                            return [2 /*return*/];
                        }
                        this.add('provider', provider);
                        return [2 /*return*/];
                }
            });
        });
    };
    Store.prototype.fetchTransactions = function (id, page, size) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.update;
                        _b = [id, 'transactionsPage'];
                        return [4 /*yield*/, ProviderApi.getTransactions(id, page, size)];
                    case 1:
                        _a.apply(this, _b.concat([_c.sent()]));
                        return [2 /*return*/];
                }
            });
        });
    };
    Store.prototype.fetchProducts = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.update;
                        _b = [id, 'products'];
                        return [4 /*yield*/, ProviderApi.getProviderProducts(id)];
                    case 1:
                        _a.apply(this, _b.concat([_c.sent()]));
                        return [2 /*return*/];
                }
            });
        });
    };
    Store.prototype.fetchDeposits = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.update;
                        _b = [id, 'deposits'];
                        return [4 /*yield*/, ProviderApi.getDeposits(id)];
                    case 1:
                        _a.apply(this, _b.concat([_c.sent()]));
                        return [2 /*return*/];
                }
            });
        });
    };
    Store.prototype.fetchBankAccounts = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.update;
                        _b = [id, 'bankAccounts'];
                        return [4 /*yield*/, ProviderApi.getBankAccounts(id)];
                    case 1:
                        _a.apply(this, _b.concat([_c.sent()]));
                        return [2 /*return*/];
                }
            });
        });
    };
    Store.prototype.fetchConfigs = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.update;
                        _b = [id, 'configs'];
                        return [4 /*yield*/, ProviderApi.getConfigs(id)];
                    case 1:
                        _a.apply(this, _b.concat([_c.sent()]));
                        return [2 /*return*/];
                }
            });
        });
    };
    Store.prototype.fetchExternalBalance = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.update;
                        _b = [id, 'externalBalance'];
                        return [4 /*yield*/, ProviderApi.getExternalBalance(id)];
                    case 1:
                        _a.apply(this, _b.concat([_c.sent()]));
                        return [2 /*return*/];
                }
            });
        });
    };
    return Store;
}(BaseStore));
export var ProviderStore = new Store();

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-picker-container-pooh_ {
  width: max-content;
}

.image-picker-title-gtA9o {
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.75);
}

.image-picker-content-iM45Z {
  width: 220px;
  height: 100px;
  display: flex;
  flex-direction: column;
  background-color: white;
  cursor: pointer;
  border: 1px solid #E0E0E0;
  border-radius: var(--small-border-radius);
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.image-picker-icon-p5FW0 {
  --size: 48px;
  color: rgba(0, 0, 0, 0.25);
}

.image-picker-placeholder-m5h2W {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 8px;
}

.image-picker-image-nEDSD {
  width: 64px;
  height: 64px;
  display: block;
  object-fit: scale-down;
  margin: auto;
}

.image-picker-uploadInput-ozvVZ {
  display: none;
}

.image-picker-error-SZYgF {
  margin-left: 0px;
  margin-top: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/image-picker/image-picker.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,eAAe;EACf,yBAAyB;EACzB,yCAAyC;EACzC,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".container {\n  width: max-content;\n}\n\n.title {\n  font-size: 15px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 0.75);\n}\n\n.content {\n  width: 220px;\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  background-color: white;\n  cursor: pointer;\n  border: 1px solid #E0E0E0;\n  border-radius: var(--small-border-radius);\n  align-items: center;\n  justify-content: center;\n  margin-top: 12px;\n}\n\n.icon {\n  --size: 48px;\n  color: rgba(0, 0, 0, 0.25);\n}\n\n.placeholder {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.5);\n  margin-top: 8px;\n}\n\n.image {\n  width: 64px;\n  height: 64px;\n  display: block;\n  object-fit: scale-down;\n  margin: auto;\n}\n\n.uploadInput {\n  display: none;\n}\n\n.error {\n  margin-left: 0px;\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `image-picker-container-pooh_`,
	"title": `image-picker-title-gtA9o`,
	"content": `image-picker-content-iM45Z`,
	"icon": `image-picker-icon-p5FW0`,
	"placeholder": `image-picker-placeholder-m5h2W`,
	"image": `image-picker-image-nEDSD`,
	"uploadInput": `image-picker-uploadInput-ozvVZ`,
	"error": `image-picker-error-SZYgF`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banks-container-myy2o {
  padding: 0px 32px 20px;
}

.banks-toolbar-UoglB {
  height: 56px;
  align-items: center;
}

.banks-title_title-CFVxr {
  font-size: 17px;
}

.banks-title_icon-MzGWF {
  --size: 20px;
}

.banks-content-ZtxNt {
  margin-top: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/banks/banks.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  padding: 0px 32px 20px;\n}\n\n.toolbar {\n  height: 56px;\n  align-items: center;\n}\n\n.title_title {\n  font-size: 17px;\n}\n\n.title_icon {\n  --size: 20px;\n}\n\n.content {\n  margin-top: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `banks-container-myy2o`,
	"toolbar": `banks-toolbar-UoglB`,
	"title_title": `banks-title_title-CFVxr`,
	"title_icon": `banks-title_icon-MzGWF`,
	"content": `banks-content-ZtxNt`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useRef } from 'react';
import { formatCurrency, formatDate } from '@recargas-dominicanas/core/utils';
import { Text } from '@recargas-dominicanas/core/components';
import { TableHeader } from '@recargas-dominicanas/core/components';
import { Table } from '@recargas-dominicanas/core/components';
import { TableRow } from '@recargas-dominicanas/core/components';
import { Cell } from '@recargas-dominicanas/core/components';
import { CellImage } from '@recargas-dominicanas/core/components';
import { style } from './transactions.module.css';
export function Transactions(props) {
    var _a;
    var transactions = props.transactions;
    var tableRef = useRef(null);
    useEffect(function () {
        if (tableRef.current)
            tableRef.current.scrollTop = 0;
    }, [transactions]);
    if (!transactions)
        return null;
    return (React.createElement("div", { className: style.container },
        React.createElement(Table, { style: style.table, refElement: tableRef },
            React.createElement(TableHeader, { style: style.tableHeader },
                React.createElement(Text, { text: 'Comp.' }),
                React.createElement(Text, { text: 'Tel\u00E9fono' }),
                React.createElement(Text, { text: 'Monto' }),
                React.createElement(Text, { text: 'Tipo' }),
                React.createElement(Text, { text: 'Fecha' }),
                ((_a = transactions[0]) === null || _a === void 0 ? void 0 : _a.business) && React.createElement(Text, { text: 'Negocio' }),
                React.createElement(Text, { text: 'Estado' })),
            React.createElement("tbody", null, transactions.map(function (transaction) {
                var _a;
                return (React.createElement(TableRow, { key: transaction.id, style: style.tableRow },
                    React.createElement(CellImage, { src: transaction.product.image }),
                    (transaction.product.type === 'Recarga' || transaction.product.type === 'Paquetico') &&
                        React.createElement(Cell, { className: style.phone, text: transaction.phone }),
                    transaction.product.type === 'Pin' &&
                        React.createElement(Cell, { className: style.phone, text: '* * * * * * * *' }),
                    transaction.product.type === 'Factura' &&
                        React.createElement(Cell, { className: style.phone, text: (_a = transaction.contract) === null || _a === void 0 ? void 0 : _a.nic }),
                    React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(transaction.amount) }),
                    React.createElement(Cell, { text: transaction.product.type }),
                    React.createElement(Cell, { text: formatDate(transaction.date) }),
                    transaction.business && React.createElement(Cell, { text: transaction.business.name }),
                    transaction.cancelled
                        ? React.createElement(Cell, { color: 'red', weight: 'medium', text: 'Cancelada' })
                        : React.createElement(Cell, { color: 'darkgray', text: 'Realizada' })));
            })))));
}

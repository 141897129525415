import React, { useEffect } from 'react';
import { BankAccountStore } from '../../store/bankAccountStore';
import { BankAccountItem } from '../../components/bank-account-item/bank-account-item.component';
import { OptionButton } from '../../components/option-button/option-button.component';
import { useHistory } from 'react-router-dom';
import { style } from './bank-accounts.module.css';
import { PageContainer, PageToolbar, Title, Text, PageContent, OutlineTable, TableHeader } from '@recargas-dominicanas/core/components';
export function BankAccounts() {
    var history = useHistory();
    var bankAccounts = BankAccountStore.getAll();
    useEffect(function () {
        BankAccountStore.fetchAll();
    }, []);
    return (React.createElement(PageContainer, { className: style.container },
        React.createElement(PageToolbar, { className: style.toolbar },
            React.createElement(Title, { style: style.title, title: 'Cuentas bancarias' }),
            React.createElement(OptionButton, { icon: 'account_balance', title: 'Bancos', rightIcon: 'chevron_right', onClick: function () { return history.push('/banks'); } })),
        React.createElement(PageContent, { className: style.content },
            React.createElement(OutlineTable, null,
                React.createElement(TableHeader, { style: style.tableHeader },
                    React.createElement(Text, { text: 'Banco' }),
                    React.createElement(Text, { text: 'Nombre' }),
                    React.createElement(Text, { text: 'Cuenta' }),
                    React.createElement(Text, { text: 'balance' }),
                    React.createElement(Text, { text: '' })),
                React.createElement("tbody", null, bankAccounts.map(function (bankAccount) {
                    return React.createElement(BankAccountItem, { key: bankAccount.id, bankAccount: bankAccount, onClick: function () { return history.push("/bank-account/".concat(bankAccount.id)); } });
                }))))));
}

import React, { useContext } from 'react';
import { Option } from '@recargas-dominicanas/core/components';
import { DropDownButtonContext } from '../drop-down-button/drop-down-button.component';
import { style } from './drop-down-option.module.css';
export function DropDownOption(props) {
    var title = props.title, onClick = props.onClick;
    var dropDownButton = useContext(DropDownButtonContext);
    function handleClick() {
        onClick === null || onClick === void 0 ? void 0 : onClick();
        dropDownButton.onClose();
    }
    return (React.createElement(Option, { style: style.option, hiddeIcon: true, text: title, onClick: handleClick }));
}

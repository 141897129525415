// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend-item-container-E262O {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
}

.legend-item-circle-oeWIi {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.legend-item-title-l9Xkc, .legend-item-amount-L2U3e {
  line-height: 16px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
}

.legend-item-green-RS8fR {
  background-color: #27AE60;
}

.legend-item-blue-EjVIx {
  background-color: #2F80ED;
}

.legend-item-purple-NLqoB {
  background-color: #9B51E0;
}

.legend-item-orange-dmfhT {
  background-color: #F2994A;
}
`, "",{"version":3,"sources":["webpack://./src/components/legend-item/legend-item.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container {\n  width: fit-content;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.circle {\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n}\n\n.title, .amount {\n  line-height: 16px;\n  color: rgba(0, 0, 0, 0.8);\n  font-size: 12px;\n}\n\n.green {\n  background-color: #27AE60;\n}\n\n.blue {\n  background-color: #2F80ED;\n}\n\n.purple {\n  background-color: #9B51E0;\n}\n\n.orange {\n  background-color: #F2994A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `legend-item-container-E262O`,
	"circle": `legend-item-circle-oeWIi`,
	"title": `legend-item-title-l9Xkc`,
	"amount": `legend-item-amount-L2U3e`,
	"green": `legend-item-green-RS8fR`,
	"blue": `legend-item-blue-EjVIx`,
	"purple": `legend-item-purple-NLqoB`,
	"orange": `legend-item-orange-dmfhT`
};
export default ___CSS_LOADER_EXPORT___;

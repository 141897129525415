import clsx from 'clsx';
import React from 'react';
import { mergeStyle } from './option-button.module.css';
import { Icon } from '@recargas-dominicanas/core/components';
export function OptionButton(props) {
    var className = props.className, icon = props.icon, title = props.title, rightIcon = props.rightIcon, optionRef = props.optionRef, _a = props.disabled, disabled = _a === void 0 ? false : _a, onClick = props.onClick;
    var style = mergeStyle(className);
    function handleClick() {
        if (!disabled)
            onClick === null || onClick === void 0 ? void 0 : onClick();
    }
    return (React.createElement("div", { ref: optionRef, className: clsx(style.container, disabled && style.containerDisabled), onClick: handleClick },
        React.createElement(Icon, { className: clsx(style.icon, disabled && style.iconDisabled), icon: icon }),
        React.createElement("span", { className: clsx(style.title, !rightIcon && style.margin12px, disabled && style.titleDisabled) }, title),
        rightIcon &&
            React.createElement(Icon, { className: clsx(style.iconRight, disabled && style.iconDisabled), icon: rightIcon })));
}

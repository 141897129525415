// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.capital-chart-container-AG8Tp {
  width: 360px;
}

.capital-chart-title-g2Hhi {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 500;
}

.capital-chart-chart-dFP1h {
  display: block;
  margin: 20px auto;
}

.capital-chart-capitalTitle-hCJr1 {
  font-size: 12px;
}

.capital-chart-capital-o5D6T {
  color: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  font-weight: 500;
}

.capital-chart-green-Se6M7 {
  fill: #27AE60;
}

.capital-chart-blue-G_DzP {
  fill: #2F80ED;
}

.capital-chart-purple-rDZPw {
  fill: #9B51E0;
}

.capital-chart-legend-G7mei {
  display: flex;
  flex-flow: row wrap;
  column-gap: 24px;
  row-gap: 20px;
  margin-top: 40px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/finances/capital-chart/capital-chart.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".container {\n  width: 360px;\n}\n\n.title {\n  color: rgba(0, 0, 0, 0.8);\n  font-size: 15px;\n  font-weight: 500;\n}\n\n.chart {\n  display: block;\n  margin: 20px auto;\n}\n\n.capitalTitle {\n  font-size: 12px;\n}\n\n.capital {\n  color: rgba(0, 0, 0, 0.8);\n  font-size: 13px;\n  font-weight: 500;\n}\n\n.green {\n  fill: #27AE60;\n}\n\n.blue {\n  fill: #2F80ED;\n}\n\n.purple {\n  fill: #9B51E0;\n}\n\n.legend {\n  display: flex;\n  flex-flow: row wrap;\n  column-gap: 24px;\n  row-gap: 20px;\n  margin-top: 40px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `capital-chart-container-AG8Tp`,
	"title": `capital-chart-title-g2Hhi`,
	"chart": `capital-chart-chart-dFP1h`,
	"capitalTitle": `capital-chart-capitalTitle-hCJr1`,
	"capital": `capital-chart-capital-o5D6T`,
	"green": `capital-chart-green-Se6M7`,
	"blue": `capital-chart-blue-G_DzP`,
	"purple": `capital-chart-purple-rDZPw`,
	"legend": `capital-chart-legend-G7mei`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-container-wDOiX {
  padding: 0px 32px 20px;
}

.bank-toolbar-BORKd {
  height: 56px;
  display: flex;
  gap: 32px;
  align-items: center;
}

.bank-toolbarRight-QqLn_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.bank-title_container-nUfLF {
  width: 360px;
  flex-grow: 0;
}

.bank-title_title-yS11h {
  font-size: 17px;
}

.bank-title_icon-Lk1ia {
  --size: 20px;
}

.bank-content-MCtqV {
  display: flex;
  gap: 32px;
  margin-top: 0px;
}

.bank-outlineCard-wsmEr {
  width: 360px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bank-image-RQVQl {
  width: 80px;
  margin: 40px;
}

.bank-bankAccountsTitle-jmxjA {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}

.bank-table_outlineCard-ANIfL {
  height: auto;
  flex-grow: 1;
}

.bank-tableHeader_container-Ne76A {
  height: 48px;
}

.bank-tableHeader_cellContent-w6bzD {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank/bank.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".container {\n  padding: 0px 32px 20px;\n}\n\n.toolbar {\n  height: 56px;\n  display: flex;\n  gap: 32px;\n  align-items: center;\n}\n\n.toolbarRight {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-grow: 1;\n}\n\n.title_container {\n  width: 360px;\n  flex-grow: 0;\n}\n\n.title_title {\n  font-size: 17px;\n}\n\n.title_icon {\n  --size: 20px;\n}\n\n.content {\n  display: flex;\n  gap: 32px;\n  margin-top: 0px;\n}\n\n.outlineCard {\n  width: 360px;\n  flex-grow: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.image {\n  width: 80px;\n  margin: 40px;\n}\n\n.bankAccountsTitle {\n  font-size: 16px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.table_outlineCard {\n  height: auto;\n  flex-grow: 1;\n}\n\n.tableHeader_container {\n  height: 48px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `bank-container-wDOiX`,
	"toolbar": `bank-toolbar-BORKd`,
	"toolbarRight": `bank-toolbarRight-QqLn_`,
	"title_container": `bank-title_container-nUfLF`,
	"title_title": `bank-title_title-yS11h`,
	"title_icon": `bank-title_icon-Lk1ia`,
	"content": `bank-content-MCtqV`,
	"outlineCard": `bank-outlineCard-wsmEr`,
	"image": `bank-image-RQVQl`,
	"bankAccountsTitle": `bank-bankAccountsTitle-jmxjA`,
	"table_outlineCard": `bank-table_outlineCard-ANIfL`,
	"tableHeader_container": `bank-tableHeader_container-Ne76A`,
	"tableHeader_cellContent": `bank-tableHeader_cellContent-w6bzD`
};
export default ___CSS_LOADER_EXPORT___;

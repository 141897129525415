// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editModal-modal_card-Z3RY3 {
  width: 324px;
  height: initial;
  min-height: 332px;
}

.editModal-modal_content-uR87t {
  padding: 0px 20px;
}

.editModal-textField_container-Bn1yY {
  width: 100%;
  margin-top: 24px;
}

.editModal-textField_input-k_B9P {
  height: 40px;
  font-size: 15px;
}

.editModal-textField_container-Bn1yY:first-child {
  margin-top: 20px;
}

.editModal-textField_container-Bn1yY:last-child {
  margin-bottom: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/config/editModal/edit-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".modal_card {\n  width: 324px;\n  height: initial;\n  min-height: 332px;\n}\n\n.modal_content {\n  padding: 0px 20px;\n}\n\n.textField_container {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.textField_input {\n  height: 40px;\n  font-size: 15px;\n}\n\n.textField_container:first-child {\n  margin-top: 20px;\n}\n\n.textField_container:last-child {\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `editModal-modal_card-Z3RY3`,
	"modal_content": `editModal-modal_content-uR87t`,
	"textField_container": `editModal-textField_container-Bn1yY`,
	"textField_input": `editModal-textField_input-k_B9P`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transactions-container-kM7HX {
  height: 100%;
  padding: 20px 0px 0px 20px;
  overflow: hidden;
}

.transactions-table_container-wVXof {
  padding-right: 10px;
}

.transactions-tableHeader_tab-cTJ3Y {
  border-top: 1px solid var(--border-color);
}

.transactions-tableRow_cell-WWowQ:last-child {
  padding-right: 16px;
}

.transactions-phone-MKDj9 {
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/transactions/transactions.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  height: 100%;\n  padding: 20px 0px 0px 20px;\n  overflow: hidden;\n}\n\n.table_container {\n  padding-right: 10px;\n}\n\n.tableHeader_tab {\n  border-top: 1px solid var(--border-color);\n}\n\n.tableRow_cell:last-child {\n  padding-right: 16px;\n}\n\n.phone {\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `transactions-container-kM7HX`,
	"table_container": `transactions-table_container-wVXof`,
	"tableHeader_tab": `transactions-tableHeader_tab-cTJ3Y`,
	"tableRow_cell": `transactions-tableRow_cell-WWowQ`,
	"phone": `transactions-phone-MKDj9`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-modal_card-i99A3 {
  width: 500px;
  height: 600px;
  display: flex;
  padding: 0px;
}

.modal-modalToolbar_container-jXY4O {
  border-bottom: 1px solid var(--border-color);
}

.modal-content-hIuwL {
  padding: 0px 20px;
}

.modal-modalToolbar_title-pCWp0 {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.modal-modalToolbar_close-nOYJJ {
  color: rgba(0, 0, 0, 0.75);
}

.modal-error_container-vviOV {
  margin: 16px 20px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modal/modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,YAAY;AACd;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".modal_card {\n  width: 500px;\n  height: 600px;\n  display: flex;\n  padding: 0px;\n}\n\n.modalToolbar_container {\n  border-bottom: 1px solid var(--border-color);\n}\n\n.content {\n  padding: 0px 20px;\n}\n\n.modalToolbar_title {\n  font-size: 16px;\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.modalToolbar_close {\n  color: rgba(0, 0, 0, 0.75);\n}\n\n.error_container {\n  margin: 16px 20px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `modal-modal_card-i99A3`,
	"modalToolbar_container": `modal-modalToolbar_container-jXY4O`,
	"content": `modal-content-hIuwL`,
	"modalToolbar_title": `modal-modalToolbar_title-pCWp0`,
	"modalToolbar_close": `modal-modalToolbar_close-nOYJJ`,
	"error_container": `modal-error_container-vviOV`
};
export default ___CSS_LOADER_EXPORT___;

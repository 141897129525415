var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useRef, useState } from 'react';
import { TextField, Text, Icon, FieldError, TextArea } from '@recargas-dominicanas/core/components';
import { useField, formatCurrency, useForm, validators } from '@recargas-dominicanas/core/utils';
import { Modal } from '../../../../components/modal/modal.component';
import { mergeStyle } from './edit-pin-modal.module.css';
import { ImagePicker } from '../../../../components/image-picker/image-picker.component';
export function EditPinModal(props) {
    var _a;
    var open = props.open, title = props.title, product = props.product, customStyle = props.style, onClose = props.onClose, onAccept = props.onAccept;
    var style = mergeStyle(customStyle);
    var addPinRef = useRef(null);
    var price = useField('');
    var _b = useState((product === null || product === void 0 ? void 0 : product.prices) || []), prices = _b[0], setPrices = _b[1];
    var _c = useState(), priceError = _c[0], setPriceError = _c[1];
    var _d = useState(), error = _d[0], setError = _d[1];
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    useEffect(function () {
        setPrices((product === null || product === void 0 ? void 0 : product.prices) || []);
        return function () {
            if (!open)
                clear();
        };
    }, [open, product]);
    var form = useForm({
        id: product === null || product === void 0 ? void 0 : product.id,
        name: (product === null || product === void 0 ? void 0 : product.name) || '',
        instructions: ((_a = product === null || product === void 0 ? void 0 : product.pin) === null || _a === void 0 ? void 0 : _a.instructions) || '',
        image: undefined
    });
    function handleAddPrice() {
        var value = parseInt(price.value);
        var exists = prices.some(function (_a) {
            var price = _a.price;
            return price === value;
        });
        setPriceError(undefined);
        if (isNaN(value) || value <= 0) {
            setPriceError('Precio invalido');
            return;
        }
        if (exists) {
            setPriceError('Precio duplicado');
            return;
        }
        setPrices(function (prices) { return __spreadArray(__spreadArray([], prices, true), [{ price: value }], false); });
        setTimeout(function () { var _a; return (_a = addPinRef === null || addPinRef === void 0 ? void 0 : addPinRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth', block: 'end' }); }, 60);
        price.clear();
    }
    function handleDeletePrice(price) {
        setPrices(function (prices) { return prices.filter(function (_price) { return _price.price !== price; }); });
    }
    function handleAccept() {
        return __awaiter(this, void 0, void 0, function () {
            var isValid, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (loading)
                            return [2 /*return*/];
                        isValid = form.isValid();
                        if (prices.length < 1 || !isValid) {
                            setPriceError('Requerido');
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        setError(undefined);
                        setLoading(true);
                        return [4 /*yield*/, (onAccept === null || onAccept === void 0 ? void 0 : onAccept(__assign(__assign({}, form.value), { prices: JSON.stringify(prices) })))];
                    case 2:
                        _a.sent();
                        setLoading(false);
                        handleClose();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        setError('Ocurrio un error al guardar el pin');
                        setLoading(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function handleClose() {
        clear();
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    function clear() {
        form.clear();
        setPrices([]);
        setPriceError(undefined);
        setError(undefined);
        setLoading(false);
    }
    return (React.createElement(Modal, { open: open, title: title, style: style.modal, secondaryButton: 'Guardar', error: error, onSecondaryClick: handleAccept, onClose: handleClose },
        React.createElement("div", { className: style.left },
            React.createElement(TextField, { label: 'Nombre', style: style.textField, placeholder: 'Nombre', formField: form.fields.name, validators: [validators.required] }),
            React.createElement(ImagePicker, { required: true, image: product === null || product === void 0 ? void 0 : product.image, field: form.fields.image }),
            React.createElement(TextArea, { style: style.textArea, label: 'Instrucciones', formField: form.fields.instructions, placeholder: 'Descripci\u00F3n' })),
        React.createElement("div", null,
            React.createElement(Text, { className: style.title, text: 'Precios' }),
            React.createElement("div", { className: style.prices },
                prices
                    .slice()
                    .sort(function (p1, p2) { return p1.price - p2.price; })
                    .map(function (_a) {
                    var price = _a.price;
                    return React.createElement("div", { className: style.priceItem, key: price },
                        React.createElement("div", { className: style.priceItemText }, formatCurrency(price)),
                        React.createElement(Icon, { className: style.priceItemIcon, icon: 'clear', onClick: function () { return handleDeletePrice(price); } }));
                }),
                React.createElement("div", { className: style.addPin, ref: addPinRef },
                    React.createElement(TextField, { style: style.addPinInput, type: 'number', placeholder: 'Agregar precio', formField: price, onEnter: handleAddPrice }),
                    React.createElement(Icon, { className: style.addPinIcon, icon: 'add', onClick: handleAddPrice })),
                priceError &&
                    React.createElement("div", { ref: function (e) { return e === null || e === void 0 ? void 0 : e.scrollIntoView({ behavior: 'smooth', block: 'end' }); } },
                        React.createElement(FieldError, { message: priceError }))))));
}

import React, { useState } from 'react';
import { Button, FieldError, TextArea, TextField } from '@recargas-dominicanas/core/components';
import { useForm } from '@recargas-dominicanas/core/utils';
import { style } from './release-note-field.module.css';
export function ReleaseNoteField(props) {
    var releaseNote = props.releaseNote, onSave = props.onSave, onCancel = props.onCancel;
    var _a = useState(false), showError = _a[0], setShowError = _a[1];
    var form = useForm({
        title: (releaseNote === null || releaseNote === void 0 ? void 0 : releaseNote.title) || '',
        description: (releaseNote === null || releaseNote === void 0 ? void 0 : releaseNote.description) || ''
    });
    function handleCancel() {
        form.clear();
        setShowError(false);
        onCancel();
    }
    function handleAdd() {
        if (!validate(form.value.title) || !validate(form.value.description)) {
            setShowError(true);
            return;
        }
        onSave(form.value.title, form.value.description);
        form.clear();
        setShowError(false);
    }
    function validate(value) {
        return value && value.trim() !== '';
    }
    return (React.createElement("div", { className: style.field, ref: function (e) { return e === null || e === void 0 ? void 0 : e.scrollIntoView({ behavior: 'smooth', block: 'end' }); } },
        React.createElement(TextField, { style: style.textField, formField: form.fields.title, placeholder: 'Titulo', autofocus: true }),
        React.createElement("div", { className: style.divider }),
        React.createElement(TextArea, { style: style.textArea, formField: form.fields.description, placeholder: 'Descripci\u00F3n' }),
        React.createElement("div", { className: style.bottom },
            showError &&
                React.createElement(FieldError, { className: style.error, message: 'Todos los campos son requeridos' }),
            React.createElement("div", { className: style.buttons },
                React.createElement(Button, { style: style.button, text: 'Cancelar', onClick: handleCancel }),
                React.createElement(Button, { style: style.button, text: 'Guardar', onClick: handleAdd })))));
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-releases-container-ymUVp {
  padding: 0px 32px 20px;
}

.app-releases-toolbar-tj5U8 {
  height: 56px;
  align-items: center;
}

.app-releases-title_title-dP8qs {
  font-size: 17px;
}

.app-releases-content-DWDXF {
  margin-top: 0px;
}

.app-releases-tableHeader_cellContent-og7oP {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/pages/app-releases/app-releases.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".container {\n  padding: 0px 32px 20px;\n}\n\n.toolbar {\n  height: 56px;\n  align-items: center;\n}\n\n.title_title {\n  font-size: 17px;\n}\n\n.content {\n  margin-top: 0px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-releases-container-ymUVp`,
	"toolbar": `app-releases-toolbar-tj5U8`,
	"title_title": `app-releases-title_title-dP8qs`,
	"content": `app-releases-content-DWDXF`,
	"tableHeader_cellContent": `app-releases-tableHeader_cellContent-og7oP`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from '@recargas-dominicanas/core/components';
import { Login } from '../login/login.component';
import { Main } from '../main/main.component';
export function App() {
    return (React.createElement(BrowserRouter, null,
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: '/login', component: Login }),
            React.createElement(ProtectedRoute, { path: '/', role: 'admin' },
                React.createElement(Main, null)))));
}

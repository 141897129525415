// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-field-container-TNI4b {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 16px;
  padding: 11px 0px 18px;
  margin: 0px 1px;
}

.edit-field-header-AiZef {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.edit-field-title-LDzlj {
  display: block;
  font-size: 15px;
  font-weight: normal;
}

.edit-field-icon-ToQrc {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.75);
  font-size: 20px;
  padding: 0px;
}

.edit-field-value-_Edb4 {
  display: block;
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
}

.edit-field-placeholder-zYKbI {
  color: rgba(0, 0, 0, 0.6);
  font-weight: normal;
  font-style: italic;
}

.edit-field-textArea_container-rPYZX {
  width: 100%;
}

.edit-field-textArea_input-qDReP {
  height: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/pending-transactions/edit-field/edit-field.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,0BAA0B;EAC1B,eAAe;EACf,YAAY;AACd;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":[".container {\n  display: flex;\n  flex-wrap: wrap;\n  row-gap: 12px;\n  column-gap: 16px;\n  padding: 11px 0px 18px;\n  margin: 0px 1px;\n}\n\n.header {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.title {\n  display: block;\n  font-size: 15px;\n  font-weight: normal;\n}\n\n.icon {\n  width: 32px;\n  height: 32px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: rgba(0, 0, 0, 0.75);\n  font-size: 20px;\n  padding: 0px;\n}\n\n.value {\n  display: block;\n  font-weight: 500;\n  font-size: 15px;\n  color: rgba(0, 0, 0, 0.7);\n}\n\n.placeholder {\n  color: rgba(0, 0, 0, 0.6);\n  font-weight: normal;\n  font-style: italic;\n}\n\n.textArea_container {\n  width: 100%;\n}\n\n.textArea_input {\n  height: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `edit-field-container-TNI4b`,
	"header": `edit-field-header-AiZef`,
	"title": `edit-field-title-LDzlj`,
	"icon": `edit-field-icon-ToQrc`,
	"value": `edit-field-value-_Edb4`,
	"placeholder": `edit-field-placeholder-zYKbI`,
	"textArea_container": `edit-field-textArea_container-rPYZX`,
	"textArea_input": `edit-field-textArea_input-qDReP`
};
export default ___CSS_LOADER_EXPORT___;

import clsx from 'clsx';
import React from 'react';
import { style } from './status.module.css';
import { Icon } from '@recargas-dominicanas/core/components';
export function Status(props) {
    var icon = props.icon, title = props.title, _a = props.severity, severity = _a === void 0 ? 'info' : _a, rotatedIcon = props.rotatedIcon, onClick = props.onClick;
    return (React.createElement("div", { onClick: onClick, className: clsx(style.container, onClick && style.clickable, onClick && severity === 'error' && style.clickableError) },
        React.createElement(Icon, { className: clsx(style.icon, rotatedIcon && style.rotateAnimation, severity === 'error' && style.error), icon: icon }),
        React.createElement("span", { className: clsx(style.title, severity === 'error' && style.error) }, title)));
}

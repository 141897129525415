// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-modal-modal_card-U3nwV {
  width: 360px;
  min-height: 280px;
  height: auto;
}

.confirm-modal-toolbar_container-jR5Zv {
  border-bottom: 1px solid var(--border-color);
}

.confirm-modal-toolbar_title-SHiSu {
  font-size: 16px;
}

.confirm-modal-content-BYPmY {
  margin-top: 24px;
}

.confirm-modal-label-z7YtS {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-left: 4px;
  margin-top: 24px;
}

.confirm-modal-checkbox-pG0ue {
  margin: 0px;
  margin-right: 8px;
}

.confirm-modal-error_container-uQcLV {
  margin: 24px 20px 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/confirm-modal/confirm-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".modal_card {\n  width: 360px;\n  min-height: 280px;\n  height: auto;\n}\n\n.toolbar_container {\n  border-bottom: 1px solid var(--border-color);\n}\n\n.toolbar_title {\n  font-size: 16px;\n}\n\n.content {\n  margin-top: 24px;\n}\n\n.label {\n  display: flex;\n  align-items: center;\n  font-size: 15px;\n  margin-left: 4px;\n  margin-top: 24px;\n}\n\n.checkbox {\n  margin: 0px;\n  margin-right: 8px;\n}\n\n.error_container {\n  margin: 24px 20px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `confirm-modal-modal_card-U3nwV`,
	"toolbar_container": `confirm-modal-toolbar_container-jR5Zv`,
	"toolbar_title": `confirm-modal-toolbar_title-SHiSu`,
	"content": `confirm-modal-content-BYPmY`,
	"label": `confirm-modal-label-z7YtS`,
	"checkbox": `confirm-modal-checkbox-pG0ue`,
	"error_container": `confirm-modal-error_container-uQcLV`
};
export default ___CSS_LOADER_EXPORT___;

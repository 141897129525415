import React, { cloneElement } from 'react';
import { findChild } from '@recargas-dominicanas/core/utils';
import { OutlineCard, Switch, TabBar } from '@recargas-dominicanas/core/components';
import { mergeStyle } from './tabs-pane.module.css';
export function TabsPane(props) {
    var children = props.children, customStyle = props.style;
    var style = mergeStyle(customStyle);
    var tabBar = findChild(children, TabBar);
    var switchComponent = findChild(children, Switch);
    return (React.createElement(OutlineCard, { className: style.container },
        tabBar && cloneElement(tabBar, { style: style.tabBar }),
        switchComponent));
}

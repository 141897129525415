// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.release-note-field-field-oIMLb {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: var(--small-border-radius);
  padding: 0px 12px;
  margin-top: 20px;
}

.release-note-field-textField_container-diV2n, .release-note-field-textArea_container-nMOyU {
  width: 100%;
}

.release-note-field-textField_input-NqoNZ {
  height: 40px;
  font-size: 14px;
  border: none;
  padding: 0px;
}

.release-note-field-textField_input-NqoNZ::placeholder {
  font-size: 14px;
  font-weight: 300;
}

.release-note-field-divider-zHa4_ {
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.release-note-field-textArea_input-_nBsf {
  height: 58px;
  font-size: 14px;
  font-weight: 300;
  border: none;
  padding: 12px 0px;
}

.release-note-field-textArea_input-_nBsf::placeholder {
  font-size: 14px;
}

.release-note-field-bottom-RcZMV {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
}

.release-note-field-error-zy7dR {
  margin-top: 0px;
}

.release-note-field-buttons-OnGcc {
  display: flex;
  margin-left: auto;
}

.release-note-field-button_base-YnoGH {
  height: 24px;
  font-size: 14px;
  padding: 0px;
  margin-left: 20px;
}

.release-note-field-button_text-todzf {
  font-size: 13px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/pages/app-releases/release-note-field/release-note-field.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,qCAAqC;EACrC,yCAAyC;EACzC,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".field {\n  width: 100%;\n  border: 1px solid rgba(0, 0, 0, 0.10);\n  border-radius: var(--small-border-radius);\n  padding: 0px 12px;\n  margin-top: 20px;\n}\n\n.textField_container, .textArea_container {\n  width: 100%;\n}\n\n.textField_input {\n  height: 40px;\n  font-size: 14px;\n  border: none;\n  padding: 0px;\n}\n\n.textField_input::placeholder {\n  font-size: 14px;\n  font-weight: 300;\n}\n\n.divider {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.10);\n}\n\n.textArea_input {\n  height: 58px;\n  font-size: 14px;\n  font-weight: 300;\n  border: none;\n  padding: 12px 0px;\n}\n\n.textArea_input::placeholder {\n  font-size: 14px;\n}\n\n.bottom {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-bottom: 6px;\n}\n\n.error {\n  margin-top: 0px;\n}\n\n.buttons {\n  display: flex;\n  margin-left: auto;\n}\n\n.button_base {\n  height: 24px;\n  font-size: 14px;\n  padding: 0px;\n  margin-left: 20px;\n}\n\n.button_text {\n  font-size: 13px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `release-note-field-field-oIMLb`,
	"textField_container": `release-note-field-textField_container-diV2n`,
	"textArea_container": `release-note-field-textArea_container-nMOyU`,
	"textField_input": `release-note-field-textField_input-NqoNZ`,
	"divider": `release-note-field-divider-zHa4_`,
	"textArea_input": `release-note-field-textArea_input-_nBsf`,
	"bottom": `release-note-field-bottom-RcZMV`,
	"error": `release-note-field-error-zy7dR`,
	"buttons": `release-note-field-buttons-OnGcc`,
	"button_base": `release-note-field-button_base-YnoGH`,
	"button_text": `release-note-field-button_text-todzf`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.withdrawals-container-xarxL {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.withdrawals-tableContainer-HISQC {
  height: 100%;
  padding-left: 10px;
  overflow: hidden;
}

.withdrawals-table_container-YrY6Y {
  padding: 0px 10px;
}

.withdrawals-tableHeader_cellContent-dnVcw {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.withdrawals-description-OtDXi {
  width: 100%;
  min-width: 280px;
  padding: 8px 26px;
  white-space: normal;
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/withdrawals/withdrawals.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.tableContainer {\n  height: 100%;\n  padding-left: 10px;\n  overflow: hidden;\n}\n\n.table_container {\n  padding: 0px 10px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.description {\n  width: 100%;\n  min-width: 280px;\n  padding: 8px 26px;\n  white-space: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `withdrawals-container-xarxL`,
	"tableContainer": `withdrawals-tableContainer-HISQC`,
	"table_container": `withdrawals-table_container-YrY6Y`,
	"tableHeader_cellContent": `withdrawals-tableHeader_cellContent-dnVcw`,
	"description": `withdrawals-description-OtDXi`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products-container-po_rs {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 10px;
}

.products-table_container-VwFBL {
  padding: 0px 10px;
}

.products-tableHeader_cellContent-uM8YT {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.products-addButton_base-wj0Ze {
  width: 152px;
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/provider/products/products.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  padding-left: 10px;\n}\n\n.table_container {\n  padding: 0px 10px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.addButton_base {\n  width: 152px;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `products-container-po_rs`,
	"table_container": `products-table_container-VwFBL`,
	"tableHeader_cellContent": `products-tableHeader_cellContent-uM8YT`,
	"addButton_base": `products-addButton_base-wj0Ze`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { DetailItem } from '@recargas-dominicanas/core/components';
import { Modal } from '../../../../components/modal/modal.component';
import { style } from './pending-transaction-modal.module.css';
import { formatCurrency, formatDate } from '@recargas-dominicanas/core/utils';
export function PendingTransactionModal(props) {
    var open = props.open, pendingTransaction = props.pendingTransaction, onClose = props.onClose;
    return (React.createElement(Modal, { open: open, style: style.modal, title: 'Transacci\u00F3n', onClose: onClose },
        React.createElement(DetailItem, { style: style.detailItem, title: 'Tipo', text: pendingTransaction.type === 'deposit' ? 'Deposito' : 'Retiro' }),
        React.createElement(DetailItem, { style: style.detailItem, title: 'Monto', color: 'green', weight: 'medium', text: formatCurrency(pendingTransaction.balance) }),
        React.createElement(DetailItem, { style: style.detailItem, title: 'Fecha', text: formatDate(pendingTransaction.date) }),
        React.createElement(DetailItem, { style: style.detailItem, title: 'Fecha de creaci\u00F3n', text: formatDate(pendingTransaction.creationDate) }),
        React.createElement(DetailItem, { style: style.detailItem, title: 'Estado', color: pendingTransaction.status === 'registered' ? 'green' : undefined, weight: 'medium', text: pendingTransaction.status === 'registered' ? 'Registrado' : 'No registrado' }),
        pendingTransaction.reference &&
            React.createElement(DetailItem, { style: style.detailItem, title: 'Referencia', text: pendingTransaction.reference }),
        pendingTransaction.description &&
            React.createElement(DetailItem, { style: style.detailItem, title: 'Descripci\u00F3n', text: pendingTransaction.description })));
}

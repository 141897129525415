// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-user-item-menu_container-HpE1V {
  min-width: 220px;
}

.business-user-item-deleteMenuOption_text-jerRi {
  color: var(--accent-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/business-user-item/business-user-item.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".menu_container {\n  min-width: 220px;\n}\n\n.deleteMenuOption_text {\n  color: var(--accent-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu_container": `business-user-item-menu_container-HpE1V`,
	"deleteMenuOption_text": `business-user-item-deleteMenuOption_text-jerRi`
};
export default ___CSS_LOADER_EXPORT___;

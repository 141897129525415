import React, { Fragment, useRef, useState } from 'react';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { Menu } from '@recargas-dominicanas/core/components';
import { CellIcon } from '@recargas-dominicanas/core/components';
import { CellImage } from '@recargas-dominicanas/core/components';
import { Cell } from '@recargas-dominicanas/core/components';
import { MenuOption } from '../menu-option/menu-option.component';
import { TableRow } from '@recargas-dominicanas/core/components';
export function BankAccountItem(props) {
    var bankAccount = props.bankAccount, onClick = props.onClick, onEdit = props.onEdit;
    var menuIconRef = useRef(null);
    var _a = useState(false), openMenu = _a[0], setOpenMenu = _a[1];
    function handleEdit() {
        onEdit === null || onEdit === void 0 ? void 0 : onEdit(bankAccount);
        setOpenMenu(false);
    }
    return (React.createElement(Fragment, null,
        React.createElement(TableRow, { key: bankAccount.id, onClick: onClick },
            React.createElement(CellImage, { src: bankAccount.bank.image }),
            React.createElement(Cell, { weight: 'medium', text: bankAccount.name }),
            React.createElement(Cell, { weight: 'medium', text: bankAccount.accountNumber }),
            React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(bankAccount.balance || 0) }),
            React.createElement(CellIcon, { refIcon: menuIconRef, onClick: function () { return setOpenMenu(true); } })),
        React.createElement(Menu, { open: openMenu, anchor: menuIconRef, onClose: function () { return setOpenMenu(false); } },
            React.createElement(MenuOption, { text: 'Editar', onClick: handleEdit }))));
}

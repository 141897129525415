// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-down-option-option_container-L6nRK {
  padding: 10px 16px;
}

.drop-down-option-option_text-zjffV {
  color: rgba(0, 0, 0, 0.85);
}
`, "",{"version":3,"sources":["webpack://./src/components/drop-down-option/drop-down-option.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".option_container {\n  padding: 10px 16px;\n}\n\n.option_text {\n  color: rgba(0, 0, 0, 0.85);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option_container": `drop-down-option-option_container-L6nRK`,
	"option_text": `drop-down-option-option_text-zjffV`
};
export default ___CSS_LOADER_EXPORT___;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { createDraft, finishDraft } from 'immer';
import { store, useAppSelector } from './store';
var BaseStore = /** @class */ (function () {
    function BaseStore(stateKey, initialState) {
        if (initialState === void 0) { initialState = []; }
        this.stateKey = stateKey;
        this.slice = createSlice({
            name: stateKey,
            initialState: initialState,
            reducers: {
                set: function (state, action) { return action.payload; }
            }
        });
    }
    BaseStore.prototype.reducer = function () {
        return this.slice.reducer;
    };
    BaseStore.prototype.findAll = function (key) {
        var _this = this;
        return useAppSelector(function (state) { return state[_this.stateKey]; }).map(function (item) { return item[key]; });
    };
    BaseStore.prototype.findById = function (id, key) {
        var _this = this;
        return useAppSelector(function (state) {
            var item = state[_this.stateKey].find(function (item) { return item.id === id; });
            return item === null || item === void 0 ? void 0 : item[key];
        });
    };
    BaseStore.prototype.add = function (key, data) {
        var _a;
        var state = createDraft(this.getState());
        state.push((_a = { id: data.id }, _a[key] = data, _a));
        this.dispatch(state);
    };
    BaseStore.prototype.set = function (data) {
        this.dispatch(createDraft(data));
    };
    BaseStore.prototype.update = function (id, itemPropKey, data) {
        var state = this.getStateById(id);
        if (state) {
            state[itemPropKey] = data;
            this.store(state);
        }
    };
    BaseStore.prototype.upsert = function (id, key, data) {
        if (!this.exists(id)) {
            this.add(key, __assign(__assign({}, data), { id: id }));
        }
        else {
            this.update(id, key, data);
        }
    };
    BaseStore.prototype.exists = function (id) {
        return this.getStateById(id) !== undefined;
    };
    BaseStore.prototype.getStateById = function (id) {
        var state = this.getState();
        var item = state.find(function (item) { return item.id === id; });
        return item ? createDraft(item) : undefined;
    };
    BaseStore.prototype.store = function (item) {
        var state = createDraft(this.getState());
        var index = state.findIndex(function (_item) { return _item.id === item.id; });
        state[index] = finishDraft(item);
        this.dispatch(state);
    };
    BaseStore.prototype.getState = function () {
        return store.getState()[this.stateKey];
    };
    BaseStore.prototype.dispatch = function (state) {
        store.dispatch(this.slice.actions.set(finishDraft(state)));
    };
    return BaseStore;
}());
export { BaseStore };

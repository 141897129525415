import React from 'react';
import { formatCurrency, formatDate } from '@recargas-dominicanas/core/utils';
import { TableHeader } from '@recargas-dominicanas/core/components';
import { TableRow } from '@recargas-dominicanas/core/components';
import { Table } from '@recargas-dominicanas/core/components';
import { CellImage } from '@recargas-dominicanas/core/components';
import { Cell } from '@recargas-dominicanas/core/components';
import { style } from './deposits.module.css';
export function Deposits(props) {
    var deposits = props.deposits;
    return (React.createElement("div", { className: style.container },
        React.createElement(Table, { style: style.table },
            React.createElement(TableHeader, { style: style.tableHeader },
                React.createElement("span", null, "Banco"),
                React.createElement("span", null, "Balance"),
                React.createElement("span", null, "Fecha"),
                React.createElement("span", null, "Referencia")),
            React.createElement("tbody", null, deposits === null || deposits === void 0 ? void 0 : deposits.map(function (deposit) { return (React.createElement(TableRow, { key: deposit.id },
                React.createElement(CellImage, { src: deposit.bankAccount.bank.image }),
                React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(deposit.balance.amount) }),
                React.createElement(Cell, { text: formatDate(deposit.date) }),
                React.createElement(Cell, { className: style.reference, text: deposit.reference }))); })))));
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pending-transactions-container-nViUD {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pending-transactions-toolbar-ADSXr {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  margin: 0px 20px;
}

.pending-transactions-toolbarContent-QdGN2 {
  display: flex;
  align-items: center;
  gap: 16px;
}

.pending-transactions-text-yO4SR {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.9);
}

.pending-transactions-statusErrorContainer-a93Cc {
  position: relative;
}

.pending-transactions-errorPopup_container-l_vAx {
  width: 300px;
  padding: 8px;
  font-size: 14px;
  color: var(--accent-color);
  background-color: #feeeee;
}

@keyframes pending-transactions-rotate-dny4X {
  from { transform: rotateZ(0deg) }
  to { transform: rotateZ(360deg) }
}

.pending-transactions-tableContainer-d8fb3 {
  height: 100%;
  padding-left: 10px;
  overflow: hidden;
}

.pending-transactions-table_container-T4hW8 {
  padding: 0px 10px;
}

.pending-transactions-tableHeader_cellContent-bnEz1 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/pending-transactions/pending-transactions.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,4CAA4C;EAC5C,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,OAAO,yBAAyB;EAChC,KAAK,2BAA2B;AAClC;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.toolbar {\n  height: 52px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid var(--border-color);\n  margin: 0px 20px;\n}\n\n.toolbarContent {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.text {\n  font-size: 13px;\n  color: rgba(0, 0, 0, 0.9);\n}\n\n.statusErrorContainer {\n  position: relative;\n}\n\n.errorPopup_container {\n  width: 300px;\n  padding: 8px;\n  font-size: 14px;\n  color: var(--accent-color);\n  background-color: #feeeee;\n}\n\n@keyframes rotate {\n  from { transform: rotateZ(0deg) }\n  to { transform: rotateZ(360deg) }\n}\n\n.tableContainer {\n  height: 100%;\n  padding-left: 10px;\n  overflow: hidden;\n}\n\n.table_container {\n  padding: 0px 10px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pending-transactions-container-nViUD`,
	"toolbar": `pending-transactions-toolbar-ADSXr`,
	"toolbarContent": `pending-transactions-toolbarContent-QdGN2`,
	"text": `pending-transactions-text-yO4SR`,
	"statusErrorContainer": `pending-transactions-statusErrorContainer-a93Cc`,
	"errorPopup_container": `pending-transactions-errorPopup_container-l_vAx`,
	"tableContainer": `pending-transactions-tableContainer-d8fb3`,
	"table_container": `pending-transactions-table_container-T4hW8`,
	"tableHeader_cellContent": `pending-transactions-tableHeader_cellContent-bnEz1`,
	"rotate": `pending-transactions-rotate-dny4X`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { useForm, validators } from '@recargas-dominicanas/core/utils';
import { Modal } from '../../../components/modal/modal.component';
import { mergeStyle } from './bank-add-modal.module.css';
import { TextField } from '@recargas-dominicanas/core/components';
import { UploadImage } from '../../../components/upload-image/upload-image.component';
export function BankAddModal(props) {
    var open = props.open, title = props.title, buttonText = props.buttonText, bank = props.bank, customStyle = props.style, onClose = props.onClose, onAccept = props.onAccept;
    if (!open)
        return null;
    var style = mergeStyle(customStyle);
    var form = useForm({
        id: bank === null || bank === void 0 ? void 0 : bank.id,
        name: (bank === null || bank === void 0 ? void 0 : bank.name) || '',
        image: undefined
    });
    function handleAccept() {
        if (form.isValid()) {
            onAccept === null || onAccept === void 0 ? void 0 : onAccept(form.value);
            handleClose();
        }
    }
    function handleClose() {
        form.clear();
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    return (React.createElement(Modal, { open: open, title: title, style: style.modal, secondaryButton: buttonText, onSecondaryClick: handleAccept, onClose: handleClose },
        React.createElement(UploadImage, { required: true, image: bank === null || bank === void 0 ? void 0 : bank.image, field: form.fields.image }),
        React.createElement("div", { className: style.rightPane },
            React.createElement(TextField, { style: style.textField, placeholder: 'Nombre', formField: form.fields.name, validators: [validators.required] }))));
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pending-transaction-item-description-MBBv7 {
  width: 100%;
  min-width: 200px;
  padding: 8px 26px;
  white-space: normal;
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/pending-transactions/pending-transaction-item/pending-transaction-item.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".description {\n  width: 100%;\n  min-width: 200px;\n  padding: 8px 26px;\n  white-space: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `pending-transaction-item-description-MBBv7`
};
export default ___CSS_LOADER_EXPORT___;

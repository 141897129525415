// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-transfer-modal-modal_card-iz8F6 {
  width: 340px;
  height: 672px;
}

.register-transfer-modal-modal_content-ch4OQ {
  overflow: scroll;
  padding: 0px 10px 4px 20px;
}

.register-transfer-modal-textField_container-zvBnD, .register-transfer-modal-dateTime_container-WPfGD, .register-transfer-modal-taxesTextField_container-YZwD7 {
  width: 100%;
  margin-top: 24px;
}

.register-transfer-modal-textField_input-Sshe9, .register-transfer-modal-taxesTextField_input-RrvKS {
  height: 40px;
  font-size: 15px;
}

.register-transfer-modal-textField_container-zvBnD:first-child {
  margin-top: 20px;
}

.register-transfer-modal-taxesField-oSWSI {
  position: relative;
}

.register-transfer-modal-taxesTextField_input-RrvKS {
  padding-right: 44px;
}

.register-transfer-modal-toggle_container-AMZxO {
  position: absolute;
  bottom: 20px;
  right: 8px;
  transform: translateY(50%);
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/register-transfer-modal/register-transfer-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,0BAA0B;AAC5B","sourcesContent":[".modal_card {\n  width: 340px;\n  height: 672px;\n}\n\n.modal_content {\n  overflow: scroll;\n  padding: 0px 10px 4px 20px;\n}\n\n.textField_container, .dateTime_container, .taxesTextField_container {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.textField_input, .taxesTextField_input {\n  height: 40px;\n  font-size: 15px;\n}\n\n.textField_container:first-child {\n  margin-top: 20px;\n}\n\n.taxesField {\n  position: relative;\n}\n\n.taxesTextField_input {\n  padding-right: 44px;\n}\n\n.toggle_container {\n  position: absolute;\n  bottom: 20px;\n  right: 8px;\n  transform: translateY(50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `register-transfer-modal-modal_card-iz8F6`,
	"modal_content": `register-transfer-modal-modal_content-ch4OQ`,
	"textField_container": `register-transfer-modal-textField_container-zvBnD`,
	"dateTime_container": `register-transfer-modal-dateTime_container-WPfGD`,
	"taxesTextField_container": `register-transfer-modal-taxesTextField_container-YZwD7`,
	"textField_input": `register-transfer-modal-textField_input-Sshe9`,
	"taxesTextField_input": `register-transfer-modal-taxesTextField_input-RrvKS`,
	"taxesField": `register-transfer-modal-taxesField-oSWSI`,
	"toggle_container": `register-transfer-modal-toggle_container-AMZxO`
};
export default ___CSS_LOADER_EXPORT___;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useForm, validators } from '@recargas-dominicanas/core/utils';
import { Text, TextField } from '@recargas-dominicanas/core/components';
import { Modal } from '../modal/modal.component';
import { style } from './edit-customer-modal.module.css';
export function EditCustomerModal(props) {
    var _a = props.open, open = _a === void 0 ? false : _a, title = props.title, error = props.error, business = props.business, onSave = props.onSave, onClose = props.onClose;
    var customerForm = useForm({
        name: (business === null || business === void 0 ? void 0 : business.customer.name) || '',
        docNumber: (business === null || business === void 0 ? void 0 : business.customer.docNumber) || '',
        phone: (business === null || business === void 0 ? void 0 : business.customer.phone) || '',
        email: (business === null || business === void 0 ? void 0 : business.customer.email) || ''
    });
    var businessForm = useForm({
        name: (business === null || business === void 0 ? void 0 : business.name) || '',
        rnc: (business === null || business === void 0 ? void 0 : business.rnc) || '',
        phone: (business === null || business === void 0 ? void 0 : business.phone) || '',
        email: (business === null || business === void 0 ? void 0 : business.email) || '',
        city: (business === null || business === void 0 ? void 0 : business.city) || '',
        address: (business === null || business === void 0 ? void 0 : business.address) || '',
        percent: (business === null || business === void 0 ? void 0 : business.percent) || ''
    });
    function handleClose() {
        customerForm.clear();
        businessForm.clear();
        onClose();
    }
    function handleSave() {
        return __awaiter(this, void 0, void 0, function () {
            var customerIsValid, businessIsValid;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        customerIsValid = customerForm.isValid();
                        businessIsValid = businessForm.isValid();
                        if (!customerIsValid || !businessIsValid)
                            return [2 /*return*/];
                        return [4 /*yield*/, onSave(customerForm.value, businessForm.value)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(Modal, { open: open, icon: 'person_add', title: title, primaryButton: 'Guardar', error: error, style: style.modal, onPrimaryClick: handleSave, onClose: handleClose, onSecondaryClick: handleClose },
        React.createElement(Text, { className: style.customerTitle, text: 'Datos del cliente' }),
        React.createElement("div", { className: style.fieldsContent },
            React.createElement(TextField, { style: style.textField, placeholder: 'Nombre', formField: customerForm.fields.name, validators: [validators.required] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'C\u00E9dula', formField: customerForm.fields.docNumber, validators: [
                    validators.required,
                    validators.length(11, 'Cédula invalida')
                ] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Tel\u00E9fono', formField: customerForm.fields.phone, validators: [
                    validators.required,
                    validators.phone
                ] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Correo electr\u00F3nico', formField: customerForm.fields.email, validators: [
                    validators.email
                ] })),
        React.createElement(Text, { className: style.businessTitle, text: 'Datos del Negocio' }),
        React.createElement("div", { className: style.fieldsContent },
            React.createElement(TextField, { style: style.textField, placeholder: 'Nombre', formField: businessForm.fields.name, validators: [validators.required] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'RNC', formField: businessForm.fields.rnc }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Tel\u00E9fono', formField: businessForm.fields.phone, validators: [
                    validators.required,
                    validators.phone
                ] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Correo electr\u00F3nico', formField: businessForm.fields.email, validators: [validators.email] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Ciudad', formField: businessForm.fields.city, validators: [validators.required] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Direcci\u00F3n', formField: businessForm.fields.address, validators: [validators.required] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Porcentaje', formField: businessForm.fields.percent, validators: [validators.required], type: 'number' }))));
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from 'react';
import { ProviderStore } from '../../../store/providerStore';
import { formatCurrency, formatDate } from '@recargas-dominicanas/core/utils';
import { style } from './transactions.module.css';
import { Cell, CellImage, Pagination, Table, TableHeader, TableRow, Text } from '@recargas-dominicanas/core/components';
export function Transactions(props) {
    var page = ProviderStore.getTransactionsPage(props.id);
    useEffect(function () {
        ProviderStore.fetchTransactions(props.id, 1, 100);
    }, [props.id]);
    function handleFetchTransactions(page, size) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                ProviderStore.fetchTransactions(props.id, page, size);
                return [2 /*return*/];
            });
        });
    }
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.tableContainer },
            React.createElement(Table, { style: style.table },
                React.createElement(TableHeader, { style: style.tableHeader },
                    React.createElement(Text, { text: 'Comp.' }),
                    React.createElement(Text, { text: 'Tel\u00E9fono' }),
                    React.createElement(Text, { text: 'Monto' }),
                    React.createElement(Text, { text: 'Tipo' }),
                    React.createElement(Text, { text: 'Fecha' }),
                    React.createElement(Text, { text: 'Negocio' }),
                    React.createElement(Text, { text: 'Estado' })),
                React.createElement("tbody", null, page === null || page === void 0 ? void 0 : page.data.map(function (transaction) {
                    var _a;
                    return (React.createElement(TableRow, { key: transaction.id, style: style.tableRow },
                        React.createElement(CellImage, { src: transaction.product.image }),
                        (transaction.product.type === 'Recarga' || transaction.product.type === 'Paquetico') &&
                            React.createElement(Cell, { className: style.phone, text: transaction.phone }),
                        transaction.product.type === 'Pin' &&
                            React.createElement(Cell, { className: style.phone, text: '* * * * * * * *' }),
                        transaction.product.type === 'Factura' &&
                            React.createElement(Cell, { className: style.phone, text: (_a = transaction.contract) === null || _a === void 0 ? void 0 : _a.nic }),
                        React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(transaction.amount) }),
                        React.createElement(Cell, { text: transaction.product.type }),
                        React.createElement(Cell, { text: formatDate(transaction.date) }),
                        React.createElement(Cell, { text: transaction.business.name }),
                        transaction.cancelled
                            ? React.createElement(Cell, { color: 'red', weight: 'medium', text: 'Cancelada' })
                            : React.createElement(Cell, { color: 'darkgray', text: 'Realizada' })));
                })))),
        page &&
            React.createElement(Pagination, { title: 'Transacciones', index: page.index, pages: page.pages, count: page.count, size: page.size, onChange: handleFetchTransactions })));
}

import React, { useState } from 'react';
import { Case, Switch, Tab, TabBar } from '@recargas-dominicanas/core/components';
import { PageContainer } from '@recargas-dominicanas/core/components';
import { Topups } from './topups/topups.component';
import { style } from './products.module.css';
import { DataPlans } from './data-plans/data-plans.component';
import { Pins } from './pins/pins.component';
import { Invoices } from './invoices/invoices.component';
export function Products() {
    var _a = useState('topups'), selectedTab = _a[0], setSelectedTab = _a[1];
    function handleTabClick(tab) {
        setSelectedTab(tab.tag);
    }
    return (React.createElement(PageContainer, { className: style.container },
        React.createElement(TabBar, { style: style.tabBar, onTabClick: handleTabClick },
            React.createElement(Tab, { tag: 'topups', title: 'Recargas' }),
            React.createElement(Tab, { tag: 'data-plans', title: 'Planes de datos' }),
            React.createElement(Tab, { tag: 'pins', title: 'Pines' }),
            React.createElement(Tab, { tag: 'invoices', title: 'Pago de facturas' })),
        React.createElement(Switch, { caseId: selectedTab },
            React.createElement(Case, { caseId: 'topups' },
                React.createElement(Topups, null)),
            React.createElement(Case, { caseId: 'data-plans' },
                React.createElement(DataPlans, null)),
            React.createElement(Case, { caseId: 'pins' },
                React.createElement(Pins, null)),
            React.createElement(Case, { caseId: 'invoices' },
                React.createElement(Invoices, null)))));
}

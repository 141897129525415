import React from 'react';
import { formatCurrency, formatDate } from '@recargas-dominicanas/core/utils';
import { TableHeader } from '@recargas-dominicanas/core/components';
import { Table } from '@recargas-dominicanas/core/components';
import { style } from './sales-reports.module.css';
import { Cell } from '@recargas-dominicanas/core/components';
import { TableRow } from '@recargas-dominicanas/core/components';
export function SalesReports(props) {
    var salesReports = props.salesReports;
    if (!salesReports)
        return null;
    return (React.createElement("div", { className: style.container },
        React.createElement(Table, { style: style.table },
            React.createElement(TableHeader, { style: style.tableHeader },
                React.createElement("span", null, "Fecha"),
                React.createElement("span", null, "Balance"),
                React.createElement("span", null, "Venta"),
                React.createElement("span", null, "Beneficio"),
                React.createElement("span", null)),
            React.createElement("tbody", null, salesReports === null || salesReports === void 0 ? void 0 : salesReports.map(function (salesReport) { return (React.createElement(TableRow, { key: salesReport.id },
                React.createElement(Cell, { text: formatDate(salesReport.date) }),
                React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(salesReport.balance) }),
                React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(salesReport.sales) }),
                React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(salesReport.profit) }),
                React.createElement(Cell, { text: '' }))); })))));
}

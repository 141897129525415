var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { BankAccountApi } from '@recargas-dominicanas/core/api';
import { DateTime, TextField } from '@recargas-dominicanas/core/components';
import { useForm, validators } from '@recargas-dominicanas/core/utils';
import { Modal } from '../../../components/modal/modal.component';
import { BankAccountStore } from '../../../store/bankAccountStore';
import { BusinessSelect } from '../../../components/business-select/business-select.component';
import { style } from './register-deposit-modal.module.css';
export function RegisterDepositModal(props) {
    var open = props.open, bankAccountId = props.bankAccountId, onClose = props.onClose;
    var _a = useState(), error = _a[0], setError = _a[1];
    var _b = useState(false), disabledButton = _b[0], setDisabledButton = _b[1];
    var form = useForm({
        date: '',
        balance: '',
        businessId: '',
        reference: ''
    });
    function handleCancel() {
        form.clear();
        onClose();
        setError(undefined);
    }
    function handleSave() {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!form.isValid())
                            return [2 /*return*/];
                        setDisabledButton(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, BankAccountApi.registerDeposit(bankAccountId, {
                                balance: parseInt(form.value.balance),
                                date: new Date(form.value.date),
                                businessId: form.value.businessId ? parseInt(form.value.businessId) : undefined,
                                reference: form.value.reference
                            })];
                    case 2:
                        _a.sent();
                        BankAccountStore.fetchById(bankAccountId);
                        BankAccountStore.fetchDeposits(bankAccountId, 1, 100);
                        handleCancel();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        setError('Ocurrio un error al registrar el deposito');
                        return [3 /*break*/, 4];
                    case 4:
                        setDisabledButton(false);
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(Modal, { open: open, style: style.modal, title: 'Registrar deposito', error: error, secondaryButton: 'Registrar', disabledSecondaryButton: disabledButton, onClose: handleCancel, onSecondaryClick: handleSave },
        React.createElement(TextField, { style: style.textField, label: 'Balance', type: 'number', formField: form.fields.balance, validators: [validators.required] }),
        React.createElement(DateTime, { style: style.dateTime, label: 'Fecha', placeholder: 'Seleccionar fecha', type: 'datetime-local', formField: form.fields.date, required: true }),
        React.createElement(TextField, { style: style.textField, label: 'Descripci\u00F3n', formField: form.fields.reference }),
        React.createElement(BusinessSelect, { className: style.businessSelect, label: 'Asignar a', icon: 'storefront', placeholder: 'Seleccionar negocio', field: form.fields.businessId })));
}

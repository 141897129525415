// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-accounts-container-zEtjj {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 10px;
}

.bank-accounts-table_container-yg6F4 {
  padding: 0px 10px;
}

.bank-accounts-tableHeader_cellContent-_ua4X {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/pages/provider/bank-accounts/bank-accounts.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  padding-left: 10px;\n}\n\n.table_container {\n  padding: 0px 10px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `bank-accounts-container-zEtjj`,
	"table_container": `bank-accounts-table_container-yg6F4`,
	"tableHeader_cellContent": `bank-accounts-tableHeader_cellContent-_ua4X`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-bank-account-modal-modal_card-JPsFE {
  width: 324px;
  height: initial;
  min-height: 372px;
}

.edit-bank-account-modal-modal_content-S2raj {
  padding: 0px 20px;
}

.edit-bank-account-modal-textField_container-V1h6j:first-child {
  margin-top: 20px;
}

.edit-bank-account-modal-textField_container-V1h6j {
  width: 100%;
  margin: 24px 0px 8px;
}

.edit-bank-account-modal-textField_input-nQyx3 {
  height: 40px;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank/edit-bank-account-modal/edit-bank-account-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".modal_card {\n  width: 324px;\n  height: initial;\n  min-height: 372px;\n}\n\n.modal_content {\n  padding: 0px 20px;\n}\n\n.textField_container:first-child {\n  margin-top: 20px;\n}\n\n.textField_container {\n  width: 100%;\n  margin: 24px 0px 8px;\n}\n\n.textField_input {\n  height: 40px;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `edit-bank-account-modal-modal_card-JPsFE`,
	"modal_content": `edit-bank-account-modal-modal_content-S2raj`,
	"textField_container": `edit-bank-account-modal-textField_container-V1h6j`,
	"textField_input": `edit-bank-account-modal-textField_input-nQyx3`
};
export default ___CSS_LOADER_EXPORT___;

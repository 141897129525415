export function formatChartValue(value) {
    if (value >= 1000000)
        return "".concat(round(value / 1000000, 1), " M");
    if (value >= 1000)
        return "".concat(round(value / 1000, 1), " mil");
    return value.toString();
}
// ex: round(12.2569456, 3) -> 12.256
function round(value, places) {
    var d = Math.pow(10, places);
    return Math.round((value + Number.EPSILON) * d) / d;
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical-bar-chart-container-GuCa8 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.vertical-bar-chart-title-QvFcv {
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 500;
}

.vertical-bar-chart-content-kmhbL {
  flex-grow: 1;
}

.vertical-bar-chart-chart-msege {
  width: 100%;
  display: block;
  flex-grow: 1;
}

.vertical-bar-chart-line-Mx84t {
  stroke: rgba(0, 0, 0, 8%);
}

.vertical-bar-chart-tickText-jeL28 {
  fill: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  text-anchor: end;
}

.vertical-bar-chart-barContainer-Wpy_R {
  cursor: pointer;
}

.vertical-bar-chart-barContainer-Wpy_R:hover > .vertical-bar-chart-value-E58pO {
  visibility: visible;
}

.vertical-bar-chart-barTitle-ez5CE {
  fill: rgba(0, 0, 0, 0.75);
  font-size: 12px;
  text-anchor: middle;
}

.vertical-bar-chart-value-E58pO {
  visibility: hidden;
  fill: rgba(20%, 20%, 20%);
  font-size: 12px;
}

.vertical-bar-chart-rect-LbKyv {
  fill: #27AE60;
}
`, "",{"version":3,"sources":["webpack://./src/components/vertical-bar-chart/vertical-bar-chart.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,cAAc;EACd,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.title {\n  flex-shrink: 0;\n  color: rgba(0, 0, 0, 0.8);\n  font-size: 15px;\n  font-weight: 500;\n}\n\n.content {\n  flex-grow: 1;\n}\n\n.chart {\n  width: 100%;\n  display: block;\n  flex-grow: 1;\n}\n\n.line {\n  stroke: rgba(0, 0, 0, 8%);\n}\n\n.tickText {\n  fill: rgba(0, 0, 0, 0.5);\n  font-size: 12px;\n  text-anchor: end;\n}\n\n.barContainer {\n  cursor: pointer;\n}\n\n.barContainer:hover > .value {\n  visibility: visible;\n}\n\n.barTitle {\n  fill: rgba(0, 0, 0, 0.75);\n  font-size: 12px;\n  text-anchor: middle;\n}\n\n.value {\n  visibility: hidden;\n  fill: rgba(20%, 20%, 20%);\n  font-size: 12px;\n}\n\n.rect {\n  fill: #27AE60;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `vertical-bar-chart-container-GuCa8`,
	"title": `vertical-bar-chart-title-QvFcv`,
	"content": `vertical-bar-chart-content-kmhbL`,
	"chart": `vertical-bar-chart-chart-msege`,
	"line": `vertical-bar-chart-line-Mx84t`,
	"tickText": `vertical-bar-chart-tickText-jeL28`,
	"barContainer": `vertical-bar-chart-barContainer-Wpy_R`,
	"value": `vertical-bar-chart-value-E58pO`,
	"barTitle": `vertical-bar-chart-barTitle-ez5CE`,
	"rect": `vertical-bar-chart-rect-LbKyv`
};
export default ___CSS_LOADER_EXPORT___;

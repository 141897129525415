// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sales-reports-container-FrpOK {
  height: 100%;
  padding: 20px 0px 0px 20px;
  overflow: hidden;
}

.sales-reports-table_container-H7FbJ {
  padding-right: 10px;
  overflow: scroll;
}

.sales-reports-tableHeader_tab-VLuGZ {
  border-top: 1px solid var(--border-color);
}

.sales-reports-table_container-H7FbJ {
  height: 100%;
  overflow: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/components/sales-reports/sales-reports.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".container {\n  height: 100%;\n  padding: 20px 0px 0px 20px;\n  overflow: hidden;\n}\n\n.table_container {\n  padding-right: 10px;\n  overflow: scroll;\n}\n\n.tableHeader_tab {\n  border-top: 1px solid var(--border-color);\n}\n\n.table_container {\n  height: 100%;\n  overflow: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sales-reports-container-FrpOK`,
	"table_container": `sales-reports-table_container-H7FbJ`,
	"tableHeader_tab": `sales-reports-tableHeader_tab-VLuGZ`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-add-modal-modal_card-iX4Cb {
  width: auto;
  height: auto;
}

.bank-add-modal-modal_content-UQtg4 {
  display: flex;
  padding: 24px;
}

.bank-add-modal-rightPane-_lQsR {
  width: 220px;
  margin-left: 52px;
}

.bank-add-modal-textField_container-AJlsv {
  width: 100%;
}

.bank-add-modal-textField_input-dWW5K, .bank-add-modal-textField_input-dWW5K::placeholder {
  height: 40px;
  font-size: 15px;
}

.bank-add-modal-textField_container-AJlsv:not(:first-child) {
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/banks/bank-add-modal/bank-add-modal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".modal_card {\n  width: auto;\n  height: auto;\n}\n\n.modal_content {\n  display: flex;\n  padding: 24px;\n}\n\n.rightPane {\n  width: 220px;\n  margin-left: 52px;\n}\n\n.textField_container {\n  width: 100%;\n}\n\n.textField_input, .textField_input::placeholder {\n  height: 40px;\n  font-size: 15px;\n}\n\n.textField_container:not(:first-child) {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `bank-add-modal-modal_card-iX4Cb`,
	"modal_content": `bank-add-modal-modal_content-UQtg4`,
	"rightPane": `bank-add-modal-rightPane-_lQsR`,
	"textField_container": `bank-add-modal-textField_container-AJlsv`,
	"textField_input": `bank-add-modal-textField_input-dWW5K`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-account-update-modal-modal_card-EfiIr {
  width: 348px;
  height: 380px;
}

.bank-account-update-modal-modal_content-_A4fy {
  padding: 0px 20px;
}

.bank-account-update-modal-textField_container-RRdQR {
  width: 100%;
  margin-top: 28px;
}

.bank-account-update-modal-textField_label-J4DKN {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.bank-account-update-modal-textField_input-ZetYv {
  height: 40px;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/bank-account-update-modal/bank-account-update-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".modal_card {\n  width: 348px;\n  height: 380px;\n}\n\n.modal_content {\n  padding: 0px 20px;\n}\n\n.textField_container {\n  width: 100%;\n  margin-top: 28px;\n}\n\n.textField_label {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.85);\n}\n\n.textField_input {\n  height: 40px;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `bank-account-update-modal-modal_card-EfiIr`,
	"modal_content": `bank-account-update-modal-modal_content-_A4fy`,
	"textField_container": `bank-account-update-modal-textField_container-RRdQR`,
	"textField_label": `bank-account-update-modal-textField_label-J4DKN`,
	"textField_input": `bank-account-update-modal-textField_input-ZetYv`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-release-item-releaseNotes-YSsMf {
  max-width: 360px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/pages/app-releases/app-release-item/app-release-item.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".releaseNotes {\n  max-width: 360px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"releaseNotes": `app-release-item-releaseNotes-YSsMf`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-container-M6x6C {
  overflow-x: auto;
}

.customer-content-WkQQf {
  min-width: 932px;
  display: flex;
}

.customer-toolbarTitle_container-hN2lr {
  width: 360px;
  flex-shrink: 0;
  margin-right: 40px;
}

.customer-toolbarRight-KrL39 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.customer-customerCard_container-na2ER {
  width: 360px;
  margin-right: 40px;
  flex-shrink: 0;
}

.customer-salesReport_item-qsmTu:first-child {
  margin-left: 0px;
}

.customer-options-a8_Sx {
  display: flex;
  align-items: center;
}

.customer-option-OgWAf {
  --size: 24px;
  margin-left: 28px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/customer/customer.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".container {\n  overflow-x: auto;\n}\n\n.content {\n  min-width: 932px;\n  display: flex;\n}\n\n.toolbarTitle_container {\n  width: 360px;\n  flex-shrink: 0;\n  margin-right: 40px;\n}\n\n.toolbarRight {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex-grow: 1;\n}\n\n.customerCard_container {\n  width: 360px;\n  margin-right: 40px;\n  flex-shrink: 0;\n}\n\n.salesReport_item:first-child {\n  margin-left: 0px;\n}\n\n.options {\n  display: flex;\n  align-items: center;\n}\n\n.option {\n  --size: 24px;\n  margin-left: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `customer-container-M6x6C`,
	"content": `customer-content-WkQQf`,
	"toolbarTitle_container": `customer-toolbarTitle_container-hN2lr`,
	"toolbarRight": `customer-toolbarRight-KrL39`,
	"customerCard_container": `customer-customerCard_container-na2ER`,
	"salesReport_item": `customer-salesReport_item-qsmTu`,
	"options": `customer-options-a8_Sx`,
	"option": `customer-option-OgWAf`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useRef } from 'react';
import { useRect } from '../../hooks/useRect';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { style } from './sales-by-product-item.module.css';
export function SalesByProductItem(props) {
    var className = props.className, product = props.product, height = props.height, x = props.x, y = props.y;
    var barRef = useRef(null);
    var textRef = useRef(null);
    var barRect = useRect({ height: height });
    var textRect = useRect({ margin: { left: 4, right: 4 } });
    useEffect(function () {
        var rect = barRef.current.getBoundingClientRect();
        var text = textRef.current.getBoundingClientRect();
        barRect.setWidth(rect.width);
        textRect.setWidth(text.width);
    }, [product.total, x]);
    function calcTextX(x) {
        return (textRect.margin.left + textRect.width + textRect.margin.right) >= barRect.width
            ? x + textRect.margin.left
            : x - textRect.width - textRect.margin.right;
    }
    return (React.createElement("g", null,
        React.createElement("rect", { className: className, ref: barRef, x: x(0), y: y(product.id) - (barRect.height / 2), width: x(product.total) - x(0), height: barRect.height }),
        React.createElement("text", { className: style.text, ref: textRef, x: calcTextX(x(product.total)), y: y(product.id), alignmentBaseline: 'central' }, formatCurrency(product.total))));
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-pending-withdrawal-modal-modal_card-CpvEC {
  width: 344px;
  height: auto;
  max-height: 640px;  
}

.register-pending-withdrawal-modal-modal_content-dXUds {
  padding: 0px 20px;
}

.register-pending-withdrawal-modal-detailItem_container-ObFh_ {
  width: auto;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 16px;
  padding: 18px 0px;
  margin: 0px 1px;
}

.register-pending-withdrawal-modal-detailItem_title-Y4wdx {
  font-size: 15px;
  font-weight: normal;
}

.register-pending-withdrawal-modal-detailItem_text-DUoq4 {
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.75);
}

.register-pending-withdrawal-modal-detailItem_green-gVPAG {
  color: var(--money-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/pending-transactions/register-pending-withdrawal-modal/register-pending-withdrawal-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".modal_card {\n  width: 344px;\n  height: auto;\n  max-height: 640px;  \n}\n\n.modal_content {\n  padding: 0px 20px;\n}\n\n.detailItem_container {\n  width: auto;\n  flex-wrap: wrap;\n  row-gap: 12px;\n  column-gap: 16px;\n  padding: 18px 0px;\n  margin: 0px 1px;\n}\n\n.detailItem_title {\n  font-size: 15px;\n  font-weight: normal;\n}\n\n.detailItem_text {\n  font-weight: 500;\n  font-size: 15px;\n  color: rgba(0, 0, 0, 0.75);\n}\n\n.detailItem_green {\n  color: var(--money-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `register-pending-withdrawal-modal-modal_card-CpvEC`,
	"modal_content": `register-pending-withdrawal-modal-modal_content-dXUds`,
	"detailItem_container": `register-pending-withdrawal-modal-detailItem_container-ObFh_`,
	"detailItem_title": `register-pending-withdrawal-modal-detailItem_title-Y4wdx`,
	"detailItem_text": `register-pending-withdrawal-modal-detailItem_text-DUoq4`,
	"detailItem_green": `register-pending-withdrawal-modal-detailItem_green-gVPAG`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-pending-deposit-modal-modal_card-rmbul {
  width: 344px;
  height: auto;
  max-height: 640px;  
}

.link-pending-deposit-modal-modal_content-zUVNk {
  padding: 0px 20px;
}

.link-pending-deposit-modal-selectTitle-Bxc08, .link-pending-deposit-modal-transactionTitle-kEi9R {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.75);
}

.link-pending-deposit-modal-selectTitle-Bxc08 {
  margin: 20px 0px;
}

.link-pending-deposit-modal-transactionTitle-kEi9R {
  margin: 24px 0px 10px;
}

.link-pending-deposit-modal-detailItem_container-vLX32 {
  width: auto;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 16px;
  padding: 18px 0px;
  margin: 0px 1px;
}

.link-pending-deposit-modal-detailItem_title-Wp7Pz {
  font-size: 15px;
  font-weight: normal;
}

.link-pending-deposit-modal-detailItem_text-g9IR5 {
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.75);
}

.link-pending-deposit-modal-detailItem_green-zbugM {
  color: var(--money-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/pending-transactions/link-pending-deposit-modal/link-pending-deposit-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".modal_card {\n  width: 344px;\n  height: auto;\n  max-height: 640px;  \n}\n\n.modal_content {\n  padding: 0px 20px;\n}\n\n.selectTitle, .transactionTitle {\n  display: block;\n  font-size: 15px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 0.75);\n}\n\n.selectTitle {\n  margin: 20px 0px;\n}\n\n.transactionTitle {\n  margin: 24px 0px 10px;\n}\n\n.detailItem_container {\n  width: auto;\n  flex-wrap: wrap;\n  row-gap: 12px;\n  column-gap: 16px;\n  padding: 18px 0px;\n  margin: 0px 1px;\n}\n\n.detailItem_title {\n  font-size: 15px;\n  font-weight: normal;\n}\n\n.detailItem_text {\n  font-weight: 500;\n  font-size: 15px;\n  color: rgba(0, 0, 0, 0.75);\n}\n\n.detailItem_green {\n  color: var(--money-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `link-pending-deposit-modal-modal_card-rmbul`,
	"modal_content": `link-pending-deposit-modal-modal_content-zUVNk`,
	"selectTitle": `link-pending-deposit-modal-selectTitle-Bxc08`,
	"transactionTitle": `link-pending-deposit-modal-transactionTitle-kEi9R`,
	"detailItem_container": `link-pending-deposit-modal-detailItem_container-vLX32`,
	"detailItem_title": `link-pending-deposit-modal-detailItem_title-Wp7Pz`,
	"detailItem_text": `link-pending-deposit-modal-detailItem_text-g9IR5`,
	"detailItem_green": `link-pending-deposit-modal-detailItem_green-zbugM`
};
export default ___CSS_LOADER_EXPORT___;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Fragment, useRef, useState } from 'react';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { ProductsApi } from '@recargas-dominicanas/core/api';
import { Menu } from '@recargas-dominicanas/core/components';
import { Cell } from '@recargas-dominicanas/core/components';
import { TableRow } from '@recargas-dominicanas/core/components';
import { CellIcon } from '@recargas-dominicanas/core/components';
import { CellImage } from '@recargas-dominicanas/core/components';
import { ProductStore } from '../../../../store/productStore';
import { MenuOption } from '../../../../components/menu-option/menu-option.component';
import { EditProductModal } from '../edit-product-modal/edit-product-modal.component';
import { mergeStyle } from './topup-item.module.css';
export function TopupItem(props) {
    var product = props.product, customStyle = props.style;
    var style = mergeStyle(customStyle);
    var iconRef = useRef(null);
    var _a = useState(false), openMenu = _a[0], setOpenMenu = _a[1];
    var _b = useState(false), openEditModal = _b[0], setOpenEditModal = _b[1];
    function handleEdit(product) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ProductsApi.update(product)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, ProductStore.fetchAll()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleEnableDisable() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ProductsApi.enable(product.id, !product.enabled)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, ProductStore.fetchAll()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(Fragment, null,
        React.createElement(TableRow, null,
            React.createElement(CellImage, { src: product.image }),
            React.createElement(Cell, { weight: 'medium', text: product.name }),
            React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(product.min) }),
            React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(product.max) }),
            product.enabled
                ? React.createElement(Cell, { weight: 'medium', color: 'green', text: 'Activado' })
                : React.createElement(Cell, { weight: 'medium', color: 'gray', text: 'Desactivado' }),
            React.createElement(CellIcon, { refIcon: iconRef, onClick: function () { return setOpenMenu(true); } })),
        React.createElement(Menu, { open: openMenu, anchor: iconRef, style: style.menu, onClose: function () { return setOpenMenu(false); } },
            React.createElement(MenuOption, { text: 'Editar', onClick: function () { return setOpenEditModal(true); } }),
            React.createElement(MenuOption, { text: product.enabled ? 'Desactivar' : 'Activar', onClick: handleEnableDisable }),
            React.createElement(MenuOption, { style: style.deleteOption, text: 'Archivar' })),
        React.createElement(EditProductModal, { open: openEditModal, title: 'Editar Recarga', buttonText: 'Guardar', product: product, onAccept: handleEdit, onClose: function () { return setOpenEditModal(false); } })));
}

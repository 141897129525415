// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-invoice-modal-modal_card-JTchq {
  width: auto;
  height: auto;
}

.edit-invoice-modal-modal_content-GK4wd {
  display: flex;
  padding: 24px;
}

.edit-invoice-modal-rightPane-fuSrY {
  width: 200px;
  margin-left: 52px;
}

.edit-invoice-modal-textField_container-eQPoq {
  width: 100%;
}

.edit-invoice-modal-textField_input-TvTzT, .edit-invoice-modal-textField_input-TvTzT::placeholder {
  height: 40px;
  font-size: 15px;
}

.edit-invoice-modal-textField_container-eQPoq:not(:first-child) {
  margin-top: 24px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/products/components/edit-invoice-modal/edit-invoice-modal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".modal_card {\n  width: auto;\n  height: auto;\n}\n\n.modal_content {\n  display: flex;\n  padding: 24px;\n}\n\n.rightPane {\n  width: 200px;\n  margin-left: 52px;\n}\n\n.textField_container {\n  width: 100%;\n}\n\n.textField_input, .textField_input::placeholder {\n  height: 40px;\n  font-size: 15px;\n}\n\n.textField_container:not(:first-child) {\n  margin-top: 24px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `edit-invoice-modal-modal_card-JTchq`,
	"modal_content": `edit-invoice-modal-modal_content-GK4wd`,
	"rightPane": `edit-invoice-modal-rightPane-fuSrY`,
	"textField_container": `edit-invoice-modal-textField_container-eQPoq`,
	"textField_input": `edit-invoice-modal-textField_input-TvTzT`
};
export default ___CSS_LOADER_EXPORT___;

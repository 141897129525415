// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-balance-purchase-modal-modal_card-AOH9y {
  width: 340px;
  height: 640px;
}

.register-balance-purchase-modal-modal_content-t5pgI {
  overflow: scroll;
  padding: 0px 10px 4px 20px;
}

.register-balance-purchase-modal-textField_container-sTsbp, 
.register-balance-purchase-modal-dateTime_container-oCAea, 
.register-balance-purchase-modal-taxesTextField_container-HtxK0,
.register-balance-purchase-modal-select_container-R9j0O {
  width: 100%;
  margin-top: 24px;
}

.register-balance-purchase-modal-textField_input-Ffbvn, .register-balance-purchase-modal-taxesTextField_input-Phnsc {
  height: 40px;
  font-size: 15px;
}

.register-balance-purchase-modal-textField_container-sTsbp:first-child {
  margin-top: 20px;
}

.register-balance-purchase-modal-select_popup-JpohX {
  padding: 8px 0px;
  max-height: 400px;
  overflow: auto;
}

.register-balance-purchase-modal-selectOption_container-Pcesc {
  color: rgba(0, 0, 0, 0.9);
  font-size: 15px;
  padding: 10px 0px 10px 10px;
}

.register-balance-purchase-modal-taxesField-RWwQI {
  position: relative;
}

.register-balance-purchase-modal-taxesTextField_input-Phnsc {
  padding-right: 44px;
}

.register-balance-purchase-modal-toggle_container-PHcbN {
  position: absolute;
  bottom: 20px;
  right: 8px;
  transform: translateY(50%);
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/register-balance-purchase-modal/register-balance-purchase-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;;;;EAIE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,0BAA0B;AAC5B","sourcesContent":[".modal_card {\n  width: 340px;\n  height: 640px;\n}\n\n.modal_content {\n  overflow: scroll;\n  padding: 0px 10px 4px 20px;\n}\n\n.textField_container, \n.dateTime_container, \n.taxesTextField_container,\n.select_container {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.textField_input, .taxesTextField_input {\n  height: 40px;\n  font-size: 15px;\n}\n\n.textField_container:first-child {\n  margin-top: 20px;\n}\n\n.select_popup {\n  padding: 8px 0px;\n  max-height: 400px;\n  overflow: auto;\n}\n\n.selectOption_container {\n  color: rgba(0, 0, 0, 0.9);\n  font-size: 15px;\n  padding: 10px 0px 10px 10px;\n}\n\n.taxesField {\n  position: relative;\n}\n\n.taxesTextField_input {\n  padding-right: 44px;\n}\n\n.toggle_container {\n  position: absolute;\n  bottom: 20px;\n  right: 8px;\n  transform: translateY(50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `register-balance-purchase-modal-modal_card-AOH9y`,
	"modal_content": `register-balance-purchase-modal-modal_content-t5pgI`,
	"textField_container": `register-balance-purchase-modal-textField_container-sTsbp`,
	"dateTime_container": `register-balance-purchase-modal-dateTime_container-oCAea`,
	"taxesTextField_container": `register-balance-purchase-modal-taxesTextField_container-HtxK0`,
	"select_container": `register-balance-purchase-modal-select_container-R9j0O`,
	"textField_input": `register-balance-purchase-modal-textField_input-Ffbvn`,
	"taxesTextField_input": `register-balance-purchase-modal-taxesTextField_input-Phnsc`,
	"select_popup": `register-balance-purchase-modal-select_popup-JpohX`,
	"selectOption_container": `register-balance-purchase-modal-selectOption_container-Pcesc`,
	"taxesField": `register-balance-purchase-modal-taxesField-RWwQI`,
	"toggle_container": `register-balance-purchase-modal-toggle_container-PHcbN`
};
export default ___CSS_LOADER_EXPORT___;

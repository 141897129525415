import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { mergeStyle } from './toggle.module.css';
export function Toggle(props) {
    var _a = props.on, initialOn = _a === void 0 ? false : _a, className = props.className, onChange = props.onChange;
    var style = mergeStyle(className);
    var _b = useState(initialOn), on = _b[0], setOn = _b[1];
    useEffect(function () {
        setOn(initialOn);
    }, [initialOn]);
    function handleClick() {
        onChange === null || onChange === void 0 ? void 0 : onChange(!on);
        setOn(function (state) { return !state; });
    }
    return (React.createElement("div", { className: clsx(style.container, on && style.containerOn), onClick: handleClick },
        React.createElement("div", { className: clsx(style.thumb, on && style.thumbOn) })));
}

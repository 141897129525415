// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-user-container-mCaAD {
  height: 100%;
  padding: 20px 0px 0px 20px;
  overflow: hidden;
}

.customer-user-table_container-gH09J {
  overflow: scroll;
  padding-right: 10px;
}

.customer-user-tableHeader_tab-wmeEH {
  border-top: 1px solid var(--border-color);
}

.customer-user-menu_container-IO3Pp {
  min-width: 220px;
}

.customer-user-modal_card-I5l60 {
  width: 360px;
  min-height: min-content;
  height: auto;
}

.customer-user-modal_content-OycNM {
  margin-top: 16px;
}

.customer-user-modal_label-CwN2y {
  margin: 16px 0px 4px;
}`, "",{"version":3,"sources":["webpack://./src/pages/customer/customer-user/customer-user.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".container {\n  height: 100%;\n  padding: 20px 0px 0px 20px;\n  overflow: hidden;\n}\n\n.table_container {\n  overflow: scroll;\n  padding-right: 10px;\n}\n\n.tableHeader_tab {\n  border-top: 1px solid var(--border-color);\n}\n\n.menu_container {\n  min-width: 220px;\n}\n\n.modal_card {\n  width: 360px;\n  min-height: min-content;\n  height: auto;\n}\n\n.modal_content {\n  margin-top: 16px;\n}\n\n.modal_label {\n  margin: 16px 0px 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `customer-user-container-mCaAD`,
	"table_container": `customer-user-table_container-gH09J`,
	"tableHeader_tab": `customer-user-tableHeader_tab-wmeEH`,
	"menu_container": `customer-user-menu_container-IO3Pp`,
	"modal_card": `customer-user-modal_card-I5l60`,
	"modal_content": `customer-user-modal_content-OycNM`,
	"modal_label": `customer-user-modal_label-CwN2y`
};
export default ___CSS_LOADER_EXPORT___;

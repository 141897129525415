// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-option-container-e1l3N {
  padding: 10px 16px;
}

.menu-option-text-W2HCx {
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/menu-option/menu-option.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  padding: 10px 16px;\n}\n\n.text {\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `menu-option-container-e1l3N`,
	"text": `menu-option-text-W2HCx`
};
export default ___CSS_LOADER_EXPORT___;

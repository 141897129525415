// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-popup-container-MJjVR {
  width: 300px;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  top: 40px;
  left: -24px;
  transform: translateY(-8px);
  transition: all 160ms;
  z-index: 3;
  border-radius: var(--small-border-radius);
  box-shadow: 0px 1px 4px lightgray;
}

.error-popup-open-BWzv4 {
  pointer-events: all;
  opacity: 1;
  transform: translateY(0px);
}

.error-popup-arrow-_CbAG {
  width: 16px;
  height: 16px;
  position: absolute;
  top: -6px;
  left: 44px;
  background-color: #feeeee;
  transform: rotate(45deg);
  box-shadow: 0px 1px 4px lightgray;
  border-radius: 0px 0px 0px 0px;
}

.error-popup-content-xN4Ud {
  width: 100%;
  height: 100%;
  font-size: 14px;
  z-index: 2;
  position: relative;
  color: var(--accent-color);
  overflow-wrap: break-word;
  background-color: #feeeee;
  border-radius: var(--small-border-radius);
  padding: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-account/pending-transactions/error-popup/error-popup.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,UAAU;EACV,kBAAkB;EAClB,oBAAoB;EACpB,SAAS;EACT,WAAW;EACX,2BAA2B;EAC3B,qBAAqB;EACrB,UAAU;EACV,yCAAyC;EACzC,iCAAiC;AACnC;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,yBAAyB;EACzB,wBAAwB;EACxB,iCAAiC;EACjC,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,UAAU;EACV,kBAAkB;EAClB,0BAA0B;EAC1B,yBAAyB;EACzB,yBAAyB;EACzB,yCAAyC;EACzC,YAAY;AACd","sourcesContent":[".container {\n  width: 300px;\n  opacity: 0;\n  position: absolute;\n  pointer-events: none;\n  top: 40px;\n  left: -24px;\n  transform: translateY(-8px);\n  transition: all 160ms;\n  z-index: 3;\n  border-radius: var(--small-border-radius);\n  box-shadow: 0px 1px 4px lightgray;\n}\n\n.open {\n  pointer-events: all;\n  opacity: 1;\n  transform: translateY(0px);\n}\n\n.arrow {\n  width: 16px;\n  height: 16px;\n  position: absolute;\n  top: -6px;\n  left: 44px;\n  background-color: #feeeee;\n  transform: rotate(45deg);\n  box-shadow: 0px 1px 4px lightgray;\n  border-radius: 0px 0px 0px 0px;\n}\n\n.content {\n  width: 100%;\n  height: 100%;\n  font-size: 14px;\n  z-index: 2;\n  position: relative;\n  color: var(--accent-color);\n  overflow-wrap: break-word;\n  background-color: #feeeee;\n  border-radius: var(--small-border-radius);\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `error-popup-container-MJjVR`,
	"open": `error-popup-open-BWzv4`,
	"arrow": `error-popup-arrow-_CbAG`,
	"content": `error-popup-content-xN4Ud`
};
export default ___CSS_LOADER_EXPORT___;

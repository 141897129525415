// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-customer-modal-modal_card-We1JC {
  width: 524px;
  height: 680px;
}

.edit-customer-modal-modal_content-IVv8e {
  overflow: scroll;
  padding: 0px 14px 0px 24px;
}

.edit-customer-modal-customerTitle-UhxKi {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  font-size: 15px;
  margin-top: 26px;
}

.edit-customer-modal-businessTitle-hUD6Z {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  font-size: 15px;
  margin-top: 40px;
}

.edit-customer-modal-fieldsContent-gG8rM {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.edit-customer-modal-textField_container-JWXMy {
  width: 220px;
  margin-top: 32px;
}

.edit-customer-modal-textField_input-wy9Hz {
  height: 40px;
  font-size: 15px;
}

.edit-customer-modal-selectOption_container-ZvXgk {
  padding: 12px 4px 12px 12px;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/edit-customer-modal/edit-customer-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,eAAe;AACjB","sourcesContent":[".modal_card {\n  width: 524px;\n  height: 680px;\n}\n\n.modal_content {\n  overflow: scroll;\n  padding: 0px 14px 0px 24px;\n}\n\n.customerTitle {\n  color: rgba(0, 0, 0, 0.9);\n  font-weight: 500;\n  font-size: 15px;\n  margin-top: 26px;\n}\n\n.businessTitle {\n  color: rgba(0, 0, 0, 0.9);\n  font-weight: 500;\n  font-size: 15px;\n  margin-top: 40px;\n}\n\n.fieldsContent {\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: space-between;\n}\n\n.textField_container {\n  width: 220px;\n  margin-top: 32px;\n}\n\n.textField_input {\n  height: 40px;\n  font-size: 15px;\n}\n\n.selectOption_container {\n  padding: 12px 4px 12px 12px;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `edit-customer-modal-modal_card-We1JC`,
	"modal_content": `edit-customer-modal-modal_content-IVv8e`,
	"customerTitle": `edit-customer-modal-customerTitle-UhxKi`,
	"businessTitle": `edit-customer-modal-businessTitle-hUD6Z`,
	"fieldsContent": `edit-customer-modal-fieldsContent-gG8rM`,
	"textField_container": `edit-customer-modal-textField_container-JWXMy`,
	"textField_input": `edit-customer-modal-textField_input-wy9Hz`,
	"selectOption_container": `edit-customer-modal-selectOption_container-ZvXgk`
};
export default ___CSS_LOADER_EXPORT___;

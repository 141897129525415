// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configuration-container-s7INy {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.configuration-addButton_base-__qTl {
  width: 100px;
  height: 32px;
  flex-shrink: 0;
  margin: 20px 20px 0px;
}

.configuration-configurations-mfXXM {
  flex-grow: 1;
  overflow: auto;
  padding: 0px 20px;
  margin-top: 32px;
}

.configuration-configuration-T4o3g {
  display: flex;
  margin-bottom: 24px;
}

.configuration-textField_input-BFY4u {
  height: 40px;
  font-size: 15px;
}

.configuration-textField_container-xekLG {
  margin-right: 24px;
}

.configuration-icon-bbDiO {
  --size: 28px;
  margin: 6px 0px;
}

.configuration-actionButtons-mnQff {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.configuration-actionButton_base-JY0BD {
  width: 112px;
  margin-left: 32px;
}

.configuration-configItem-Bzl4q {
  border: 1px solid var(--border-color);
  border-radius: var(--small-border-radius);
  padding: 12px;
}

.configuration-configItemName-p1IN6 {
  font-weight: 500;
}

.configuration-configItemValue-xeGDi {
  margin-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/provider/configuration/configuration.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,qCAAqC;EACrC,yCAAyC;EACzC,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.addButton_base {\n  width: 100px;\n  height: 32px;\n  flex-shrink: 0;\n  margin: 20px 20px 0px;\n}\n\n.configurations {\n  flex-grow: 1;\n  overflow: auto;\n  padding: 0px 20px;\n  margin-top: 32px;\n}\n\n.configuration {\n  display: flex;\n  margin-bottom: 24px;\n}\n\n.textField_input {\n  height: 40px;\n  font-size: 15px;\n}\n\n.textField_container {\n  margin-right: 24px;\n}\n\n.icon {\n  --size: 28px;\n  margin: 6px 0px;\n}\n\n.actionButtons {\n  flex-shrink: 0;\n  display: flex;\n  justify-content: flex-end;\n  padding: 20px;\n}\n\n.actionButton_base {\n  width: 112px;\n  margin-left: 32px;\n}\n\n.configItem {\n  border: 1px solid var(--border-color);\n  border-radius: var(--small-border-radius);\n  padding: 12px;\n}\n\n.configItemName {\n  font-weight: 500;\n}\n\n.configItemValue {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `configuration-container-s7INy`,
	"addButton_base": `configuration-addButton_base-__qTl`,
	"configurations": `configuration-configurations-mfXXM`,
	"configuration": `configuration-configuration-T4o3g`,
	"textField_input": `configuration-textField_input-BFY4u`,
	"textField_container": `configuration-textField_container-xekLG`,
	"icon": `configuration-icon-bbDiO`,
	"actionButtons": `configuration-actionButtons-mnQff`,
	"actionButton_base": `configuration-actionButton_base-JY0BD`,
	"configItem": `configuration-configItem-Bzl4q`,
	"configItemName": `configuration-configItemName-p1IN6`,
	"configItemValue": `configuration-configItemValue-xeGDi`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal-bar-chart-container-LAyHf {
  width: 360px;
  margin-top: 40px;
}

.horizontal-bar-chart-title-kpxYh {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 500;
}

.horizontal-bar-chart-chart-MqjyA {
  display: block;
  margin-top: 24px;
}

.horizontal-bar-chart-line-wml0m {
  stroke: rgba(0, 0, 0, 8%);
}

.horizontal-bar-chart-tickText-KnA5E {
  fill: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  text-anchor: middle;
}

.horizontal-bar-chart-barText-INk0L {
  fill: rgba(0, 0, 0, 0.8);
  font-size: 12px;
}

.horizontal-bar-chart-green-qRRDA {
  fill: #27AE60;
}

.horizontal-bar-chart-blue-YegOG {
  fill: #2F80ED;
}

.horizontal-bar-chart-purple-fH24l {
  fill: #9B51E0;
}

.horizontal-bar-chart-orange-vorGw {
  fill: #F2994A;
}
`, "",{"version":3,"sources":["webpack://./src/components/horizontal-bar-chart/horizontal-bar-chart.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".container {\n  width: 360px;\n  margin-top: 40px;\n}\n\n.title {\n  color: rgba(0, 0, 0, 0.8);\n  font-size: 15px;\n  font-weight: 500;\n}\n\n.chart {\n  display: block;\n  margin-top: 24px;\n}\n\n.line {\n  stroke: rgba(0, 0, 0, 8%);\n}\n\n.tickText {\n  fill: rgba(0, 0, 0, 0.5);\n  font-size: 12px;\n  text-anchor: middle;\n}\n\n.barText {\n  fill: rgba(0, 0, 0, 0.8);\n  font-size: 12px;\n}\n\n.green {\n  fill: #27AE60;\n}\n\n.blue {\n  fill: #2F80ED;\n}\n\n.purple {\n  fill: #9B51E0;\n}\n\n.orange {\n  fill: #F2994A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `horizontal-bar-chart-container-LAyHf`,
	"title": `horizontal-bar-chart-title-kpxYh`,
	"chart": `horizontal-bar-chart-chart-MqjyA`,
	"line": `horizontal-bar-chart-line-wml0m`,
	"tickText": `horizontal-bar-chart-tickText-KnA5E`,
	"barText": `horizontal-bar-chart-barText-INk0L`,
	"green": `horizontal-bar-chart-green-qRRDA`,
	"blue": `horizontal-bar-chart-blue-YegOG`,
	"purple": `horizontal-bar-chart-purple-fH24l`,
	"orange": `horizontal-bar-chart-orange-vorGw`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-item-productItem-PRL4X {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: var(--small-border-radius);
  padding: 12px;
}

.product-item-productItemImage-aJuP0 {
  width: 32px;
  height: 32px;
}

.product-item-productItemInfo-nYw40 {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.product-item-productItemName-BUudA {
  display: block;
  margin-left: 24px;
}

.product-item-productItemType-w03Gu {
  width: 72px;
  font-size: 15px;
  font-style: italic;
  color: #555555;
}

.product-item-deleteOption_text-i_XYA {
  color: var(--accent-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/provider/products/product-item/product-item.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,qCAAqC;EACrC,yCAAyC;EACzC,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".productItem {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  border: 1px solid var(--border-color);\n  border-radius: var(--small-border-radius);\n  padding: 12px;\n}\n\n.productItemImage {\n  width: 32px;\n  height: 32px;\n}\n\n.productItemInfo {\n  flex-grow: 1;\n  display: flex;\n  justify-content: space-between;\n}\n\n.productItemName {\n  display: block;\n  margin-left: 24px;\n}\n\n.productItemType {\n  width: 72px;\n  font-size: 15px;\n  font-style: italic;\n  color: #555555;\n}\n\n.deleteOption_text {\n  color: var(--accent-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productItem": `product-item-productItem-PRL4X`,
	"productItemImage": `product-item-productItemImage-aJuP0`,
	"productItemInfo": `product-item-productItemInfo-nYw40`,
	"productItemName": `product-item-productItemName-BUudA`,
	"productItemType": `product-item-productItemType-w03Gu`,
	"deleteOption_text": `product-item-deleteOption_text-i_XYA`
};
export default ___CSS_LOADER_EXPORT___;

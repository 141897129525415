import { useEffect, useState } from 'react';
export function useRect(rect) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var _s = useState((rect === null || rect === void 0 ? void 0 : rect.width) || 0), width = _s[0], setWidth = _s[1];
    var _t = useState((rect === null || rect === void 0 ? void 0 : rect.height) || 0), height = _t[0], setHeight = _t[1];
    var _u = useState(((_a = rect === null || rect === void 0 ? void 0 : rect.padding) === null || _a === void 0 ? void 0 : _a.top) || 0), paddingTop = _u[0], setPaddingTop = _u[1];
    var _v = useState(((_b = rect === null || rect === void 0 ? void 0 : rect.padding) === null || _b === void 0 ? void 0 : _b.left) || 0), paddingLeft = _v[0], setPaddingLeft = _v[1];
    var _w = useState(((_c = rect === null || rect === void 0 ? void 0 : rect.padding) === null || _c === void 0 ? void 0 : _c.right) || 0), paddingRight = _w[0], setPaddingRight = _w[1];
    var _x = useState(((_d = rect === null || rect === void 0 ? void 0 : rect.padding) === null || _d === void 0 ? void 0 : _d.bottom) || 0), paddingBottom = _x[0], setPaddingBottom = _x[1];
    var _y = useState(((_e = rect === null || rect === void 0 ? void 0 : rect.margin) === null || _e === void 0 ? void 0 : _e.top) || 0), marginTop = _y[0], setMarginTop = _y[1];
    var _z = useState(((_f = rect === null || rect === void 0 ? void 0 : rect.margin) === null || _f === void 0 ? void 0 : _f.left) || 0), marginLeft = _z[0], setMarginLeft = _z[1];
    var _0 = useState(((_g = rect === null || rect === void 0 ? void 0 : rect.margin) === null || _g === void 0 ? void 0 : _g.right) || 0), marginRight = _0[0], setMarginRight = _0[1];
    var _1 = useState(((_h = rect === null || rect === void 0 ? void 0 : rect.margin) === null || _h === void 0 ? void 0 : _h.bottom) || 0), marginBottom = _1[0], setMarginBottom = _1[1];
    useEffect(function () {
        setWidth(function (state) { return (rect === null || rect === void 0 ? void 0 : rect.width) !== undefined ? rect.width : state; });
        setHeight(function (state) { return (rect === null || rect === void 0 ? void 0 : rect.height) !== undefined ? rect.height : state; });
        setPaddingTop(function (state) { var _a; return ((_a = rect === null || rect === void 0 ? void 0 : rect.padding) === null || _a === void 0 ? void 0 : _a.top) !== undefined ? rect.padding.top : state; });
        setPaddingLeft(function (state) { var _a; return ((_a = rect === null || rect === void 0 ? void 0 : rect.padding) === null || _a === void 0 ? void 0 : _a.left) !== undefined ? rect.padding.left : state; });
        setPaddingRight(function (state) { var _a; return ((_a = rect === null || rect === void 0 ? void 0 : rect.padding) === null || _a === void 0 ? void 0 : _a.right) !== undefined ? rect.padding.right : state; });
        setPaddingBottom(function (state) { var _a; return ((_a = rect === null || rect === void 0 ? void 0 : rect.padding) === null || _a === void 0 ? void 0 : _a.bottom) !== undefined ? rect.padding.bottom : state; });
        setMarginTop(function (state) { var _a; return ((_a = rect === null || rect === void 0 ? void 0 : rect.margin) === null || _a === void 0 ? void 0 : _a.top) !== undefined ? rect.margin.top : state; });
        setMarginLeft(function (state) { var _a; return ((_a = rect === null || rect === void 0 ? void 0 : rect.margin) === null || _a === void 0 ? void 0 : _a.left) !== undefined ? rect.margin.left : state; });
        setMarginRight(function (state) { var _a; return ((_a = rect === null || rect === void 0 ? void 0 : rect.margin) === null || _a === void 0 ? void 0 : _a.right) !== undefined ? rect.margin.right : state; });
        setMarginBottom(function (state) { var _a; return ((_a = rect === null || rect === void 0 ? void 0 : rect.margin) === null || _a === void 0 ? void 0 : _a.bottom) !== undefined ? rect.margin.bottom : state; });
    }, [
        rect === null || rect === void 0 ? void 0 : rect.width,
        rect === null || rect === void 0 ? void 0 : rect.height,
        (_j = rect === null || rect === void 0 ? void 0 : rect.padding) === null || _j === void 0 ? void 0 : _j.top,
        (_k = rect === null || rect === void 0 ? void 0 : rect.padding) === null || _k === void 0 ? void 0 : _k.left,
        (_l = rect === null || rect === void 0 ? void 0 : rect.padding) === null || _l === void 0 ? void 0 : _l.right,
        (_m = rect === null || rect === void 0 ? void 0 : rect.padding) === null || _m === void 0 ? void 0 : _m.bottom,
        (_o = rect === null || rect === void 0 ? void 0 : rect.margin) === null || _o === void 0 ? void 0 : _o.top,
        (_p = rect === null || rect === void 0 ? void 0 : rect.margin) === null || _p === void 0 ? void 0 : _p.left,
        (_q = rect === null || rect === void 0 ? void 0 : rect.margin) === null || _q === void 0 ? void 0 : _q.right,
        (_r = rect === null || rect === void 0 ? void 0 : rect.margin) === null || _r === void 0 ? void 0 : _r.bottom,
    ]);
    return {
        width: width,
        height: height,
        padding: {
            top: paddingTop,
            left: paddingLeft,
            right: paddingRight,
            bottom: paddingBottom
        },
        margin: {
            top: marginTop,
            left: marginLeft,
            right: marginRight,
            bottom: marginBottom
        },
        setWidth: setWidth,
        setHeight: setHeight,
        setPaddingTop: setPaddingTop,
        setPaddingLeft: setPaddingLeft,
        setPaddingRight: setPaddingRight,
        setPaddingBottom: setPaddingBottom,
        setMarginTop: setMarginTop,
        setMarginLeft: setMarginLeft,
        setMarginRight: setMarginRight,
        setMarginBottom: setMarginBottom,
        setPadding: function (top, left, right, bottom) {
            setPaddingTop(top);
            setPaddingLeft(left);
            setPaddingRight(right);
            setPaddingBottom(bottom);
        },
        setMargin: function (top, left, right, bottom) {
            setMarginTop(top);
            setMarginLeft(left);
            setMarginRight(right);
            setMarginBottom(bottom);
        }
    };
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pins-container-osiHw {
  height: 100%;
  padding: 0px 16px;
  overflow: hidden;
  margin: 28px 0px 24px;
}

.pins-table_container-Nztx8 {
  height: 100%;
  overflow: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/pages/products/pins/pins.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".container {\n  height: 100%;\n  padding: 0px 16px;\n  overflow: hidden;\n  margin: 28px 0px 24px;\n}\n\n.table_container {\n  height: 100%;\n  overflow: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pins-container-osiHw`,
	"table_container": `pins-table_container-Nztx8`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-image-container-ByFJA {
  background-color: white;
}

.upload-image-image-Jyopv {
  width: 136px;
  height: 136px;
  display: block;
  object-fit: scale-down;
  margin: auto;
}

.upload-image-uploadButton_base-zWh_E {
  width: 136px;
  margin-top: 32px;
  padding: 0px 8px;
}

.upload-image-uploadInput-rGT2P {
  display: none;
}

.upload-image-error-FvPw7 {
  margin-left: 0px;
  margin-top: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/upload-image/upload-image.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".container {\n  background-color: white;\n}\n\n.image {\n  width: 136px;\n  height: 136px;\n  display: block;\n  object-fit: scale-down;\n  margin: auto;\n}\n\n.uploadButton_base {\n  width: 136px;\n  margin-top: 32px;\n  padding: 0px 8px;\n}\n\n.uploadInput {\n  display: none;\n}\n\n.error {\n  margin-left: 0px;\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `upload-image-container-ByFJA`,
	"image": `upload-image-image-Jyopv`,
	"uploadButton_base": `upload-image-uploadButton_base-zWh_E`,
	"uploadInput": `upload-image-uploadInput-rGT2P`,
	"error": `upload-image-error-FvPw7`
};
export default ___CSS_LOADER_EXPORT___;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useAsyncEffect } from '@recargas-dominicanas/core/utils';
import { BusinessApi, CustomerApi } from '@recargas-dominicanas/core/api';
import { BusinessStore } from '../../store/businessStore';
import { CustomerCard } from '../../components/customer-card/customer-card.component';
import { Deposits } from '../../components/deposits/deposits.component';
import { BusinessUsers } from '../../components/business-users/business-users.component';
import { SalesReports } from '../../components/sales-reports/sales-reports.component';
import { TabsPane } from '../../components/tabs-pane/tabs-pane.component';
import { EditCustomerModal } from '../../components/edit-customer-modal/edit-customer-modal.component';
import { BusinessTransactions } from './business-transactions/business-transactions.component';
import { CustomerUser } from './customer-user/customer-user.component';
import { style } from './customer.module.css';
import { Case, Icon, PageContainer, PageContent, PageToolbar, SalesReport, Switch, Tab, TabBar, ToolbarTitle } from '@recargas-dominicanas/core/components';
export function Customer() {
    var _this = this;
    var params = useParams();
    var id = parseInt(params.id);
    var business = BusinessStore.getById(id);
    var deposits = BusinessStore.getDeposits(id);
    var salesReports = BusinessStore.getSalesReports(id);
    var _a = useState('transactions'), selectedTab = _a[0], setSelectedTab = _a[1];
    var _b = useState(false), openEditCustomer = _b[0], setOpenEditCustomer = _b[1];
    var _c = useState(), editCustomerError = _c[0], setEditCustomerError = _c[1];
    useAsyncEffect(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!business) return [3 /*break*/, 2];
                    return [4 /*yield*/, BusinessStore.fetchById(id)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [4 /*yield*/, BusinessStore.fetchTransactions(id, 1, 100)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, BusinessStore.fetchDeposits(id)];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, BusinessStore.fetchSalesReports(id)];
                case 5:
                    _a.sent();
                    return [4 /*yield*/, BusinessStore.fetchBusinessUsers(id)];
                case 6:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); });
    function handleTabClick(tab) {
        setSelectedTab(tab.tag);
    }
    function handleOpenEditCustomer() {
        setOpenEditCustomer(true);
    }
    function handleCloseEditCustomer() {
        setOpenEditCustomer(false);
        setEditCustomerError(undefined);
    }
    function handleEditCustomer(customerData, businessData) {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setEditCustomerError(undefined);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, CustomerApi.update(business.customer.id, customerData)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, BusinessApi.update(business.id, businessData)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, BusinessStore.fetchById(id)];
                    case 4:
                        _a.sent();
                        handleCloseEditCustomer();
                        return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        setEditCustomerError('Ocurrio un error al actualizar los datos del cliente');
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    }
    if (!business)
        return null;
    return (React.createElement(PageContainer, { className: style.container },
        React.createElement(PageToolbar, null,
            React.createElement(ToolbarTitle, { style: style.toolbarTitle, title: business.name }),
            React.createElement("div", { className: style.toolbarRight },
                React.createElement(SalesReport, { style: style.salesReport, balance: business.balance, sales: business.sales, profit: business.profit }),
                React.createElement("div", { className: style.options },
                    React.createElement(Icon, { className: style.option, icon: 'edit', onClick: handleOpenEditCustomer })))),
        React.createElement(PageContent, { className: style.content },
            React.createElement(CustomerCard, { style: style.customerCard, customer: business.customer, business: business }),
            React.createElement(TabsPane, null,
                React.createElement(TabBar, { onTabClick: handleTabClick },
                    React.createElement(Tab, { tag: 'transactions', title: 'Transacciones' }),
                    React.createElement(Tab, { tag: 'deposits', title: 'Depositos' }),
                    React.createElement(Tab, { tag: 'sales-reports', title: 'Cierre de ventas' }),
                    React.createElement(Tab, { tag: 'users', title: 'Usuarios' }),
                    React.createElement(Tab, { tag: 'customer', title: 'Cliente' })),
                React.createElement(Switch, { caseId: selectedTab },
                    React.createElement(Case, { caseId: 'transactions' },
                        React.createElement(BusinessTransactions, { id: business.id })),
                    React.createElement(Case, { caseId: 'deposits' },
                        React.createElement(Deposits, { deposits: deposits })),
                    React.createElement(Case, { caseId: 'sales-reports' },
                        React.createElement(SalesReports, { salesReports: salesReports })),
                    React.createElement(Case, { caseId: 'users' },
                        React.createElement(BusinessUsers, { businessId: business.id })),
                    React.createElement(Case, { caseId: 'customer' },
                        React.createElement(CustomerUser, { businessId: business.id, customer: business.customer }))))),
        React.createElement(EditCustomerModal, { open: openEditCustomer, title: 'Editar cliente', error: editCustomerError, business: business, onSave: handleEditCustomer, onClose: handleCloseEditCustomer })));
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { useAsyncEffect, validators } from '@recargas-dominicanas/core/utils';
import { FieldError, Icon } from '@recargas-dominicanas/core/components';
import { mergeStyle } from './image-picker.module.css';
export function ImagePicker(props) {
    var _this = this;
    var field = props.field, _a = props.required, required = _a === void 0 ? false : _a, image = props.image, customStyle = props.style;
    var style = mergeStyle(customStyle);
    var uploadInput = useRef(null);
    useEffect(function () {
        if (required)
            field === null || field === void 0 ? void 0 : field.addValidators([validators.required]);
    }, [required]);
    useEffect(function () {
        return function () { return field === null || field === void 0 ? void 0 : field.addValidators([]); };
    }, []);
    useAsyncEffect(function () { return __awaiter(_this, void 0, void 0, function () {
        var imageUrl, response, file;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!image) return [3 /*break*/, 2];
                    imageUrl = "".concat(process.env.IMAGES_DOMAIN, "/").concat(image);
                    return [4 /*yield*/, axios.get(imageUrl, { withCredentials: true, responseType: 'blob' })];
                case 1:
                    response = _a.sent();
                    file = new File([response.data], image, { type: response.data.type });
                    field === null || field === void 0 ? void 0 : field.update(file);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); });
    var objectUrlImage = useMemo(function () {
        if (image && !(field === null || field === void 0 ? void 0 : field.value))
            return "".concat(process.env.IMAGES_DOMAIN, "/").concat(image);
        if (field === null || field === void 0 ? void 0 : field.value)
            return URL.createObjectURL(field === null || field === void 0 ? void 0 : field.value);
    }, [field === null || field === void 0 ? void 0 : field.value, image]);
    function handleClick() {
        var _a;
        (_a = uploadInput.current) === null || _a === void 0 ? void 0 : _a.click();
    }
    function handleUploadInputChange(event) {
        var _a;
        field === null || field === void 0 ? void 0 : field.update((_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0]);
    }
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.title }, "Imagen"),
        React.createElement("div", { className: style.content, onClick: handleClick },
            !image && !(field === null || field === void 0 ? void 0 : field.value) &&
                React.createElement(Fragment, null,
                    React.createElement(Icon, { className: style.icon, icon: 'image' }),
                    React.createElement("div", { className: style.placeholder }, "Seleccionar imagen")),
            (image || (field === null || field === void 0 ? void 0 : field.value)) &&
                React.createElement("img", { className: style.image, src: objectUrlImage })),
        !(field === null || field === void 0 ? void 0 : field.isValid) &&
            React.createElement(FieldError, { className: style.error, message: 'Seleccione una imagen' }),
        React.createElement("input", { className: style.uploadInput, type: 'file', accept: '.png,.jpg,.jpeg,.svg', ref: uploadInput, onChange: handleUploadInputChange })));
}

import React, { useEffect, useRef } from 'react';
import { FieldError, Icon } from '@recargas-dominicanas/core/components';
import { mergeStyle } from './file-field.module.css';
import { validators } from '@recargas-dominicanas/core/utils';
import clsx from 'clsx';
export function FileField(props) {
    var _a;
    var className = props.className, placeholder = props.placeholder, _b = props.required, required = _b === void 0 ? false : _b, field = props.field;
    var style = mergeStyle(className);
    var inputRef = useRef(null);
    var filename = props.filename || ((_a = field === null || field === void 0 ? void 0 : field.value) === null || _a === void 0 ? void 0 : _a.name);
    useEffect(function () {
        if (required)
            field === null || field === void 0 ? void 0 : field.addValidators([validators.required]);
    }, [required]);
    useEffect(function () {
        return function () { return field === null || field === void 0 ? void 0 : field.addValidators([]); };
    }, []);
    function handleClick() {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    }
    function handleChange(event) {
        var _a;
        field === null || field === void 0 ? void 0 : field.update((_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0]);
    }
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.title }, "Aplicacion"),
        React.createElement("div", { className: style.field, onClick: handleClick },
            React.createElement("div", { className: clsx(style.value, filename && style.active), title: filename }, filename || placeholder),
            React.createElement(Icon, { className: style.icon, icon: 'file_upload' }),
            React.createElement("input", { className: style.input, ref: inputRef, type: 'file', accept: '.apk', onChange: handleChange })),
        !(field === null || field === void 0 ? void 0 : field.isValid) &&
            React.createElement(FieldError, { message: 'Campo requerido' })));
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-product-modal-modal_card-e_59v {
  width: auto;
  height: auto;
}

.edit-product-modal-modal_content-uUHIX {
  display: flex;
  padding: 24px;
}

.edit-product-modal-rightPane-GdQAx {
  width: 220px;
  margin-left: 52px;
}

.edit-product-modal-textField_container-MUYj7 {
  width: 100%;
}

.edit-product-modal-textField_input-M_pYP, .edit-product-modal-textField_input-M_pYP::placeholder {
  height: 40px;
  font-size: 15px;
}

.edit-product-modal-textField_container-MUYj7:not(:first-child) {
  margin-top: 24px;
}

.edit-product-modal-select_container-kfism {
  width: 100%;
  margin-top: 24px;
}

.edit-product-modal-selectOption_container-Be_XK {
  padding: 12px 0px 12px 12px;
}

.edit-product-modal-inputContainer-r0Uuz {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.edit-product-modal-inlineTextField_container-OuvWv {
  width: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/products/components/edit-product-modal/edit-product-modal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".modal_card {\n  width: auto;\n  height: auto;\n}\n\n.modal_content {\n  display: flex;\n  padding: 24px;\n}\n\n.rightPane {\n  width: 220px;\n  margin-left: 52px;\n}\n\n.textField_container {\n  width: 100%;\n}\n\n.textField_input, .textField_input::placeholder {\n  height: 40px;\n  font-size: 15px;\n}\n\n.textField_container:not(:first-child) {\n  margin-top: 24px;\n}\n\n.select_container {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.selectOption_container {\n  padding: 12px 0px 12px 12px;\n}\n\n.inputContainer {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 24px;\n}\n\n.inlineTextField_container {\n  width: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `edit-product-modal-modal_card-e_59v`,
	"modal_content": `edit-product-modal-modal_content-uUHIX`,
	"rightPane": `edit-product-modal-rightPane-GdQAx`,
	"textField_container": `edit-product-modal-textField_container-MUYj7`,
	"textField_input": `edit-product-modal-textField_input-M_pYP`,
	"select_container": `edit-product-modal-select_container-kfism`,
	"selectOption_container": `edit-product-modal-selectOption_container-Be_XK`,
	"inputContainer": `edit-product-modal-inputContainer-r0Uuz`,
	"inlineTextField_container": `edit-product-modal-inlineTextField_container-OuvWv`
};
export default ___CSS_LOADER_EXPORT___;

import React, { Fragment, useRef, useState } from 'react';
import { Icon, Menu } from '@recargas-dominicanas/core/components';
import { MenuOption } from '../../../components/menu-option/menu-option.component';
import { style } from './release-note-item.module.css';
import { ReleaseNoteField } from '../release-note-field/release-note-field.component';
export function ReleaseNoteItem(props) {
    var index = props.index, releaseNote = props.releaseNote, _a = props.showMenuOption, showMenuOption = _a === void 0 ? true : _a, onEdit = props.onEdit, onCancelEdit = props.onCancelEdit, onSave = props.onSave, onDelete = props.onDelete;
    var iconRef = useRef(null);
    var _b = useState(false), openMenu = _b[0], setOpenMenu = _b[1];
    var _c = useState(false), edit = _c[0], setEdit = _c[1];
    function handleEdit() {
        setEdit(true);
        setOpenMenu(false);
        onEdit();
    }
    function handleCancelEdit() {
        setEdit(false);
        onCancelEdit();
    }
    function handleDelete() {
        onDelete(index);
    }
    function handleSave(title, description) {
        onSave(index, title, description);
        setEdit(false);
    }
    return (React.createElement(Fragment, null,
        !edit &&
            React.createElement("div", { className: style.container },
                React.createElement("div", { className: style.toolbar },
                    React.createElement("div", { className: style.title }, releaseNote.title),
                    showMenuOption &&
                        React.createElement(Icon, { className: style.icon, iconRef: iconRef, icon: 'more_horiz', onClick: function () { return setOpenMenu(true); } })),
                React.createElement("div", { className: style.description }, releaseNote.description),
                React.createElement(Menu, { open: openMenu, anchor: iconRef, style: style.menu, onClose: function () { return setOpenMenu(false); } },
                    React.createElement(MenuOption, { style: style.menuOption, text: 'Editar', onClick: handleEdit }),
                    React.createElement(MenuOption, { style: style.menuOption, text: 'Eliminar', onClick: handleDelete }))),
        edit &&
            React.createElement(ReleaseNoteField, { releaseNote: releaseNote, onSave: handleSave, onCancel: handleCancelEdit })));
}

import React from 'react';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { Icon, Image } from '@recargas-dominicanas/core/components';
import { mergeStyle } from './bank-account-select-item.module.css';
export function BankAccountSelectItem(props) {
    var customStyle = props.style, bankAccount = props.bankAccount, _a = props.showCloseIcon, showCloseIcon = _a === void 0 ? true : _a, onClose = props.onClose;
    var style = mergeStyle(customStyle);
    function handleClose(event) {
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    return (React.createElement("div", { className: style.container },
        React.createElement(Image, { className: style.image, src: bankAccount.bank.image }),
        React.createElement("div", { className: style.data },
            React.createElement("div", { className: style.row },
                React.createElement("span", { className: style.name }, bankAccount.name),
                showCloseIcon &&
                    React.createElement(Icon, { className: style.closeIcon, icon: 'clear', onMouseDown: handleClose })),
            React.createElement("div", { className: style.row },
                React.createElement("span", { className: style.account }, bankAccount.accountNumber),
                React.createElement("span", { className: style.balance }, formatCurrency(bankAccount.balance))))));
}

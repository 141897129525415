// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-user-rename-modal-modal_card-cwP6l {
  width: 340px;
  height: 400px;
}

.business-user-rename-modal-modal_content-ielry {
  padding: 0px 20px;
}


.business-user-rename-modal-modal_primaryButtonBase-NLiCS {
  margin-left: 32px;
}

.business-user-rename-modal-textField_container-DqSqx {
  width: 100%;
  margin-top: 24px;
}

.business-user-rename-modal-item-grzK8 {
  border: 1px solid var(--border-color);
  border-radius: var(--small-border-radius);
  padding: 12px 16px;
  margin-top: 24px;
}

.business-user-rename-modal-title-hXWlB {
  display: block;
  font-size: 15px;
}

.business-user-rename-modal-username-_saWz {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/business-user-rename-modal/business-user-rename-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;;AAGA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,qCAAqC;EACrC,yCAAyC;EACzC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".modal_card {\n  width: 340px;\n  height: 400px;\n}\n\n.modal_content {\n  padding: 0px 20px;\n}\n\n\n.modal_primaryButtonBase {\n  margin-left: 32px;\n}\n\n.textField_container {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.item {\n  border: 1px solid var(--border-color);\n  border-radius: var(--small-border-radius);\n  padding: 12px 16px;\n  margin-top: 24px;\n}\n\n.title {\n  display: block;\n  font-size: 15px;\n}\n\n.username {\n  display: block;\n  font-size: 16px;\n  font-weight: 500;\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `business-user-rename-modal-modal_card-cwP6l`,
	"modal_content": `business-user-rename-modal-modal_content-ielry`,
	"modal_primaryButtonBase": `business-user-rename-modal-modal_primaryButtonBase-NLiCS`,
	"textField_container": `business-user-rename-modal-textField_container-DqSqx`,
	"item": `business-user-rename-modal-item-grzK8`,
	"title": `business-user-rename-modal-title-hXWlB`,
	"username": `business-user-rename-modal-username-_saWz`
};
export default ___CSS_LOADER_EXPORT___;

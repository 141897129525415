// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finance-item-container-lWi8U {
  min-width: 200px;
  width: 240px;
  flex-grow: 1;
  border: 1px solid var(--border-color);
  border-radius: var(--large-border-radius);
  padding: 12px 16px;
}

.finance-item-title-VS3Ah {
  color: rgba(0, 0, 0, 0.80);
  font-size: 13px;
  font-weight: 500;
}

.finance-item-amount-H4QTR {
  color: var(--money-color);
  font-size: 18px;
  font-weight: bold;
  margin-top: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/finances/finance-item/finance-item.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,qCAAqC;EACrC,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".container {\n  min-width: 200px;\n  width: 240px;\n  flex-grow: 1;\n  border: 1px solid var(--border-color);\n  border-radius: var(--large-border-radius);\n  padding: 12px 16px;\n}\n\n.title {\n  color: rgba(0, 0, 0, 0.80);\n  font-size: 13px;\n  font-weight: 500;\n}\n\n.amount {\n  color: var(--money-color);\n  font-size: 18px;\n  font-weight: bold;\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `finance-item-container-lWi8U`,
	"title": `finance-item-title-VS3Ah`,
	"amount": `finance-item-amount-H4QTR`
};
export default ___CSS_LOADER_EXPORT___;

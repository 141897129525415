import * as d3 from 'd3';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { formatChartValue } from '../../utils/formatChartValue';
import { useRect } from '../../hooks/useRect';
import { style } from './sales-by-product-chart.module.css';
import { LegendItem } from '../legend-item/legend-item.component';
import { SalesByProductItem } from '../sales-by-product-item/sales-by-product-item.component';
export function SalesByProductChart(props) {
    var title = props.title, _a = props.products, products = _a === void 0 ? [] : _a;
    var _b = useState(0), resize = _b[0], setResize = _b[1];
    var contentRef = useRef(null);
    var svgRef = useRef(null);
    var svgTicksRef = useRef(null);
    var deps = products.map(function (product) { return product.id + product.total; }).toString();
    var container = useRect({ padding: { left: 4 } });
    var tickText = useRect({ margin: { top: 12 } });
    var lastTickText = useRect();
    var productName = useRect({ margin: { right: 12 } });
    var bar = useRect({ height: 20, margin: { top: 16 } });
    var xAxisStart = container.padding.left + productName.width + productName.margin.right;
    var xAxisEnd = container.width - (lastTickText.width / 2);
    var yAxisStart = container.padding.top + (bar.height / 2);
    var yAxisEnd = container.height - (bar.height / 2);
    var max = useMemo(function () { return d3.max(products, function (product) { return product.total; }); }, [deps]);
    var ids = useMemo(function () { return d3.map(products, function (product) { return product.id; }); }, [deps]);
    var x = useMemo(function () { return d3.scaleLinear([0, max], [xAxisStart, xAxisEnd]); }, [max, xAxisStart, xAxisEnd]);
    var y = useMemo(function () { return d3.scalePoint(ids, [yAxisStart, yAxisEnd]); }, [deps, yAxisStart, yAxisEnd]);
    var xTicks = useMemo(function () { return x.nice(3).ticks(3); }, [x]);
    useEffect(function () {
        var onResize = function () { return setResize(window.innerWidth + window.innerHeight); };
        window.addEventListener('resize', onResize);
        return function () { return window.removeEventListener('resize', onResize); };
    }, []);
    useEffect(function () {
        var rect = contentRef.current.getBoundingClientRect();
        container.setWidth(rect.width - 10);
        container.setHeight(Math.max(rect.height, products.length * (bar.height + bar.margin.top)));
    }, [deps, resize]);
    useEffect(function () {
        var _a, _b;
        if (products.length > 0 && container.width && container.height) {
            var tickTextElements = svgTicksRef.current.querySelectorAll(".".concat(style.tickText));
            var productNameElements = svgRef.current.querySelectorAll(".".concat(style.productName));
            var productNameRects = Array.from(productNameElements).map(function (element) { return element.getBBox(); });
            var maxProductNameRect = productNameRects.reduce(function (prev, next) { return prev.width > next.width ? prev : next; });
            tickText.setHeight(((_a = tickTextElements[0]) === null || _a === void 0 ? void 0 : _a.getBBox().height) || 0);
            lastTickText.setWidth(((_b = tickTextElements[tickTextElements.length - 1]) === null || _b === void 0 ? void 0 : _b.getBBox().width) || 0);
            productName.setWidth(maxProductNameRect.width || 0);
        }
    }, [deps, container.width, container.height]);
    function colors(type) {
        switch (type) {
            case 'Recarga': return 'green';
            case 'Paquetico': return 'blue';
            case 'Pin': return 'purple';
            case 'Factura': return 'orange';
            default: throw 'error';
        }
    }
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.title }, title),
        React.createElement("div", { ref: contentRef, className: style.content },
            React.createElement("svg", { className: style.chart, height: container.height, ref: svgRef },
                React.createElement("g", null, xTicks.map(function (tick) {
                    return React.createElement("line", { key: tick, className: style.line, x1: x(tick), x2: x(tick), y1: container.padding.top, y2: container.height });
                })),
                React.createElement("g", null, products.map(function (product) {
                    return React.createElement("text", { key: product.id, className: style.productName, x: container.padding.left + productName.width, y: y(product.id), textAnchor: 'end', alignmentBaseline: 'middle' }, product.name);
                })),
                React.createElement("g", null, container.width && container.height && products.map(function (product) {
                    return React.createElement(SalesByProductItem, { key: product.id, className: style[colors(product.type)], product: product, height: bar.height, x: x, y: y });
                })))),
        React.createElement("svg", { className: style.ticks, height: 16, ref: svgTicksRef }, xTicks.map(function (tick) {
            return React.createElement("text", { key: tick, className: style.tickText, x: x(tick), y: 16, alignmentBaseline: 'text-after-edge' }, formatChartValue(tick));
        })),
        React.createElement("div", { className: style.legend },
            React.createElement(LegendItem, { title: 'Recargas', color: 'green' }),
            React.createElement(LegendItem, { title: 'Paqueticos', color: 'blue' }),
            React.createElement(LegendItem, { title: 'Pines', color: 'purple' }),
            React.createElement(LegendItem, { title: 'Facturas', color: 'orange' }))));
}

import React, { useEffect, useState } from 'react';
import { formatCurrency, formatDate } from '@recargas-dominicanas/core/utils';
import { ProviderStore } from '../../../store/providerStore';
import { RegisterDepositModal } from './register-deposit-modal/register-deposit-modal.component';
import { style } from './deposits.module.css';
import { Table, TableHeader, OutlineButton, TableRow, CellImage, Cell } from '@recargas-dominicanas/core/components';
export function Deposits(props) {
    var id = props.id;
    var deposits = ProviderStore.getDeposits(id);
    var _a = useState(false), openRegisterModal = _a[0], setOpenRegisterModal = _a[1];
    useEffect(function () {
        ProviderStore.fetchDeposits(id);
    }, []);
    return (React.createElement("div", { className: style.container },
        React.createElement(Table, { style: style.table },
            React.createElement(TableHeader, { style: style.tableHeader },
                React.createElement("span", null, "Banco"),
                React.createElement("span", null, "Balance"),
                React.createElement("span", null, "Fecha"),
                React.createElement("span", null, "Descripci\u00F3n"),
                React.createElement(OutlineButton, { style: style.addButton, text: 'Registrar deposito', onClick: function () { return setOpenRegisterModal(true); } })),
            React.createElement("tbody", null, deposits === null || deposits === void 0 ? void 0 : deposits.map(function (deposit) { return (React.createElement(TableRow, { key: deposit.id },
                React.createElement(CellImage, { src: deposit.bankAccount.bank.image }),
                React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(deposit.balance.amount) }),
                React.createElement(Cell, { text: formatDate(deposit.date) }),
                React.createElement(Cell, { className: style.description, text: deposit.reference }),
                React.createElement(Cell, { text: '' }))); }))),
        React.createElement(RegisterDepositModal, { open: openRegisterModal, id: id, onClose: function () { return setOpenRegisterModal(false); } })));
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-account-select-item-container-beBgM {
  width: 100%;
  display: flex;
  padding: 8px;
}

.bank-account-select-item-image-L5hYy {
  width: 32px;
  height: 32px;
}

.bank-account-select-item-data-sWO87 {
  width: 100%;
  margin-left: 16px;
}

.bank-account-select-item-row-JihNh {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bank-account-select-item-row-JihNh:not(:first-child) {
  margin-top: 12px;
}

.bank-account-select-item-name-aj9Jj {
  display: block;
  color: rgba(0, 0, 0, 0.75);
  font-size: 15px;
  font-weight: 500;
}

.bank-account-select-item-closeIcon-ymx9Z {
  --size: 20px;
  color: rgba(0, 0, 0, 0.75);
}

.bank-account-select-item-account-yW6DR {
  display: block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.bank-account-select-item-balance-xAWII {
  font-size: 14px;
  font-weight: 500;
  color: var(--money-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/bank-account-select-item/bank-account-select-item.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  padding: 8px;\n}\n\n.image {\n  width: 32px;\n  height: 32px;\n}\n\n.data {\n  width: 100%;\n  margin-left: 16px;\n}\n\n.row {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.row:not(:first-child) {\n  margin-top: 12px;\n}\n\n.name {\n  display: block;\n  color: rgba(0, 0, 0, 0.75);\n  font-size: 15px;\n  font-weight: 500;\n}\n\n.closeIcon {\n  --size: 20px;\n  color: rgba(0, 0, 0, 0.75);\n}\n\n.account {\n  display: block;\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.balance {\n  font-size: 14px;\n  font-weight: 500;\n  color: var(--money-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `bank-account-select-item-container-beBgM`,
	"image": `bank-account-select-item-image-L5hYy`,
	"data": `bank-account-select-item-data-sWO87`,
	"row": `bank-account-select-item-row-JihNh`,
	"name": `bank-account-select-item-name-aj9Jj`,
	"closeIcon": `bank-account-select-item-closeIcon-ymx9Z`,
	"account": `bank-account-select-item-account-yW6DR`,
	"balance": `bank-account-select-item-balance-xAWII`
};
export default ___CSS_LOADER_EXPORT___;

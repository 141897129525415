// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-deposit-modal-modal_card-qzuKi {
  width: 340px;
  height: initial;
}

.register-deposit-modal-modal_content-vWyMB {
  overflow: scroll;
  padding: 0px 10px 4px 20px;
}

.register-deposit-modal-textField_container-Q7GtL, .register-deposit-modal-dateTime_container-yq0dF {
  width: 100%;
  margin-top: 24px;
}

.register-deposit-modal-textField_input-xMbjj {
  height: 40px;
  font-size: 15px;
}

.register-deposit-modal-textField_container-Q7GtL:first-child {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/provider/deposits/register-deposit-modal/register-deposit-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".modal_card {\n  width: 340px;\n  height: initial;\n}\n\n.modal_content {\n  overflow: scroll;\n  padding: 0px 10px 4px 20px;\n}\n\n.textField_container, .dateTime_container {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.textField_input {\n  height: 40px;\n  font-size: 15px;\n}\n\n.textField_container:first-child {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `register-deposit-modal-modal_card-qzuKi`,
	"modal_content": `register-deposit-modal-modal_content-vWyMB`,
	"textField_container": `register-deposit-modal-textField_container-Q7GtL`,
	"dateTime_container": `register-deposit-modal-dateTime_container-yq0dF`,
	"textField_input": `register-deposit-modal-textField_input-xMbjj`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-business-user-modal_card-oaAfd {
  width: 340px;
  height: 400px;
  min-height: 372px;
}

.add-business-user-modal_content-qiuQv {
  padding: 0px 20px;
}


.add-business-user-modal_primaryButtonBase-zfn6T {
  margin-left: 32px;
}

.add-business-user-textField_container-ML_a1 {
  width: 100%;
  margin-top: 24px;
}

.add-business-user-item-Fk2tu {
  border: 1px solid var(--border-color);
  border-radius: var(--small-border-radius);
  padding: 12px 16px;
  margin-top: 24px;
}

.add-business-user-title-Dn4AK {
  display: block;
  font-size: 15px;
}

.add-business-user-username-ExP_N {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/add-business-user/add-business-user.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;;AAGA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,qCAAqC;EACrC,yCAAyC;EACzC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".modal_card {\n  width: 340px;\n  height: 400px;\n  min-height: 372px;\n}\n\n.modal_content {\n  padding: 0px 20px;\n}\n\n\n.modal_primaryButtonBase {\n  margin-left: 32px;\n}\n\n.textField_container {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.item {\n  border: 1px solid var(--border-color);\n  border-radius: var(--small-border-radius);\n  padding: 12px 16px;\n  margin-top: 24px;\n}\n\n.title {\n  display: block;\n  font-size: 15px;\n}\n\n.username {\n  display: block;\n  font-size: 16px;\n  font-weight: 500;\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `add-business-user-modal_card-oaAfd`,
	"modal_content": `add-business-user-modal_content-qiuQv`,
	"modal_primaryButtonBase": `add-business-user-modal_primaryButtonBase-zfn6T`,
	"textField_container": `add-business-user-textField_container-ML_a1`,
	"item": `add-business-user-item-Fk2tu`,
	"title": `add-business-user-title-Dn4AK`,
	"username": `add-business-user-username-ExP_N`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-accounts-container-lNpXm {
  padding: 0px 32px 20px;
}

.bank-accounts-toolbar-ofnqG {
  height: 56px;
  align-items: center;
}

.bank-accounts-title_title-IP9gC {
  font-size: 17px;
}

.bank-accounts-content-eOhHJ {
  margin-top: 0px;
}

.bank-accounts-tableHeader_cellContent-PieAU {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/pages/bank-accounts/bank-accounts.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".container {\n  padding: 0px 32px 20px;\n}\n\n.toolbar {\n  height: 56px;\n  align-items: center;\n}\n\n.title_title {\n  font-size: 17px;\n}\n\n.content {\n  margin-top: 0px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `bank-accounts-container-lNpXm`,
	"toolbar": `bank-accounts-toolbar-ofnqG`,
	"title_title": `bank-accounts-title_title-IP9gC`,
	"content": `bank-accounts-content-eOhHJ`,
	"tableHeader_cellContent": `bank-accounts-tableHeader_cellContent-PieAU`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products-container-gTfpy {
  padding: 8px 16px 0px;
}

.products-tabBar_container-f1qEH {
  flex-shrink: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/products/products.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".container {\n  padding: 8px 16px 0px;\n}\n\n.tabBar_container {\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `products-container-gTfpy`,
	"tabBar_container": `products-tabBar_container-f1qEH`
};
export default ___CSS_LOADER_EXPORT___;

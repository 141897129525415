// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finances-container-rK44A {
  overflow-x: auto;
  padding: 0px 32px;
}

.finances-toolbar-WYqJa {
  min-width: 400px;
  height: 56px;
  align-items: center;
}

.finances-title_title-rVpPv {
  font-size: 17px;
}

.finances-options-gErol {
  display: flex;
  gap: 12px;
}

.finances-menu_container-aXZsB {
  width: 200px
}

.finances-menuOption_text-REcjI {
  font-size: 15px;
  color: rgb(0, 0, 0, 0.8);
}

.finances-content-Xl_yk {
  width: 100%;
  min-width: 400px;
  margin: 0px auto;
  overflow-y: auto;
  max-width: 1440px;
}

.finances-financeItems-kFR_S {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  overflow-x: auto;
}

.finances-financeItems-kFR_S::-webkit-scrollbar { 
  display: none;
}

.finances-charts-ncmGN {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  padding: 0px 4px 8px;
  margin-top: 24px;
}

.finances-chartColumn-JelaZ, .finances-verticalBarCharts-gxzpM {
  height: 536px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.finances-verticalBarCharts-gxzpM {
  min-width: 380px;
  flex-grow: 1;
  gap: 44px;
}

.finances-verticalBarChart-PpUyF {
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/finances/finances.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE;AACF;;AAEA;EACE,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,YAAY;AACd","sourcesContent":[".container {\n  overflow-x: auto;\n  padding: 0px 32px;\n}\n\n.toolbar {\n  min-width: 400px;\n  height: 56px;\n  align-items: center;\n}\n\n.title_title {\n  font-size: 17px;\n}\n\n.options {\n  display: flex;\n  gap: 12px;\n}\n\n.menu_container {\n  width: 200px\n}\n\n.menuOption_text {\n  font-size: 15px;\n  color: rgb(0, 0, 0, 0.8);\n}\n\n.content {\n  width: 100%;\n  min-width: 400px;\n  margin: 0px auto;\n  overflow-y: auto;\n  max-width: 1440px;\n}\n\n.financeItems {\n  display: flex;\n  justify-content: space-between;\n  gap: 32px;\n  overflow-x: auto;\n}\n\n.financeItems::-webkit-scrollbar { \n  display: none;\n}\n\n.charts {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 60px;\n  padding: 0px 4px 8px;\n  margin-top: 24px;\n}\n\n.chartColumn, .verticalBarCharts {\n  height: 536px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.verticalBarCharts {\n  min-width: 380px;\n  flex-grow: 1;\n  gap: 44px;\n}\n\n.verticalBarChart {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `finances-container-rK44A`,
	"toolbar": `finances-toolbar-WYqJa`,
	"title_title": `finances-title_title-rVpPv`,
	"options": `finances-options-gErol`,
	"menu_container": `finances-menu_container-aXZsB`,
	"menuOption_text": `finances-menuOption_text-REcjI`,
	"content": `finances-content-Xl_yk`,
	"financeItems": `finances-financeItems-kFR_S`,
	"charts": `finances-charts-ncmGN`,
	"chartColumn": `finances-chartColumn-JelaZ`,
	"verticalBarCharts": `finances-verticalBarCharts-gxzpM`,
	"verticalBarChart": `finances-verticalBarChart-PpUyF`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-user-confirm-modal-deleteModal_card-EmZ1B {
  width: 360px;
  height: auto;
}

.business-user-confirm-modal-toolbar_container-onDae {
  border-bottom: 1px solid var(--border-color);
}

.business-user-confirm-modal-toolbar_title-lvuuh {
  font-size: 16px;
}

.business-user-confirm-modal-item-SbyL_ {
  border: 1px solid var(--border-color);
  border-radius: var(--small-border-radius);
  padding: 16px;
  margin-top: 24px;
}

.business-user-confirm-modal-itemTitle-p_ag3 {
  display: block;
  font-size: 15px;
  font-weight: 500;
}

.business-user-confirm-modal-username-I1AUY {
  display: block;
  color: var(--accent-color);
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
}

.business-user-confirm-modal-label-jmPya {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 20px;
}

.business-user-confirm-modal-checkbox-U3aNk {
  margin-right: 8px;
}

.business-user-confirm-modal-error_container-cCcVt {
  margin: 24px 20px 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/business-user-confirm-modal/business-user-confirm-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qCAAqC;EACrC,yCAAyC;EACzC,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".deleteModal_card {\n  width: 360px;\n  height: auto;\n}\n\n.toolbar_container {\n  border-bottom: 1px solid var(--border-color);\n}\n\n.toolbar_title {\n  font-size: 16px;\n}\n\n.item {\n  border: 1px solid var(--border-color);\n  border-radius: var(--small-border-radius);\n  padding: 16px;\n  margin-top: 24px;\n}\n\n.itemTitle {\n  display: block;\n  font-size: 15px;\n  font-weight: 500;\n}\n\n.username {\n  display: block;\n  color: var(--accent-color);\n  font-size: 16px;\n  font-weight: 500;\n  margin-top: 12px;\n}\n\n.label {\n  display: flex;\n  align-items: center;\n  font-size: 15px;\n  margin-top: 20px;\n}\n\n.checkbox {\n  margin-right: 8px;\n}\n\n.error_container {\n  margin: 24px 20px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteModal_card": `business-user-confirm-modal-deleteModal_card-EmZ1B`,
	"toolbar_container": `business-user-confirm-modal-toolbar_container-onDae`,
	"toolbar_title": `business-user-confirm-modal-toolbar_title-lvuuh`,
	"item": `business-user-confirm-modal-item-SbyL_`,
	"itemTitle": `business-user-confirm-modal-itemTitle-p_ag3`,
	"username": `business-user-confirm-modal-username-I1AUY`,
	"label": `business-user-confirm-modal-label-jmPya`,
	"checkbox": `business-user-confirm-modal-checkbox-U3aNk`,
	"error_container": `business-user-confirm-modal-error_container-cCcVt`
};
export default ___CSS_LOADER_EXPORT___;

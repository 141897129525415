// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-container-FpfmS {
  width: 28px;
  height: 16px;
  position: relative;
  border-radius: 14px;
  border: 1px solid transparent;
  background-color: rgba(0, 0, 0, 0.25);
  transition: background-color 160ms;
  cursor: pointer;
}

.toggle-containerOn-vca4f {
  background-color: rgba(241, 38, 38, 90%);
}

.toggle-thumb-PFJjb {
  width: 14px;
  height: 14px;
  position: absolute;
  left: 0px;
  background-color: white;
  border-radius: 7px;
  transition: left 160ms;
}

.toggle-thumbOn-b_kqK {
  left: calc(100% - 14px);
}
`, "",{"version":3,"sources":["webpack://./src/components/toggle/toggle.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,6BAA6B;EAC7B,qCAAqC;EACrC,kCAAkC;EAClC,eAAe;AACjB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,uBAAuB;EACvB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".container {\n  width: 28px;\n  height: 16px;\n  position: relative;\n  border-radius: 14px;\n  border: 1px solid transparent;\n  background-color: rgba(0, 0, 0, 0.25);\n  transition: background-color 160ms;\n  cursor: pointer;\n}\n\n.containerOn {\n  background-color: rgba(241, 38, 38, 90%);\n}\n\n.thumb {\n  width: 14px;\n  height: 14px;\n  position: absolute;\n  left: 0px;\n  background-color: white;\n  border-radius: 7px;\n  transition: left 160ms;\n}\n\n.thumbOn {\n  left: calc(100% - 14px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `toggle-container-FpfmS`,
	"containerOn": `toggle-containerOn-vca4f`,
	"thumb": `toggle-thumb-PFJjb`,
	"thumbOn": `toggle-thumbOn-b_kqK`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useRef, useState } from 'react';
import { formatCurrency, formatDate } from '@recargas-dominicanas/core/utils';
import { TableRow, Cell, CellIcon, Menu } from '@recargas-dominicanas/core/components';
import { PendingTransactionModal } from '../pending-transaction-modal/pending-transaction-modal.component';
import { RegisterPendingDepositModal } from '../register-pending-deposit-modal/register-pending-deposit-modal.component';
import { RegisterPendingWithdrawalModal } from '../register-pending-withdrawal-modal/register-pending-withdrawal-modal.component';
import { LinkPendingWithdrawalModal } from '../link-pending-withdrawal-modal/link-pending-withdrawal-modal.component';
import { LinkPendingDepositModal } from '../link-pending-deposit-modal/link-pending-deposit-modal.component';
import { MenuOption } from '../../../../components/menu-option/menu-option.component';
import { style } from './pending-transaction-item.module.css';
import { RegisterPendingProfitWithdrawalModal } from '../register-pending-profit-withdrawal-modal/register-pending-profit-withdrawal-modal.component';
export function PendingTransactionItem(props) {
    var bankAccountId = props.bankAccountId, pendingTransaction = props.pendingTransaction;
    var registered = pendingTransaction.status === 'registered';
    var menuIconRef = useRef(null);
    var _a = useState(false), openMenu = _a[0], setOpenMenu = _a[1];
    var _b = useState(false), openPendingTransactionModal = _b[0], setOpenPendingTransactionModal = _b[1];
    var _c = useState(false), openRegisterPendingDepositModal = _c[0], setOpenRegisterPendingDepositModal = _c[1];
    var _d = useState(false), openRegisterPendingWithdrawalModal = _d[0], setOpenRegisterPendingWithdrawalModal = _d[1];
    var _e = useState(false), openRegisterPendingProfitWithdrawalModal = _e[0], setOpenRegisterPendingProfitWithdrawalModal = _e[1];
    var _f = useState(false), openLinkPendingDepositModal = _f[0], setOpenLinkPendingDepositModal = _f[1];
    var _g = useState(false), openLinkPendingWithdrawalModal = _g[0], setOpenLinkPendingWithdrawalModal = _g[1];
    function handleOpenRegisterModal() {
        if (pendingTransaction.type === 'deposit')
            setOpenRegisterPendingDepositModal(true);
        if (pendingTransaction.type === 'withdrawal')
            setOpenRegisterPendingWithdrawalModal(true);
        setOpenMenu(false);
    }
    function handleOpenRegisterProfitModal() {
        setOpenRegisterPendingProfitWithdrawalModal(true);
        setOpenMenu(false);
    }
    function handleOpenLinkModal() {
        if (pendingTransaction.type === 'deposit')
            setOpenLinkPendingDepositModal(true);
        if (pendingTransaction.type === 'withdrawal')
            setOpenLinkPendingWithdrawalModal(true);
        setOpenMenu(false);
    }
    return (React.createElement(TableRow, { onClick: function () { return setOpenPendingTransactionModal(true); } },
        React.createElement(Cell, { text: pendingTransaction.type === 'deposit' ? 'Deposito' : 'Retiro' }),
        React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(pendingTransaction.balance) }),
        React.createElement(Cell, { text: formatDate(pendingTransaction.creationDate) }),
        !registered &&
            React.createElement(Cell, { text: 'No registrado', color: 'gray', weight: 'medium' }),
        registered &&
            React.createElement(Cell, { text: 'Registrado', color: 'green', weight: 'medium' }),
        React.createElement(Cell, { className: style.description, text: pendingTransaction.description }),
        React.createElement(CellIcon, { refIcon: menuIconRef, icon: 'more_horiz', onClick: function () { return setOpenMenu(true); } }),
        React.createElement(Menu, { open: openMenu, anchor: menuIconRef, onClose: function () { return setOpenMenu(false); } },
            React.createElement(MenuOption, { text: 'Registrar', disabled: registered, onClick: handleOpenRegisterModal }),
            pendingTransaction.type === 'withdrawal' &&
                React.createElement(MenuOption, { text: 'Registrar como gasto', disabled: registered, onClick: handleOpenRegisterProfitModal }),
            React.createElement(MenuOption, { text: 'Enlazar', disabled: registered, onClick: handleOpenLinkModal })),
        React.createElement(PendingTransactionModal, { open: openPendingTransactionModal, pendingTransaction: pendingTransaction, onClose: function () { return setOpenPendingTransactionModal(false); } }),
        React.createElement(RegisterPendingDepositModal, { open: openRegisterPendingDepositModal, bankAccountId: bankAccountId, pendingTransaction: pendingTransaction, onClose: function () { return setOpenRegisterPendingDepositModal(false); } }),
        React.createElement(RegisterPendingWithdrawalModal, { open: openRegisterPendingWithdrawalModal, bankAccountId: bankAccountId, pendingTransaction: pendingTransaction, onClose: function () { return setOpenRegisterPendingWithdrawalModal(false); } }),
        React.createElement(RegisterPendingProfitWithdrawalModal, { open: openRegisterPendingProfitWithdrawalModal, bankAccountId: bankAccountId, pendingTransaction: pendingTransaction, onClose: function () { return setOpenRegisterPendingProfitWithdrawalModal(false); } }),
        React.createElement(LinkPendingDepositModal, { open: openLinkPendingDepositModal, bankAccountId: bankAccountId, pendingTransaction: pendingTransaction, onClose: function () { return setOpenLinkPendingDepositModal(false); } }),
        React.createElement(LinkPendingWithdrawalModal, { open: openLinkPendingWithdrawalModal, bankAccountId: bankAccountId, pendingTransaction: pendingTransaction, onClose: function () { return setOpenLinkPendingWithdrawalModal(false); } })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';
import { formatDate, useForm, validators } from '@recargas-dominicanas/core/utils';
import { FileField } from '../../../components/file-field/file-field.component';
import { ReleaseNoteField } from '../release-note-field/release-note-field.component';
import { ReleaseNoteItem } from '../release-note-item/release-note-item.component';
import { AppReleaseApi } from '@recargas-dominicanas/core/api';
import { AppReleaseStore } from '../../../store/appReleaseStore';
import { style } from './app-release-modal.module.css';
import { Button, Error, FieldError, IndeterminateProgressBar, Modal, ModalActions, ModalContent, ModalToolbar, OutlineButton, Text, TextField } from '@recargas-dominicanas/core/components';
export function AppReleaseModal(props) {
    var _a = props.open, open = _a === void 0 ? false : _a, appRelease = props.appRelease, onSave = props.onSave, onClose = props.onClose;
    var _b = useState([]), releaseNotes = _b[0], setReleaseNotes = _b[1];
    var _c = useState(true), showReleaseNoteField = _c[0], setShowReleaseNoteField = _c[1];
    var _d = useState(false), showAddButton = _d[0], setShowAddButton = _d[1];
    var _e = useState(), errorMessage = _e[0], setErrorMessage = _e[1];
    var _f = useState(false), loading = _f[0], setLoading = _f[1];
    var _g = useState(false), showReleaseNotesError = _g[0], setShowReleaseNoteError = _g[1];
    var form = useForm({
        version: (appRelease === null || appRelease === void 0 ? void 0 : appRelease.version) || '',
        app: undefined
    });
    useEffect(function () {
        if (!open)
            return;
        if (appRelease === null || appRelease === void 0 ? void 0 : appRelease.releaseNotes) {
            setReleaseNotes(appRelease.releaseNotes);
            setShowReleaseNoteField(false);
            setShowAddButton(true);
        }
        if (appRelease === null || appRelease === void 0 ? void 0 : appRelease.published) {
            setShowAddButton(false);
        }
    }, [open, appRelease]);
    function handleClose() {
        if (loading)
            return;
        form.clear();
        setLoading(false);
        setShowAddButton(false);
        setShowReleaseNoteField(true);
        setReleaseNotes((appRelease === null || appRelease === void 0 ? void 0 : appRelease.releaseNotes) || []);
        setErrorMessage(undefined);
        setShowReleaseNoteError(false);
        onClose();
    }
    function handleSave() {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!form.isValid()) {
                            return [2 /*return*/];
                        }
                        if (releaseNotes.length === 0) {
                            setShowReleaseNoteError(true);
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        setErrorMessage(undefined);
                        setLoading(true);
                        setShowReleaseNoteField(false);
                        setShowAddButton(false);
                        return [4 /*yield*/, onSave(form.value.version, form.value.app, releaseNotes)];
                    case 2:
                        _a.sent();
                        setLoading(false);
                        handleClose();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        setLoading(false);
                        setShowReleaseNoteField(false);
                        setShowAddButton(true);
                        if (err_1.response.data === 'VERSION_ALREADY_EXISTS_ERROR') {
                            setErrorMessage('Ya existe una aplicación con esta versión');
                        }
                        else if (err_1.response.data === 'SMALLER_VERSION_ERROR') {
                            setErrorMessage('La versión no puede ser menor a la versión actual');
                        }
                        else {
                            setErrorMessage('Ocurrio un error');
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function handlePublish() {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        setLoading(true);
                        return [4 /*yield*/, AppReleaseApi.publish(appRelease.id)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, AppReleaseStore.fetchAll()];
                    case 2:
                        _a.sent();
                        setLoading(false);
                        handleClose();
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        setLoading(false);
                        setErrorMessage('Ocurrio un error al publicar la aplicacion');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function handleAddReleaseNote(title, description) {
        setShowReleaseNoteError(false);
        setReleaseNotes(function (releaseNotes) { return __spreadArray(__spreadArray([], releaseNotes, true), [{ title: title, description: description }], false); });
        setShowReleaseNoteField(false);
        setShowAddButton(true);
    }
    function handleEditReleaseNote(index, title, description) {
        setReleaseNotes(function (releaseNotes) {
            return releaseNotes.map(function (releaseNote, i) {
                return i === index
                    ? __assign(__assign({}, releaseNote), { title: title, description: description }) : releaseNote;
            });
        });
        setShowReleaseNoteField(false);
        setShowAddButton(true);
    }
    function handleDeleteReleaseNote(index) {
        setReleaseNotes(function (releaseNotes) { return releaseNotes.filter(function (_, i) { return i !== index; }); });
    }
    function handleShowReleaseNoteField() {
        setShowReleaseNoteField(true);
        setShowAddButton(false);
    }
    function handleHideReleseNoteField() {
        setShowReleaseNoteField(false);
        setShowAddButton(false);
    }
    function handleCancelReleaseNoteField() {
        if (releaseNotes.length > 0) {
            setShowReleaseNoteField(false);
            setShowAddButton(true);
        }
    }
    return (React.createElement(Modal, { open: open, style: style.modal, onClose: handleClose },
        React.createElement(ModalToolbar, { title: 'Lanzamiento', onClose: handleClose }),
        React.createElement(ModalContent, { className: style.modalContent },
            React.createElement("div", { className: style.fieldsContent },
                React.createElement(TextField, { style: style.textField, label: 'Version', placeholder: 'Ej: 1.7.3', formField: form.fields.version, validators: [
                        validators.required,
                        validators.regex(/\d\.\d\.\d/)
                    ] }),
                React.createElement(FileField, { className: style.textField, placeholder: 'Subir APK', filename: appRelease === null || appRelease === void 0 ? void 0 : appRelease.filename, field: form.fields.app, required: appRelease === undefined })),
            React.createElement(Text, { className: style.title, text: 'Notas de la versi\u00F3n' }),
            React.createElement("div", { className: style.releaseNotes },
                releaseNotes.map(function (releaseNote, index) {
                    return React.createElement(ReleaseNoteItem, { key: index, index: index, releaseNote: releaseNote, showMenuOption: !appRelease || appRelease.published === false, onEdit: handleHideReleseNoteField, onCancelEdit: function () { return setShowAddButton(true); }, onSave: handleEditReleaseNote, onDelete: handleDeleteReleaseNote });
                }),
                showReleaseNoteField &&
                    React.createElement(Fragment, null,
                        React.createElement(ReleaseNoteField, { onSave: handleAddReleaseNote, onCancel: handleCancelReleaseNoteField }),
                        showReleaseNotesError &&
                            React.createElement(FieldError, { message: 'Las notas de la version son requeridas' })),
                showAddButton &&
                    React.createElement(Button, { style: style.addButton, text: 'Agregar', onClick: handleShowReleaseNoteField })),
            React.createElement("div", { className: clsx(style.info, (appRelease === null || appRelease === void 0 ? void 0 : appRelease.published) && style.paddingBottom) },
                (appRelease === null || appRelease === void 0 ? void 0 : appRelease.date) &&
                    React.createElement("div", null,
                        React.createElement("span", null, "Fecha: "),
                        React.createElement("span", { className: style.date }, formatDate(appRelease.date))),
                (appRelease === null || appRelease === void 0 ? void 0 : appRelease.published) &&
                    React.createElement("div", { className: style.published }, "Publicado"))),
        React.createElement(Error, { style: style.error, message: errorMessage }),
        React.createElement(IndeterminateProgressBar, { show: loading, className: style.indeterminateProgressBar }),
        !(appRelease === null || appRelease === void 0 ? void 0 : appRelease.published) &&
            React.createElement(ModalActions, { style: style.modalActions },
                appRelease !== undefined &&
                    React.createElement(OutlineButton, { text: 'Publicar', disabled: appRelease === undefined || loading, onClick: handlePublish }),
                React.createElement(OutlineButton, { text: 'Guardar', disabled: loading, onClick: handleSave }))));
}

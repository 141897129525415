// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pin-item-menu_container-xevRQ {
  min-width: 220px;
}

.pin-item-deleteOption_text-xGSy3 {
  color: var(--accent-color);
}

.pin-item-price-e2nkF {
  height: 28px;
  line-height: 28px;
  display: inline-block;
  background-color: rgba(0, 146, 44, 0.1);
  color: var(--money-color);
  padding: 0px 16px;
  font-size: 14px;
  text-align: center;
  border-radius: 14px;
  margin-right: 12px;
  text-align: center;
}

.pin-item-instructions-ByvCX span {
  display: inline-block;
  width: 260px;
  white-space: normal;
  padding: 8px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/products/components/pin-item/pin-item.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,qBAAqB;EACrB,uCAAuC;EACvC,yBAAyB;EACzB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".menu_container {\n  min-width: 220px;\n}\n\n.deleteOption_text {\n  color: var(--accent-color);\n}\n\n.price {\n  height: 28px;\n  line-height: 28px;\n  display: inline-block;\n  background-color: rgba(0, 146, 44, 0.1);\n  color: var(--money-color);\n  padding: 0px 16px;\n  font-size: 14px;\n  text-align: center;\n  border-radius: 14px;\n  margin-right: 12px;\n  text-align: center;\n}\n\n.instructions span {\n  display: inline-block;\n  width: 260px;\n  white-space: normal;\n  padding: 8px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu_container": `pin-item-menu_container-xevRQ`,
	"deleteOption_text": `pin-item-deleteOption_text-xGSy3`,
	"price": `pin-item-price-e2nkF`,
	"instructions": `pin-item-instructions-ByvCX`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-users-container-KAZ9R {
  height: 100%;
  padding: 20px 0px 0px 20px;
  overflow: hidden;
}

.business-users-table_container-Pxb3r {
  overflow: scroll;
  padding-right: 10px;
}

.business-users-tableHeader_tab-QeQoy {
  border-top: 1px solid var(--border-color);
}

.business-users-addUser_base-ykVgT {
  width: fit-content;
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/business-users/business-users.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".container {\n  height: 100%;\n  padding: 20px 0px 0px 20px;\n  overflow: hidden;\n}\n\n.table_container {\n  overflow: scroll;\n  padding-right: 10px;\n}\n\n.tableHeader_tab {\n  border-top: 1px solid var(--border-color);\n}\n\n.addUser_base {\n  width: fit-content;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `business-users-container-KAZ9R`,
	"table_container": `business-users-table_container-Pxb3r`,
	"tableHeader_tab": `business-users-tableHeader_tab-QeQoy`,
	"addUser_base": `business-users-addUser_base-ykVgT`
};
export default ___CSS_LOADER_EXPORT___;

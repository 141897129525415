// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-pane-container-GCohb {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tabs-pane-tabBar_container-gDkmf {
  border-top-left-radius: var(--large-border-radius);
  border-top-right-radius: var(--large-border-radius);
  flex-shrink: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/tabs-pane/tabs-pane.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,kDAAkD;EAClD,mDAAmD;EACnD,cAAc;AAChB","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.tabBar_container {\n  border-top-left-radius: var(--large-border-radius);\n  border-top-right-radius: var(--large-border-radius);\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `tabs-pane-container-GCohb`,
	"tabBar_container": `tabs-pane-tabBar_container-gDkmf`
};
export default ___CSS_LOADER_EXPORT___;

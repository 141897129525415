import React from 'react';
import { Select, SelectOption } from '@recargas-dominicanas/core/components';
import { BankAccountSelectItem } from '../bank-account-select-item/bank-account-select-item.component';
import { mergeStyle } from './bank-account-select.module.css';
export function BankAccountSelect(props) {
    var label = props.label, field = props.field, _a = props.placeholder, placeholder = _a === void 0 ? 'Seleccionar cuenta bancaria' : _a, _b = props.disabled, disabled = _b === void 0 ? false : _b, bankAccounts = props.bankAccounts, customStyle = props.style;
    var style = mergeStyle(customStyle);
    return (React.createElement(Select, { style: style, icon: 'account_balance', label: label, placeholder: placeholder, required: true, disabled: disabled, formField: field }, bankAccounts === null || bankAccounts === void 0 ? void 0 : bankAccounts.map(function (bankAccount) {
        var _a;
        return (React.createElement(SelectOption, { key: bankAccount.id, style: style.selectOption, value: bankAccount, label: bankAccount.name, showClearIcon: false },
            React.createElement(BankAccountSelectItem, { bankAccount: bankAccount, showCloseIcon: bankAccount.id === ((_a = field === null || field === void 0 ? void 0 : field.value) === null || _a === void 0 ? void 0 : _a.id), onClose: function () { return field === null || field === void 0 ? void 0 : field.clear(); } })));
    })));
}

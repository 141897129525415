// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.option-button-container-tDbeX {
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 0px 0px 0px 8px;
}

.option-button-container-tDbeX:hover {
  background-color: var(--background-color);
}

.option-button-container-tDbeX:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.option-button-containerDisabled-IevFv {
  cursor: default;
  border: 1px solid rgba(0, 0, 0, 0.10);
}

.option-button-containerDisabled-IevFv:hover {
  background-color: initial;
}

.option-button-title-lRbHB {
  line-height: 32px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  margin: 0px 0px 0px 8px;
  white-space: nowrap;
}

.option-button-titleDisabled-Z5h0H {
  color: rgba(0, 0, 0, 0.6);
}

.option-button-margin12px-H5ul7 {
  margin-right: 12px;
}

.option-button-icon-7DL6G, .option-button-iconRight-xGKSi {
  --size: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.option-button-iconDisabled-QdVcm {
  color: rgba(0, 0, 0, 0.5);
}

.option-button-iconDisabled-QdVcm:hover {
  cursor: default;
}

.option-button-iconRight-xGKSi {
  margin: 0px 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/option-button/option-button.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,qCAAqC;EACrC,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,eAAe;EACf,qCAAqC;AACvC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  height: 32px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  border: 1px solid rgba(0, 0, 0, 0.15);\n  border-radius: 4px;\n  padding: 0px 0px 0px 8px;\n}\n\n.container:hover {\n  background-color: var(--background-color);\n}\n\n.container:active {\n  background-color: rgba(0, 0, 0, 0.05);\n}\n\n.containerDisabled {\n  cursor: default;\n  border: 1px solid rgba(0, 0, 0, 0.10);\n}\n\n.containerDisabled:hover {\n  background-color: initial;\n}\n\n.title {\n  line-height: 32px;\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n  margin: 0px 0px 0px 8px;\n  white-space: nowrap;\n}\n\n.titleDisabled {\n  color: rgba(0, 0, 0, 0.6);\n}\n\n.margin12px {\n  margin-right: 12px;\n}\n\n.icon, .iconRight {\n  --size: 20px;\n  color: rgba(0, 0, 0, 0.7);\n}\n\n.iconDisabled {\n  color: rgba(0, 0, 0, 0.5);\n}\n\n.iconDisabled:hover {\n  cursor: default;\n}\n\n.iconRight {\n  margin: 0px 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `option-button-container-tDbeX`,
	"containerDisabled": `option-button-containerDisabled-IevFv`,
	"title": `option-button-title-lRbHB`,
	"titleDisabled": `option-button-titleDisabled-Z5h0H`,
	"margin12px": `option-button-margin12px-H5ul7`,
	"icon": `option-button-icon-7DL6G`,
	"iconRight": `option-button-iconRight-xGKSi`,
	"iconDisabled": `option-button-iconDisabled-QdVcm`
};
export default ___CSS_LOADER_EXPORT___;

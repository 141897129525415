// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sales-container-myWVx {
  min-width: 1000px;
  height: 100%;
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 12px;
}

.sales-header-y1i5L {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between
}

.sales-salesItems-ATyVl {
  display: flex;
  align-items: center;
  gap: 32px;
}

.sales-item-pwRfe {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.sales-title-Gk_bx {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.75);
}

.sales-amount-Qdfru {
  color: var(--money-color);
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}

.sales-options-OH_rK {
  display: flex;
  gap: 12px;
}

.sales-content-b_Clw {
  display: flex;
  flex-grow: 1;
  gap: 40px;
  padding: 0px 4px 4px;
}

.sales-contentRight-bL6wo {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 40px;
}

.sales-verticalBarChart-V0fhH {
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/provider/sales/sales.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,SAAS;EACT,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,cAAc;EACd;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,YAAY;AACd","sourcesContent":[".container {\n  min-width: 1000px;\n  height: 100%;\n  display: flex;\n  gap: 24px;\n  flex-direction: column;\n  padding: 12px;\n}\n\n.header {\n  display: flex;\n  flex-shrink: 0;\n  justify-content: space-between\n}\n\n.salesItems {\n  display: flex;\n  align-items: center;\n  gap: 32px;\n}\n\n.item {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 12px;\n}\n\n.title {\n  font-size: 14px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 0.75);\n}\n\n.amount {\n  color: var(--money-color);\n  font-size: 14px;\n  font-weight: bold;\n  white-space: nowrap;\n}\n\n.options {\n  display: flex;\n  gap: 12px;\n}\n\n.content {\n  display: flex;\n  flex-grow: 1;\n  gap: 40px;\n  padding: 0px 4px 4px;\n}\n\n.contentRight {\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.verticalBarChart {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sales-container-myWVx`,
	"header": `sales-header-y1i5L`,
	"salesItems": `sales-salesItems-ATyVl`,
	"item": `sales-item-pwRfe`,
	"title": `sales-title-Gk_bx`,
	"amount": `sales-amount-Qdfru`,
	"options": `sales-options-OH_rK`,
	"content": `sales-content-b_Clw`,
	"contentRight": `sales-contentRight-bL6wo`,
	"verticalBarChart": `sales-verticalBarChart-V0fhH`
};
export default ___CSS_LOADER_EXPORT___;

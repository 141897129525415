// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-brand_container-rX3B8 {
  justify-content: center;
  margin: 72px auto 0px;
}

.login-card-ucDha {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ECECEC;
  border-radius: 8px;
  padding: 28px 28px 24px;
  margin: 60px auto 0px;
}

.login-cardIcon-FkN4p {
  --size: 48px;
  margin-top: 18px;
}

.login-cardTitle_container-UpzLN {
  margin-top: 16px;
}

.login-form-T85Cr {
  width: 100%;
  height: 200px;
}

.login-emailField_container-KF7fC {
  width: 100%;
  min-height: 60px;
  margin-top: 44px;
}

.login-passwordField_container-FanI5 {
  width: 100%;
  margin-top: 16px;
}

.login-button_base-InH0X {
  width: 152px;
  margin-left: auto;
  margin-top: 40px;
}

.login-forgetPassword-KtDr9 {
  color: #F12626;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-top: 52px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/login/login.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".brand_container {\n  justify-content: center;\n  margin: 72px auto 0px;\n}\n\n.card {\n  width: 360px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border: 1px solid #ECECEC;\n  border-radius: 8px;\n  padding: 28px 28px 24px;\n  margin: 60px auto 0px;\n}\n\n.cardIcon {\n  --size: 48px;\n  margin-top: 18px;\n}\n\n.cardTitle_container {\n  margin-top: 16px;\n}\n\n.form {\n  width: 100%;\n  height: 200px;\n}\n\n.emailField_container {\n  width: 100%;\n  min-height: 60px;\n  margin-top: 44px;\n}\n\n.passwordField_container {\n  width: 100%;\n  margin-top: 16px;\n}\n\n.button_base {\n  width: 152px;\n  margin-left: auto;\n  margin-top: 40px;\n}\n\n.forgetPassword {\n  color: #F12626;\n  font-weight: 500;\n  font-size: 16px;\n  text-align: center;\n  margin-top: 52px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brand_container": `login-brand_container-rX3B8`,
	"card": `login-card-ucDha`,
	"cardIcon": `login-cardIcon-FkN4p`,
	"cardTitle_container": `login-cardTitle_container-UpzLN`,
	"form": `login-form-T85Cr`,
	"emailField_container": `login-emailField_container-KF7fC`,
	"passwordField_container": `login-passwordField_container-FanI5`,
	"button_base": `login-button_base-InH0X`,
	"forgetPassword": `login-forgetPassword-KtDr9`
};
export default ___CSS_LOADER_EXPORT___;

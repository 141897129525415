// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-product-modal-modal_card-jbQDU {
  width: 340px;
  min-height: 440px;
  height: auto;
}

.add-product-modal-autocomplete_container-3kUAj {
  width: 100%;
  margin-top: 24px;
}

.add-product-modal-autocomplete_popup-yM3JM {
  max-height: 224px;
}

.add-product-modal-productItem-mImXM {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
}

.add-product-modal-productItemImage-H3rFg {
  width: 32px;
  height: 32px;
}

.add-product-modal-productItemInfo-_BUSg {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.add-product-modal-productItemName-Mh62p {
  display: block;
  margin-left: 24px;
}

.add-product-modal-productItemType-JWcSY {
  width: 72px;
  font-size: 15px;
  font-style: italic;
  color: #555555;
}

.add-product-modal-productItemSelected-tqtEG {
  border: 1px solid var(--border-color);
  border-radius: var(--small-border-radius);
  margin-top: 20px;
  margin-bottom: 4px;
}

.add-product-modal-textField_container-qhAE0 {
  width: 100%;
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/provider/products/add-product-modal/add-product-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EAEE,qCAAqC;EACrC,yCAAyC;EACzC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".modal_card {\n  width: 340px;\n  min-height: 440px;\n  height: auto;\n}\n\n.autocomplete_container {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.autocomplete_popup {\n  max-height: 224px;\n}\n\n.productItem {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 12px;\n}\n\n.productItemImage {\n  width: 32px;\n  height: 32px;\n}\n\n.productItemInfo {\n  flex-grow: 1;\n  display: flex;\n  justify-content: space-between;\n}\n\n.productItemName {\n  display: block;\n  margin-left: 24px;\n}\n\n.productItemType {\n  width: 72px;\n  font-size: 15px;\n  font-style: italic;\n  color: #555555;\n}\n\n.productItemSelected {\n  composes: productItem;\n  border: 1px solid var(--border-color);\n  border-radius: var(--small-border-radius);\n  margin-top: 20px;\n  margin-bottom: 4px;\n}\n\n.textField_container {\n  width: 100%;\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `add-product-modal-modal_card-jbQDU`,
	"autocomplete_container": `add-product-modal-autocomplete_container-3kUAj`,
	"autocomplete_popup": `add-product-modal-autocomplete_popup-yM3JM`,
	"productItem": `add-product-modal-productItem-mImXM`,
	"productItemImage": `add-product-modal-productItemImage-H3rFg`,
	"productItemInfo": `add-product-modal-productItemInfo-_BUSg`,
	"productItemName": `add-product-modal-productItemName-Mh62p`,
	"productItemType": `add-product-modal-productItemType-JWcSY`,
	"productItemSelected": `add-product-modal-productItemSelected-tqtEG add-product-modal-productItem-mImXM`,
	"textField_container": `add-product-modal-textField_container-qhAE0`
};
export default ___CSS_LOADER_EXPORT___;

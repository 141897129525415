// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topup-item-menu_container-SGMYp {
  min-width: 220px;
}

.topup-item-deleteOption_text-j_B0g {
  color: var(--accent-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/products/components/topup-item/topup-item.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".menu_container {\n  min-width: 220px;\n}\n\n.deleteOption_text {\n  color: var(--accent-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu_container": `topup-item-menu_container-SGMYp`,
	"deleteOption_text": `topup-item-deleteOption_text-j_B0g`
};
export default ___CSS_LOADER_EXPORT___;

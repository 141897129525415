// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-field-container-vDoRf {
  width: 220px;
}

.file-field-title-A1Mqt {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 12px;
}

.file-field-field-vNYmX {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--border-color);
  border-radius: var(--small-border-radius);
  padding: 0px 14px;
}

.file-field-value-r_SDU {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 12px;
}

.file-field-active-uQMW7 {
  color: black;
}

.file-field-icon-CjA9s {
  --size: 20px;
  flex-shrink: 0;
  color:rgba(0, 0, 0, 0.75);
}

.file-field-input-_yJ9n {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/file-field/file-field.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,qCAAqC;EACrC,yCAAyC;EACzC,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".container {\n  width: 220px;\n}\n\n.title {\n  font-size: 14px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 0.8);\n  margin-bottom: 12px;\n}\n\n.field {\n  width: 100%;\n  height: 40px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n  border: 1px solid var(--border-color);\n  border-radius: var(--small-border-radius);\n  padding: 0px 14px;\n}\n\n.value {\n  color: rgba(0, 0, 0, 0.8);\n  font-size: 15px;\n  overflow: hidden;\n  white-space: nowrap;\n  margin-right: 12px;\n}\n\n.active {\n  color: black;\n}\n\n.icon {\n  --size: 20px;\n  flex-shrink: 0;\n  color:rgba(0, 0, 0, 0.75);\n}\n\n.input {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `file-field-container-vDoRf`,
	"title": `file-field-title-A1Mqt`,
	"field": `file-field-field-vNYmX`,
	"value": `file-field-value-r_SDU`,
	"active": `file-field-active-uQMW7`,
	"icon": `file-field-icon-CjA9s`,
	"input": `file-field-input-_yJ9n`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { formatCurrency, formatPhone } from '@recargas-dominicanas/core/utils';
import { PageContainer, Text } from '@recargas-dominicanas/core/components';
import { PageToolbar } from '@recargas-dominicanas/core/components';
import { PageContent } from '@recargas-dominicanas/core/components';
import { Products } from './products/products.component';
import { Deposits } from './deposits/deposits.component';
import { BankAccounts } from './bank-accounts/bank-accounts.component';
import { Configuration } from './configuration/configuration.component';
import { ProviderStore } from '../../store/providerStore';
import { Transactions } from './transactions/transactions.component';
import { Sales } from './sales/sales.component';
import { style } from './provider.module.css';
import { Case, OutlineCard, Switch, Tab, TabBar, ToolbarTitle, DetailItem } from '@recargas-dominicanas/core/components';
export function Provider() {
    var params = useParams();
    var id = parseInt(params.id);
    var provider = ProviderStore.getById(id);
    var externalBalance = ProviderStore.getExternalBalance(id);
    var _a = useState('sales'), selectedTab = _a[0], setSelectedTab = _a[1];
    useEffect(function () {
        ProviderStore.fetchById(id);
        ProviderStore.fetchTransactions(id, 1, 100);
        ProviderStore.fetchProducts(id);
        ProviderStore.fetchBankAccounts(id);
        ProviderStore.fetchDeposits(id);
        ProviderStore.fetchConfigs(id);
        ProviderStore.fetchExternalBalance(id);
    }, []);
    function handleTabClick(tab) {
        setSelectedTab(tab.tag);
    }
    return !provider ? null : (React.createElement(PageContainer, { className: style.container },
        React.createElement(PageToolbar, { className: style.toolbar },
            React.createElement(ToolbarTitle, { style: style.title, title: 'Proveedor' }),
            React.createElement(TabBar, { onTabClick: handleTabClick },
                React.createElement(Tab, { style: style.tab, tag: 'sales', title: 'Ventas' }),
                React.createElement(Tab, { style: style.tab, tag: 'transactions', title: 'Transacciones' }),
                React.createElement(Tab, { style: style.tab, tag: 'products', title: 'Productos' }),
                React.createElement(Tab, { style: style.tab, tag: 'deposits', title: 'Depositos' }),
                React.createElement(Tab, { style: style.tab, tag: 'bank-accounts', title: 'Cuentas bancarias' }),
                React.createElement(Tab, { style: style.tab, tag: 'configuration', title: 'Configuraci\u00F3n' }))),
        React.createElement(PageContent, { className: style.content },
            React.createElement(OutlineCard, { className: style.outlineCard },
                React.createElement("img", { className: style.image, src: "".concat(process.env.API, "/static/").concat(provider.image) }),
                React.createElement(Text, { className: style.providerTitle, text: provider.name }),
                provider.rnc && React.createElement(DetailItem, { style: style.detailItem, title: 'RNC', text: provider.rnc }),
                provider.phone && React.createElement(DetailItem, { style: style.detailItem, title: 'Tel\u00E9fono', text: formatPhone(provider.phone) }),
                provider.email && React.createElement(DetailItem, { style: style.detailItem, title: 'Email', text: provider.email }),
                React.createElement(DetailItem, { title: 'Estado', text: provider.enabled ? 'Activo' : 'Desactivado', style: provider.enabled ? style.detailItemEnabled : style.detailItemDisabled }),
                React.createElement(DetailItem, { style: style.detailItemBalance, title: 'Balance', text: formatCurrency(externalBalance || 0) })),
            React.createElement(OutlineCard, { className: style.outlineCardRight },
                React.createElement(Switch, { caseId: selectedTab },
                    React.createElement(Case, { caseId: 'sales' },
                        React.createElement(Sales, { provider: provider })),
                    React.createElement(Case, { caseId: 'transactions' },
                        React.createElement(Transactions, { id: provider.id })),
                    React.createElement(Case, { caseId: 'products' },
                        React.createElement(Products, { id: provider.id })),
                    React.createElement(Case, { caseId: 'deposits' },
                        React.createElement(Deposits, { id: provider.id })),
                    React.createElement(Case, { caseId: 'bank-accounts' },
                        React.createElement(BankAccounts, { id: provider.id, userId: provider.user.id })),
                    React.createElement(Case, { caseId: 'configuration' },
                        React.createElement(Configuration, { providerId: provider.id })))))));
}

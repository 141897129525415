// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transactions-container-Qcc0E {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.transactions-tableContainer-p62Ex {
  height: 100%;
  padding-left: 10px;
  overflow: hidden;
}

.transactions-table_container-Ktj5_ {
  padding: 0px 10px;
}

.transactions-tableHeader_cellContent-IhTQy {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/pages/provider/transactions/transactions.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.tableContainer {\n  height: 100%;\n  padding-left: 10px;\n  overflow: hidden;\n}\n\n.table_container {\n  padding: 0px 10px;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `transactions-container-Qcc0E`,
	"tableContainer": `transactions-tableContainer-p62Ex`,
	"table_container": `transactions-table_container-Ktj5_`,
	"tableHeader_cellContent": `transactions-tableHeader_cellContent-IhTQy`
};
export default ___CSS_LOADER_EXPORT___;

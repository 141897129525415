var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { BankAccountApi, ProviderApi } from '@recargas-dominicanas/core/api';
import { useAsyncEffect, useForm, validators } from '@recargas-dominicanas/core/utils';
import { DateTime, Select, SelectOption, TextField } from '@recargas-dominicanas/core/components';
import { Modal } from '../../../components/modal/modal.component';
import { BankAccountStore } from '../../../store/bankAccountStore';
import { BankAccountSelect } from '../../../components/bank-account-select/bank-account-select.component';
import { Toggle } from '../../../components/toggle/toggle.component';
import { ProviderStore } from '../../../store/providerStore';
import { style } from './register-balance-purchase-modal.module.css';
export function RegisterBalancePurchaseModal(props) {
    var _this = this;
    var open = props.open, bankAccountId = props.bankAccountId, balance = props.balance, onClose = props.onClose;
    var form = useForm({
        providerId: undefined,
        bankAccount: undefined,
        date: '',
        balance: '',
        businessId: '',
        description: '',
        brcd: '',
        taxes: ''
    });
    var providers = ProviderStore.getAll();
    var _a = useState(), error = _a[0], setError = _a[1];
    var _b = useState(false), disabledTaxesField = _b[0], setDisabledTaxesField = _b[1];
    var _c = useState([]), bankAccounts = _c[0], setBankAccounts = _c[1];
    var _d = useState(false), disabledButton = _d[0], setDisabledButton = _d[1];
    useAsyncEffect(function () { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!open)
                        return [2 /*return*/];
                    form.fields.bankAccount.clear();
                    if (!(form.value.providerId !== undefined)) return [3 /*break*/, 2];
                    _a = setBankAccounts;
                    return [4 /*yield*/, ProviderApi.getBankAccounts(form.value.providerId)];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    return [3 /*break*/, 3];
                case 2:
                    setBankAccounts([]);
                    _b.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [open, form.value.providerId]);
    useEffect(function () {
        if (form.value.balance) {
            var taxes = (parseInt(form.value.balance) / 100) * 0.15;
            form.fields.taxes.update(taxes.toString());
        }
        else {
            form.fields.taxes.update('');
        }
    }, [form.value.balance]);
    function handleCancel() {
        form.clear();
        onClose();
        setError(undefined);
    }
    function handleSave() {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!form.isValid())
                            return [2 /*return*/];
                        setDisabledButton(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, BankAccountApi.registerBalancePurchase(bankAccountId, {
                                providerId: form.value.providerId,
                                bankAccountId: form.value.bankAccount.id,
                                balance: parseInt(form.value.balance),
                                date: new Date(form.value.date),
                                description: form.value.description,
                                taxes: !disabledTaxesField ? parseFloat(form.value.taxes) : undefined,
                                brcd: form.value.brcd ? parseFloat(form.value.brcd) : undefined
                            })];
                    case 2:
                        _a.sent();
                        BankAccountStore.fetchById(bankAccountId);
                        BankAccountStore.fetchWithdrawals(bankAccountId, 1, 100);
                        handleCancel();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        setError('Ocurrio un error al registrar la compra de balance');
                        return [3 /*break*/, 4];
                    case 4:
                        setDisabledButton(false);
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(Modal, { open: open, style: style.modal, title: 'Registrar compra de balance', error: error, secondaryButton: 'Registrar', disabledSecondaryButton: disabledButton, onClose: handleCancel, onSecondaryClick: handleSave },
        React.createElement(Select, { style: style.select, icon: 'storefront', label: 'Proveedor', placeholder: 'Seleccionar proveedor', required: true, formField: form.fields.providerId }, providers === null || providers === void 0 ? void 0 : providers.map(function (provider) {
            return React.createElement(SelectOption, { style: style.selectOption, key: provider.id, value: provider.id, label: provider.name }, provider.name);
        })),
        React.createElement(BankAccountSelect, { style: style.textField, label: 'Cuenta bancaria', field: form.fields.bankAccount, disabled: bankAccounts.length === 0, bankAccounts: bankAccounts }),
        React.createElement(TextField, { style: style.textField, label: 'Balance', type: 'number', formField: form.fields.balance, validators: [
                validators.required,
                validators.max(balance)
            ] }),
        React.createElement(DateTime, { style: style.dateTime, label: 'Fecha', placeholder: 'Seleccionar fecha', type: 'datetime-local', formField: form.fields.date, required: true }),
        React.createElement(TextField, { style: style.textField, label: 'Descripci\u00F3n', formField: form.fields.description }),
        React.createElement("div", { className: style.taxesField },
            React.createElement(TextField, { style: style.taxesTextField, label: 'Impuestos', formField: form.fields.taxes, disabled: disabledTaxesField }),
            React.createElement(Toggle, { on: true, className: style.toggle, onChange: function (on) { return setDisabledTaxesField(!on); } })),
        React.createElement(TextField, { style: style.textField, label: 'BRCD (Transferencia al instante)', formField: form.fields.brcd })));
}

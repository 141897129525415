
    const result = require("!!../../../node_modules/style-loader/dist/cjs.js!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./image-picker.module.css");
    const resultContent = result.__esModule ? result.default : result;

    function groupStyle(style, separator) {
  const regexp = new RegExp(`([\\w-]+?)${separator}([\\w-]+)`);
  const groupStyle = {};

  for (const key in style) {
    if (regexp.test(key)) {
      const [, groupKey, propKey] = key.split(regexp);
      groupStyle[groupKey] = { ...groupStyle[groupKey],
        [propKey]: style[key]
      };
    } else {
      groupStyle[key] = style[key];
    }
  }

  return groupStyle;
}
    function merge(defaultStyle) {
  return style => {
    const mergeStyle = { ...defaultStyle
    };

    for (const key in style) {
      if (mergeStyle.hasOwnProperty(key)) {
        mergeStyle[key] = defaultStyle[key] + ' ' + style[key];
      }
    }

    return mergeStyle;
  };
}

    export const style = groupStyle(resultContent, '_');
    export const mergeStyle = merge(style);
  